import React, {useRef} from 'react';
import Folder2 from '../Folder'
import BookShare from '../BookShare'
import Book from '../Book'
import Link from '../Link'
import folderClasses from './folderClasses'
import {useDrag, useDrop } from 'react-dnd';
import config from '../../../data/config';

function Folder(props){
  //** treeData */
  const {folder, toggleClose, handleShow, parentPath, moveItemChild, moveItem, toggleMemoIcon, memoIcons} = props
  const treeDepth = props.treeDepth + 1
  const linkDepth = -1

  //** drag and drop */
  const path = [...parentPath, folder.key]
  folder.path = path
  const folderDelegate = {type: folder.type, key: folder.key, path: path}
  const [, drag, previewRef] = useDrag({
    item: folderDelegate,
    collect: (monitor)=>({
        opacity: monitor.isDragging()? 0.5: 1
    })
  })
  const [{isSiblingOver, canDrop}, drop] = useDrop({
    accept: ['fld', 'bok', 'lnk'],
    drop(item){
        if(item.key !== folder.key){
          if(item.type === 'fld'){
            moveItemChild({targetDelegate: folderDelegate, sourceDelegate: item});
          }else{
            moveItem({targetDelegate: folderDelegate, sourceDelegate: item});
          }
        }
    },
    collect: (monitor) => ({
      isSiblingOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop(item, monitor){
      // parent item cannot move to child item
      return (item.key !== folder.key) && !folder.path.includes(item.key)
    }
  })
  const [{isChildOver, canChildDrop}, dropChild] = useDrop({
      accept: ['fld', 'bok', 'lnk'],
      drop(item){
          if(item.key !== folder.key){
              if(item.type === 'fld'){
                moveItem({targetDelegate: folderDelegate, sourceDelegate: item});
              }else{
                moveItemChild({targetDelegate: folderDelegate, sourceDelegate: item});
              }
          }
      },
      collect: (monitor) => ({
        isChildOver: monitor.isOver(),
        canChildDrop: monitor.canDrop(),
      }),
      canDrop(item, monitor){
        // parent item cannot move to child item
        return (item.key !== folder.key) && !folder.path.includes(item.key)
      }
  })
  const ref = useRef(null)
  const ref2 = useRef(null)
  drop(ref)
  previewRef(drag(dropChild(ref2)))

  let siblingStyle
  if(isSiblingOver){
    siblingStyle = canDrop? config.canDropStyle: config.cannotDropStyle
  }
  let childStyle
  if(isChildOver){
    childStyle = canChildDrop? config.canDropStyle: config.cannotDropStyle
  }
  //** props */
  const itemProps = {toggleClose, handleShow, parentPath: path, moveItemChild, moveItem, toggleMemoIcon, memoIcons}
  
  return(
    <>
    {folder &&  
      <div className="folder">
        <i style={siblingStyle} className={folder.close ? (folder.children?.length?"closeIcon": "nochildIcon"):"titleIcon"} ref={ref} onClick={toggleClose(folder)}></i>
        <div className={"alert header " + folderClasses[treeDepth%folderClasses.length]} ref={ref2} style={childStyle}>
          <a href={folder.href} target="_blank" rel="noopener noreferrer">
            <span className={folder.match ? "title match" : 'title'}>{folder.title}</span>
          </a>
          {folder.memo && <i className={memoIcons[folder.memoI || 0]} onClick={toggleMemoIcon(folder)}></i>}
          <i className="settings" onClick={handleShow(folder)}></i>
        </div>
        <div className={folder.memoI?"memoI": "memo"}>{folder.memo}</div>
        <div className="content">
          {!folder.close && folder.children && folder.children.map((item)=>{
            return (
              item.type === 'fld'? <Folder2 key={item.key} folder={item} treeDepth={treeDepth} {...itemProps}/>:
              (item.type === 'bok' && item.share)? <BookShare key={item.key} book={item} {...itemProps}/>:
              (item.type === 'bok' && !item.share)? <Book key={item.key} book={item} {...itemProps}/>:
              item.type === 'lnk'? <Link key={item.key} link={item} linkDepth={linkDepth} {...itemProps}/>:
              null
            )
          })}
        </div>
      </div>
    }
    </>
  );
}

export default Folder;