import config from '../data/config';
import { object, string, ref } from 'yup';

const schema_ttl = object().shape({
    title: string().min(config.title_min, 'title_min_error').max(config.title_max, 'title_max_error').required('title_required_error'),
});
const schema_url = object().shape({
    href: string().url('url_error').max(config.url_max, 'url_max_error'),
});
const schema_memo = object().shape({
    memo: string().min(config.memo_min, 'memo_min_error').max(config.memo_max, 'memo_max_error'),
});
const schema_keywords = object().shape({
    memo: string().min(config.memo_min, 'memo_min_error').max(config.memo_max, 'memo_max_error'),
});
const schema_bgurl = object().shape({
    bgurl: string().url('url_error').max(config.bgurl_max, 'bgurl_max_error'),
});
const schema_email = object().shape({
    email: string().email('email_error').max(config.email_max, 'email_max_error').required('email_required_error'),
});
const schema_userid = object().shape({
    userid: string().strict(true).lowercase('userid_lower_error').min(config.userid_min, 'userid_min_error' ).max(config.userid_max, 'userid_max_error').required('userid_required_error'),
});
const schema_pass = object().shape({
    pass: string().min(config.pass_min, 'pass_min_error' ).max(config.pass_max, 'pass_max_error').required('pass_required_error'),
});
const schema_pass2 = object().shape({
    pass: string().min(config.pass_min, 'pass_min_error').max(config.pass_max, 'pass_max_error').required('pass_required_error'),
    pass2: string().oneOf([ref('pass'), null], 'pass_mismatch_error').required('pass_required_error'),
});
const schema_newpass = object().shape({
    newpass: string().min(config.pass_min, 'pass_min_error').max(config.pass_max, 'pass_max_error').required('pass_required_error'),
});
const validateTtl = (target, pageList) => {
    try{
        if(target.type === 'pge'){ // create page: check unique title
            const filtered = pageList.filter(page => (page.title === target.title) && (page.title !== target.originalTitle))
            if(filtered.length > 0){
                return {errors:["unique_error"]};
            }else{
                schema_ttl.validateSync(target);
            }
        }else{
            schema_ttl.validateSync(target);
        }
        return null;
    }catch(e){
        return e;
    }
}
const validateUrl = (target) => {
    try{
        schema_url.validateSync(target);
        return null;
    }catch(e){
        return e;
    }
}
const validateMemo = (target) => {
    try{
        schema_memo.validateSync(target);
        return null;
    }catch(e){
        return e;
    }
}
const validateKeywords = (target) => {
    try{
        schema_keywords.validateSync(target);
        return null;
    }catch(e){
        return e;
    }
}
const validateBgurl = (target) => {
    try{
        schema_bgurl.validateSync(target);
        return null;
    }catch(e){
        return e;
    }
}
const validateEmail = (target) => {
        try{
            schema_email.validateSync(target);
            return null;
        }catch(e){
            return e;
        }
}
const validateUserid = (target) => {
        try{
            schema_userid.validateSync(target);
            return null;
        }catch(e){
            return e;
        }
}
const validatePass = (target) => {
        try{
            schema_pass.validateSync(target);
            return null;
        }catch(e){
            return e;
        }
}
const validatePass2 = (target) => {
        try{
            schema_pass2.validateSync(target);
            return null;
        }catch(e){
            return e;
        }
}
const validateNewPass = (target) => {
        try{
            schema_newpass.validateSync(target);
            return null;
        }catch(e){
            return e;
        }
}
const schema_login = object().shape({
    userid: string().strict(true).lowercase('userid_lower_error').min(config.userid_min, 'userid_min_error' ).max(config.userid_max, 'userid_max_error').required('userid_required_error'),
    pass: string().min(config.pass_min, 'pass_min_error' ).max(config.pass_max, 'pass_max_error').required('pass_required_error'),
})
const schema_signup = object().shape({
    email: string().email('email_error').max(config.email_max, 'email_max_error').required('email_required_error'),
    userid: string().strict(true).lowercase('userid_lower_error').min(config.userid_min, 'userid_min_error' ).max(config.userid_max, 'userid_max_error').required('userid_required_error'),
    pass: string().min(config.pass_min, 'pass_min_error' ).max(config.pass_max, 'pass_max_error').required('pass_required_error'),
    pass2: string().oneOf([ref('pass'), null], 'pass_match_error').required('pass_required_error')
})
const validateLogin= (target) => {
        try{
            schema_login.validateSync(target);
            return null;
        }catch(e){
            return e;
        }
}
const validateSignup= (target) => {
        try{
            schema_signup.validateSync(target);
            return null;
        }catch(e){
            return e;
        }
}
const schema_code = object().shape({code: string().min(config.code_min, 'code_min_error' ).max(config.code_max, 'code_max_error').required()}) 
const validateCode= (target) => {
        try{
            schema_code.validateSync(target);
            return null;
        }catch(e){
            return e;
        }
}
export {
    validateTtl,
    validateUrl,
    validateMemo,
    validateKeywords,
    validateBgurl,
    validateEmail,
    validateUserid,
    validatePass,
    validatePass2,
    validateLogin,
    validateSignup,
    validateNewPass,
    validateCode
}
