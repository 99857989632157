const getParentAndTargetNodeFromPath = (path, page) => {
    if(!path || path.length === 0){
      return null
    }
    if(path.length === 1){
      return {itemParent: null, item: page}
    }
    let itemParent = page;
    for(let i = 1, n = path.length - 1; i < n; i++){
      itemParent = itemParent.children.find(item => item.key === path[i]);
    }
    const item = itemParent.children.find(item => item.key === path[path.length - 1])
    return {itemParent, item} ;
  }

export default getParentAndTargetNodeFromPath