import React, {useState, useContext, useEffect, useRef} from 'react';
import api from '../../utils/api'
import ajaxLoader from '../../icons/ajax-loader.gif';
import HeaderMyhome from '../../components/HeaderMyhome';
import {LoginContext} from '../../components/LoginProvider';
import BookShare from './BookShare';
import MyModalShare from './MyModalShare';
import config from '../../data/config'
import {LangContext} from '../../components/LangProvider';

function Share(){
   let {userid, token} = useContext(LoginContext)
   const {lang} = useContext(LangContext);
   const t = lang.share;
   const pathUserid = config.shareUserid
   const path = ['share']
   const pathTitle = 'home'
   const prevPathTitle = 'home'
   const [, setShow] = useState(false)
   const [showShare, setShowShare] = useState(false)
   let [selected, setSelected] = useState(null)
   const [pageRecent, setPageRecent] = useState({type: 'pge', key: config.shareUserid, title: config.shareUserid, public: true, children: []});
   const defaultPageSearch = {type: 'pge', key: config.shareUserid+'share', title: config.shareUserid+'share', public: true, children: []}
   let [pageSearch, setPageSearch] = useState(defaultPageSearch);
   const [loading, setLoading] = useState(false);
  const [, setServerErrors] = useState(null);
  let [memoShown, setMemoShown] = useState(true);
  let [tidy, setTidy] = useState(1);
  let [after, setAfter] = useState(null);
  let [afterSearch, setAfterSearch] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const pageCache = useRef({})

   useEffect(()=>{
      doLoadShare(after)
   },[])
   const handleMore = () => {
     if(after){
      doLoadShare(after)
     }
   }
const doLoadShare = (after) => {
  setLoading(true)
  api.loadShare(after).then((response)=>{
     setLoading(false)
    if(response.results?.length > 0){
      pageRecent.children = pageRecent.children.concat(response.results)
    }
     if(response.after){
       setAfter(response.after[0]['@ref']['id'])
     }else{
       setAfter(null)
     }
     setPageRecent({...pageRecent})
  }).catch((error)=>{
     setLoading(false)
  })  
}
   const toggleClose = (node)=>() => {
      node.close = !node.close;
      setPageRecent({...pageRecent});    
      setPageSearch({...pageSearch});    
    } 
   const handleShow = (node)=>()=>{
      if(!checkAuth(userid, pathUserid)){
         setShowShare(true)
         setSelected(node)
      }else{
         setShow(true)
         setSelected(node)
      }
   }
   const moveItemChild = ()=>{

   }
   const moveItem = ()=>{

   }
   const memoIcons = ['memoIcon0', 'memoIcon1', 'memoIcon2' ]
   const toggleMemoIcon = (node) => () => {
     node.memoI = node.memoI? (node.memoI + 1) % 3: 1
     setPageRecent({...pageRecent});    
     setPageSearch({...pageSearch});  
   }
     //** auth check */
   const checkAuth = (userid, pathUserid) => {
      return userid === pathUserid
   }
   const toggleMemo = ()=>{
      memoShown = !memoShown
      setMemoShown(memoShown)
    }
    const toggleTidy = ()=>{
      tidy = tidy % 3 + 1
      setTidy(tidy)
      resetRecursiveClose(pageRecent, tidy)
      resetRecursiveClose(pageSearch, tidy)
    }
    
    //** search */
    function resetRecursiveClose(node, tidy){
      if(tidy === 1){
          node.close = false
          if(node.children && node.children.length > 0){
            for(let i = 0, n = node.children.length; i < n; i++){
              node.children[i].close = false
            }
          }
      }else if(tidy === 2){
        node.close = false;
        if(node.children && node.children.length > 0){
          for(let i = 0, n = node.children.length; i < n; i++){
            node.children[i].close = true
          }
        }
      }else if(tidy === 3){
        node.close = true;
      }
    }
    const resetSearchResult = () => {
      setAfterSearch(null)
      setPageSearch(defaultPageSearch)
    }
    const resetSearch = () => {
      afterSearch = null
      setAfterSearch(afterSearch)
      pageSearch = defaultPageSearch
      setPageSearch(pageSearch);
    }
    const setSearchString = (searchWord) => {
      
      if(!searchWord){
        return;
      }
      resetSearch()
      setSearchKeyword(searchWord)
      doSearchShare(searchWord)

    }//setSearchString
    const handleMoreSearch = () => {
      if(afterSearch){
        doSearchShare(searchKeyword)
      }
    }
    const doSearchShare = (searchKeyword) => {
      if(searchKeyword && afterSearch !== 'end'){
        setLoading(true)
        api.searchShare(searchKeyword, afterSearch).then((response)=>{
          setLoading(false)
          if(response.results?.length > 0){
            pageSearch.children = pageSearch.children.concat(response.results)
          }
          if(response.afterSearch){
            setAfterSearch(response.afterSearch[0]['@ref']['id'])
          }else{
            setAfterSearch('end')
          }
          setPageSearch({...pageSearch})
        }).catch((error)=>{
          setLoading(false)
          setServerErrors(error.errors[0])
        })
      }
    }
  const HeaderMyhomeProps = {userid, pathUserid, page: pageRecent, toggleMemo, memoShown, setSearchString, resetSearchResult, toggleTidy, tidy, pageCache, checkAuth}
  const itemProps = {toggleClose, handleShow, parentPath: path, moveItemChild, moveItem, toggleMemoIcon, memoIcons}
   const modalShareProps = {showShare, setShowShare, selected, userid, page: pageRecent, setPage: setPageRecent, setServerErrors, setLoading, pathUserid, pathTitle, 
   token, checkAuth, prevPathTitle}
  const exampleStyle = {
    color: 'blue', 
  }
   return(
   <>
   {memoShown && <link rel="stylesheet" type="text/css" href={"/vendor/memoShown.css"} />}
   {loading && <img className='ajaxLoader' src={ajaxLoader} alt='ajaxLoader'/>}
   <HeaderMyhome {...HeaderMyhomeProps}/>
   <div className="share">
    <header className="bg-primary py-5">
      <div className="container h-100">
        <div className="row h-100 align-items-center">
            <div className="col-lg-12">
              <h1 className="display-4 text-white mt-5 mb-2">{t.mainTitle}</h1>
              <span className="lead mb-5 text-white">{t.humanSelected}</span><br/>
              <span className="lead mb-5 text-white">{t.embedInto}</span><br/>
              <span className="lead mb-5 text-white">{t.url1}</span>
              <span className="lead mb-5 text-white"> ( {t.example1}<a style={exampleStyle} target="_blank" rel="noopener noreferrer" href={t.exampleUrl1}>{t.exampleUrl1}</a> )</span><br/>
              <span className="lead mb-5 text-white">{t.url2}</span>
              <span className="lead mb-5 text-white"> ( {t.example2}<a style={exampleStyle} target="_blank" rel="noopener noreferrer" href={t.exampleUrl2}>{t.exampleUrl2}</a> )</span><br/>
              <span className="lead mb-5 text-white">{t.embedCode}</span><br/>
              <span className="lead mb-5 text-white">&lt;iframe src='https://neohompy.com/share/bookTitle' width='350px' height='400px'&gt;&lt;/iframe&gt;</span><br/>
            </div>
        </div>
      </div>
    </header>  
   </div>
   {tidy !== 3 &&
    <>
      {/* <HeaderShare {...HeaderShareProps}/> */}
      <div className="container main">
      {pageSearch &&
        <> 
         <div className="page pageSearch">
            <i className={pageSearch.close ? (pageSearch.children?.length?"closeIcon": "nochildIcon"):"titleIcon"}  onClick={toggleClose(pageSearch)} ></i>
            <div className={pageSearch.public?"alert alert-dark header public": "alert alert-dark header private"} >
              <a href={pageSearch.href} target="_blank" rel="noopener noreferrer">
                <span className={'title'}>.</span>
              </a>
              <div className={pageSearch.public?"headerTitle public":"headerTitle private"}>{t.searchResult}</div>
              {pageSearch.memo && <i className={memoIcons[pageSearch.memoI || 0]} onClick={toggleMemoIcon(pageSearch)}></i>}
              <i className="settings" onClick={handleShow(pageSearch)}></i>
            </div>
            <div className={pageSearch.memoI?"memoI": "memo"}>{pageSearch.memo}</div>
            <div className="content">
            {!pageSearch.close && pageSearch.children && pageSearch.children.length > 0 && pageSearch.children.map(book => <BookShare key={book?.key} book={book} {...itemProps}/>)}  
            </div>
            <div className={"searchShareMore"} >
              <a href={pageSearch.href} target="_blank" rel="noopener noreferrer">
                <span className={pageSearch.match ? "title match" : 'title'}>.</span>
              </a>
              <div className={pageSearch.public?"headerTitle public":"headerTitle private"}><span onClick={handleMoreSearch} className="shareMore" >{(!afterSearch || afterSearch==='end')?t.end:t.moreSearchResult}</span></div>
            </div>
         </div>
        </>
      }
      {pageRecent &&
        <> 
         <div className="page pageRecent">
            <i className={pageRecent.close ? (pageRecent.children?.length?"closeIcon": "nochildIcon"):"titleIcon"}  onClick={toggleClose(pageRecent)} ></i>
            <div className={pageRecent.public?"alert alert-dark header public": "alert alert-dark header private"} >
              <a href={pageRecent.href} target="_blank" rel="noopener noreferrer">
                <span className={pageRecent.match ? "title match" : 'title'}>.</span>
              </a>
              <div className={pageRecent.public?"headerTitle public":"headerTitle private"}>{t.recentBooks}</div>
              {pageRecent.memo && <i className={memoIcons[pageRecent.memoI || 0]} onClick={toggleMemoIcon(pageRecent)}></i>}
              <i className="settings" onClick={handleShow(pageRecent)}></i>
            </div>
            <div className={pageRecent.memoI?"memoI": "memo"}>{pageRecent.memo}</div>
            <div className="content">
            {!pageRecent.close && pageRecent.children && pageRecent.children.length > 0 && pageRecent.children.map(book => <BookShare key={book?.key} book={book} {...itemProps}/>)}  
            </div>
            <div className={"loadShareMore"} >
              <div><span onClick={handleMore} className="shareMore" >{after?t.moreRecentBooks:t.end}</span></div>
            </div>
         </div>
        </>
      }
      </div>
    </>
   }
      {showShare && <MyModalShare {...modalShareProps}/>}
   </>
   ) 
}

export default Share