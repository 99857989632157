import shortid from 'shortid';

  let randomId = '';
  let nodeCount = 0;
  const getId = () => {
    if(!randomId){
      randomId = shortid();
    }
    return randomId + nodeCount++;
  }
  const createBook = (title, links, start, end, linkCount, bookCount) => {
    linkCount.count += (end - start);
    return { type: 'bok', title: bookCount + '_' + links[start].title, key: getId(), children: links.slice(start,end), close: true};
  }
  const moveLinksIntoChildren = ({folder, step}) => {
    let links;
    if(Array.isArray(folder)){ //rootFolders
      links = folder.filter((item) => {
        return item.type === 'lnk';
      });
    }else{ // folder object
      links = folder.links;
    }

    let title = folder.title;
    let length = links.length;
    let start = 0;
    let end = ((start + step) < length) ? start + step : length;
    let linkCount = {count: 0};
    let bookCount = 0;
    let bookArr = [];
    while(linkCount.count < length){
      bookArr[bookCount] = createBook(title, links, start, end, linkCount, bookCount);
      start += step;
      end = (start + step) < length ? start + step : length;
      bookCount++;
    }

    return bookArr;

    
  }

  export default moveLinksIntoChildren;