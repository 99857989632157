export const mockPageData = {
  type: "pge",
  key: "home",
  title: "home",
  close: false,
  default: false,
  children: [
    {
      children: [
        {
          type: "bok",
          key: "5z7F5TIaH2",
          title: "Temp",
        },
      ],
      type: "fld",
      key: "5z7F5TIaH3",
      title: "Temp",
    },
    {
      children: [
        {
          type: "bok",
          key: "d9RyT4vpy4",
          title: "Daily",
        },
        {
          type: "bok",
          key: "d9RyT4vpy1",
          title: "Email",
        },
        {
          type: "bok",
          key: "d9RyT4vpy3",
          title: "Search",
        },
      ],
      type: "fld",
      key: "d9RyT4vpy0",
      title: "Daily",
    },
    {
      children: [
        {
          type: "bok",
          key: "dyGdiLYg32",
          title: "News",
        },
        {
          type: "bok",
          key: "kgae9aqCc0",
          title: "Science",
        },
      ],
      type: "fld",
      key: "d9RyT4vpy6",
      title: "News",
    },
    {
      children: [
        {
          type: "bok",
          key: "Xju6PbN7o1",
          title: "Social",
        },
      ],
      type: "fld",
      key: "d9RyT4vpy8",
      title: "Social",
    },
    {
      children: [
        {
          type: "bok",
          key: "pU12o0gLg5",
          title: "Shopping",
        },
      ],
      type: "fld",
      key: "pU12o0gLg4",
      title: "Shopping",
    },
    {
      children: [
        {
          type: "bok",
          key: "5z7F5TIaH1",
          title: "Business",
        },
        {
          type: "bok",
          key: "kgae9aqCc4",
          title: "Real Estate",
        },
        {
          type: "bok",
          key: "kgae9aqCc3",
          title: "Finance",
        },
      ],
      type: "fld",
      key: "kgae9aqCc2",
      title: "Business",
    },
    {
      children: [
        {
          type: "bok",
          key: "dyGdiLYg35",
          title: "Game",
        },
        {
          type: "bok",
          key: "dyGdiLYg34",
          title: "Video",
        },
        {
          type: "bok",
          key: "dyGdiLYg33",
          title: "Music",
        },
      ],
      type: "fld",
      key: "d9RyT4vpy7",
      title: "Entertainment",
    },
    {
      children: [
        {
          type: "bok",
          key: "iLSYvK3DA0",
          title: "Lifestyle",
        },
        {
          type: "bok",
          key: "RR8SEXtEw0",
          title: "Food",
        },
        {
          type: "bok",
          key: "Xju6PbN7o0",
          title: "Health",
        },
      ],
      type: "fld",
      key: "JGfJ_gc3L0",
      title: "Lifestyle",
    },
    {
      children: [
        {
          type: "bok",
          key: "pU12o0gLg1",
          title: "Hobby",
        },
        {
          type: "bok",
          key: "pU12o0gLg3",
          title: "Arts",
        },
        {
          type: "bok",
          key: "pU12o0gLg2",
          title: "Travel",
        },
      ],
      type: "fld",
      key: "rkmMsW9V10",
      title: "Hobby",
    },
  ],
};
export const mockPageList = [
  {
    type: "pge",
    key: "home",
    title: "home",
  },
];
export const shortPageData = {
  "type": "pge",
  "key": "home",
  "title": "home",
  "close": false,
  "default": false,
  "children": [
    {
      "children": [
        {
          "type": "bok",
          "key": "d9RyT4vpy4",
          "title": "book",
          "children": [
            {
              "children": [
                {
                  "type": "lnk",
                  "key": "I4FxYgS3056",
                  "title": "link"
                }
              ],
              "type": "lnk",
              "key": "I4FxYgS3034",
              "title": "link"
            }
          ],
        }
      ],
      "type": "fld",
      "key": "d9RyT4vpy0",
      "title": "category"
    }
  ]
}



