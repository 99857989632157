import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Home from './routes/Home';
import Share from './routes/Share';
import Privacy from './routes/Privacy';
import ShareEmbed from './routes/ShareEmbed';
import Myhome from './routes/Myhome';
import LoginProvider from './components/LoginProvider';
import LangProvider from './components/LangProvider';

function App(props) {
  return (
    <LangProvider>
      <LoginProvider>
        <Router>
            <Switch>
              <Route exact path='/home' component={Home} />
              <Route exact path="/share" component={Share} />
              <Route exact path="/privacy" component={Privacy} />
              <Route path="/share/:bookTitle" component={ShareEmbed} />
              <Route path="/:pathUserid?/:pathTitle?" component={Myhome} />
            </Switch>
        </Router>
      </LoginProvider>
    </LangProvider>
  );
}
export default App;
