function cutByLen(str, maxByte) {
    let b, c, i;
    for(b = i = 0; i < str.length; i++) {
        c = str.charCodeAt(i);
        // b += c >> 7 ? 2 : 1;
        b += c > 127 ? 2 : 1;
        if (b > maxByte){
            break;
        }
    }
    return str.substring(0,i);
}

export default cutByLen;