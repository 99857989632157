import React, {useContext} from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import config from '../../data/config';
import {LangContext} from '../../components/LangProvider';

function LangSelector(props){
    const {lang, setCookies} = useContext(LangContext);
    const handleLang = (e) => {
      setCookies([config.cookiesName], e);
    }

    return(
    <DropdownButton
      alignRight
      title={lang.header.name}
      id="dropdown-menu-align-right"
      onSelect={handleLang}
    >
      <Dropdown.Item eventKey="ko">한국어</Dropdown.Item>
      <Dropdown.Item eventKey="en">English</Dropdown.Item>
    </DropdownButton>
    );
}

export default LangSelector;