const submitBackupWithPass = (data) => {
  return fetch('/.netlify/functions/users-submitBackupWithPass', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}
const submitRecoverWithPass = (data) => {
  return fetch('/.netlify/functions/users-submitRecoverWithPass', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  })
}
export default {
  submitBackupWithPass: submitBackupWithPass,
  submitRecoverWithPass: submitRecoverWithPass,
}
//==============================