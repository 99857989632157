import React from 'react'
import {
    Link,
  } from "react-router-dom";

function PageBtn(props){
    const {pageDelegate, pathUserid, handleShowPage, t, currentPathTitle, setPathUserid, setPathTitle} = props
    const getClassName = (pageDelegate)=>{
        if(pageDelegate.public){
            return (pageDelegate.title===currentPathTitle.current?"btn btn-primary public": "btn btn-secondary public")
        }else{
            return (pageDelegate.title===currentPathTitle.current?"btn btn-primary private": "btn btn-secondary private")
        }
    }
    const handleNewPath = () => {
        setPathUserid(pathUserid)
        setPathTitle(pageDelegate.title)
    }
    return(
        <Link to={`/${pathUserid}/${pageDelegate.title}`}>
            <button className={getClassName(pageDelegate)} onClick={handleNewPath}>{pageDelegate.title}
                {pageDelegate.public && <h6><span className="badge badge-success public">{t.public}</span></h6>}
                <i className="settings" onClick={handleShowPage(pageDelegate)}></i>
            </button>
        </Link>
    )

}

export default PageBtn