import React, {useContext} from 'react';
import {LangContext} from '../../components/LangProvider';

function Search(props){
  //** props */
  const {setSearchString, resetSearchResult} = props
  const {lang} = useContext(LangContext);
  const t = lang.Search
  const searchRef = React.createRef();
  const handleReset = (event) => {
    event.preventDefault();
    event.stopPropagation();
    // reset input
    searchRef.current.value = '';
    resetSearchResult();
  }

    return(
        <>
          <span className="nav-item searchItem">
              <form
              style={{ display: 'inline-block' }}
              onSubmit={event => {
              event.preventDefault();
              event.stopPropagation();
              setSearchString( searchRef.current.value )
              }}
              >
                  <input
                  id="find-box"
                  type="text"
                  placeholder={t.searchHolder}
                  style={{ fontSize: '1rem'}}
                  ref={searchRef}
                  />
                  <button
                  type="submit"
                  className="btn btn-primary searchBtn"
                  >
                  <i className="search"></i>
                  </button>
                  <button
                  type="button"
                  className="btn btn-success searchBtn"
                  >
                  <i className="searchReset" onClick={handleReset}></i>
                  </button>
              </form>
          </span>
        </>
    );
}

export default Search;