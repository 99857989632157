import React, {useContext, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {LoginContext} from '../../components/LoginProvider';
import {LangContext} from '../../components/LangProvider';
import {validateEmail, validateUserid, validatePass, validatePass2, validateCode} from '../../utils/validation';
import api from '../../utils/api';
import ajaxLoader from '../../icons/ajax-loader.gif';
import config from '../../data/config'

// let user = {};
function LoginModal(props){
    //** input validation */
    const {lang, langCode} = useContext(LangContext);
    const t = lang.loginmodal;
    const {setUserid} = useContext(LoginContext);
    const [user] = useState({})
    const {showLoginModal, showSignupModal, handleClose} = props;
    const [emailError, setEmailError] = useState(null);
    const [useridError, setUseridError] = useState(null);
    const [passError, setPassError] = useState(null);
    const [passError2, setPassError2] = useState(null);
    const [veriReady, setVeriReady] = useState(true)
    const [verified, setVerified] = useState(false)
    const [verifying, setVerifying] = useState(false)
    const [verifyError, setVerifyError] = useState(null)
    let disabledLogin = useridError || passError;
    let disabledSignup = emailError || useridError || passError || passError2;

    const handleEmail = (e) => {
        user.email = e.target.value;
        setEmailError(validateEmail(user));
        setVeriReady(true)
        setVerifying(false)
        setVerified(false)
    }
    const handleRequestCode = () => {
      // request code for email verification
      const email_error = validateEmail(user);
      setEmailError(email_error?.errors[0]);

      if(!email_error){
        setServerErrors(null);
        setLoading(true);
        api.requestCode(user.email, langCode).then((response) => {
          if(response.errors && response.errors[0]){
            setServerErrors(response?.errors[0]);
            setLoading(false);
            setVeriReady(true)
            setVerifying(false)
            setVerified(false)
          }else{
            setLoading(false);
            setVeriReady(false)
            setVerifying(true)
            setVerified(false)
          }
        }).catch((e) => {
          setLoading(false);
          setVeriReady(true)
          setVerifying(false)
          setVerified(false)
        })
      }
    }
    const handleCode = (e) => {
      user.code = (e.target.value).trim();
      const code_error = validateCode(user);
      setVerifyError(code_error?.errors[0])
    }
    const handleVerifyCode = () => {
      const email_error = validateEmail(user);
      setEmailError(email_error?.errors[0]);
      const code_error = validateCode(user);
      setVerifyError(code_error?.errors[0])
      if(!code_error && !email_error){
        setVerifyError(null);
        setLoading(true);
        api.verifyCode(user.email, user.code).then((response) => {
          if(response.errors && response.errors[0]){
            setVerifyError(response?.errors[0]);
            setLoading(false);
            setVeriReady(true)
            setVerifying(false)
            setVerified(false)
          }else{
            setVerifyError(null);
            setLoading(false);
            setVeriReady(false)
            setVerifying(false)
            setVerified(true)
          }
        }).catch((error) => {
          if(error && error.errors){
            setVerifyError(error?.errors[0]);
          }
          setLoading(false);
          setVeriReady(true)
          setVerifying(false)
          setVerified(false)
        })
      }
    }
    const handleUserid = (e) => {
      user.userid = e.target.value;
      setUseridError(validateUserid(user));
    }
    const handlePass = (e) => {
      user.pass = e.target.value;
      setPassError(validatePass(user));
    }
    const handlePass2 = (e) => {
      user.pass2 = e.target.value;
      setPassError2(validatePass2(user));
    }
    const handleLogin = (e) => {
      const userid_error = validateUserid(user);
      setUseridError(userid_error?.errors[0]);
      const pass_error = validatePass(user);
      setPassError(pass_error?.errors[0]);
      disabledLogin = userid_error || pass_error;
      if(!disabledLogin){
        submitLogin(user)
      }
    }
    const handleSignup = (e) => {
      const email_error = validateEmail(user);
      setEmailError(email_error?.errors[0]);
      if(!verified){
        setVerifyError('notverified_error');
      }
      const userid_error = validateUserid(user);
      setUseridError(userid_error?.errors[0]);
      const pass_error = validatePass(user);
      setPassError(pass_error?.errors[0]);
      const pass_error2 = validatePass2(user);
      setPassError2(pass_error2?.errors[0]);
      if(config.useEmailVerification){
        disabledSignup = email_error || userid_error || pass_error || pass_error2 || !verified;
      }else{
        disabledSignup = email_error || userid_error || pass_error || pass_error2;
      }
      if(!disabledSignup){
        submitSignup(user);
      }
    }
    //** input submit */
    const [serverErrors, setServerErrors] = useState(null);
    const [loading, setLoading] = useState(false);

    const submitSignup = (user) => {
      setServerErrors(null);
      setLoading(true);
      api.signupWithUser(user).then((response) => {
        if(response.errors && response.errors[0]){
          setServerErrors(response?.errors[0]);
          setLoading(false);
        }else{
          api.saveLocalToken(response.token)
          setUserid(response.userid);
          setLoading(false);
          handleClose();
          window.location.href = `/${response.userid}`
        }
      }).catch((e) => {
        setLoading(false);
      })
    }
    const submitLogin = (user) => {
      setServerErrors(null);
      setLoading(true);
      api.loginWithUser(user).then((response) => {
        if(response.errors && response.errors[0]){
          setServerErrors(response?.errors[0]);
          setLoading(false);
        }else{
          api.saveLocalToken(response.token)
          setUserid(response.userid);
          setLoading(false);
          handleClose();
          window.location.href = `/${response.userid}`
        }
      }).catch((e) => {
        setLoading(false);
      })
    }
    return(
      <Modal
      show={(showLoginModal || showSignupModal)}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
    <Modal.Title>{showLoginModal && t.login}{showSignupModal && t.signup}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="form-inline">
            {showSignupModal && 
            <div className="form-group">
              <label htmlFor="inputEmail">{t.email}</label>
              <input type="email" id="inputEmail6" className="form-control mx-sm-3" aria-describedby="emailHelpInline" placeholder={t.emailholder} onChange={handleEmail}/>
              {!emailError &&             
               <small id="emailHelpInline" className="text-muted">
               {t.emailguide}
               </small>}
              {emailError &&             
              <span id="emailHelpInline" className="loginError">
                {t[emailError]}
              </span>}
            </div>}
            {showSignupModal && config.useEmailVerification && 
            <div className="form-group">
              <label htmlFor="requestcode">{t.requestcode_label}</label>
              {veriReady && 
              <Button id="requestcode" className="form-control mx-sm-3" variant="success" onClick={handleRequestCode}>
                {t.requestcode}
              </Button>
              }
              { verifying && 
              <>
              <input type="text" id="verifycode_input" className="form-control mx-sm-3" aria-describedby="textHelpInline" placeholder={t.verifycode_holder} onChange={handleCode}/>
              <Button id="verifycode" className="form-control mx-sm-1" variant="secondary" onClick={handleVerifyCode}>
                {t.verifycode}
              </Button>
              </>
              }
              { verified && 
                <div className="form-control mx-sm-3 verified">verified</div>
              }
              {verifyError &&             
              <span id="textHelpInline" className="loginError">
                {t[verifyError]}
              </span>}
            </div>}
            <div className="form-group">
              <label htmlFor="inputUserid">{t.userid}</label>
              <input type="text" id="inputUserid6" className="form-control mx-sm-3" aria-describedby="useridHelpInline" placeholder={t.useridholder} onChange={handleUserid}/>
              {!useridError &&             
              <small id="useridHelpInline" className="text-muted">
                {t.useridguide}
              </small>}
              {useridError &&             
              <span id="useridHelpInline" className="loginError">
                {t[useridError]}
              </span>}
            </div>
            { loading && <img className='ajaxLoader' src={ajaxLoader} alt='ajaxLoader'/>}
            <div className="form-group">
              <label htmlFor="inputPassword">{t.pass}</label>
              <input type="password" id="inputPassword6" className="form-control mx-sm-3" aria-describedby="passwordHelpInline" placeholder={t.passholder} onChange={handlePass}/>
              {!passError && <small id="passwordHelpInline" className="text-muted">
                {t.passguide}
              </small>}
              {passError && 
              <span id="passwordHelpInline" className="loginError">
                {t[passError]}
              </span>}
            </div>
            {showSignupModal && 
            <div className="form-group">
              <label htmlFor="inputPassword2">{t.pass2}</label>
              <input type="password" id="inputPassword2" className="form-control mx-sm-3" aria-describedby="confirmPasswordHelpInline" placeholder={t.passholder2} onChange={handlePass2}/>
              {!passError2 && <small id="confirmPasswordHelpInline" className="text-muted">
                {t.passguide2}
              </small>}
              {passError2 && 
                <span id="passwordHelpInline2" className="loginError">
                {t[passError2]}
                </span>}
            </div>}
        </form>
      </Modal.Body>
      <Modal.Footer>
        {serverErrors &&  
          <span className="loginError">
          {t[serverErrors]}
          </span>}
        <Button variant="secondary" onClick={handleClose}>
          {t.close}
        </Button>
        {showLoginModal && 
        <Button variant="primary" onClick={handleLogin} disabled={disabledLogin}>
          {t.submit}
        </Button>
        }
        {showSignupModal &&
        <Button variant="primary" onClick={handleSignup} disabled={disabledSignup}>
          {t.submit}
        </Button>
        }
      </Modal.Footer>
    </Modal>
    );
}

export default LoginModal;