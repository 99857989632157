import consoleSave from './consoleSave';

export const saveAsFile = (userid, pathTitle, page) => {
    const bookmarksHtml = doGetHtmlFromJson(page);
    consoleSave(bookmarksHtml, `${userid}-${pathTitle}-${new Date().toLocaleDateString()}.html`);
}
export const saveAsJSON = (userid, pathTitle, page) => {
    const JSONString = JSON.stringify(page);
    consoleSave(JSONString, `${userid}-${pathTitle}-${new Date().toLocaleDateString()}.json`);
}

const headerHtml = `<!DOCTYPE NETSCAPE-Bookmark-file-1>
<!-- This is an automatically generated file.
     It will be read and overwritten.
     DO NOT EDIT! -->
<META HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=UTF-8">
<TITLE>Bookmarks</TITLE>
<H1>root</H1>\n`;
let tempHtml = headerHtml + '<DL><p>\n'; // to compensate the last additional '</DL><p>' 
let treeDepth = 1;
const doGetHtmlFromJson = (bookmarksJson) => {
    getHtmlFromJson([bookmarksJson]);
    return tempHtml;
}
const getHtmlFromJson = (bookmarksJson) => {

    let node;
    for(let i = 0; i < bookmarksJson.length; i++){
        node = bookmarksJson[i];
        if(isFolder(node) || isBook(node)){
            startFolder(node);
        }else if(isLink(node)){
            startLink(node);
        }
        if(node.children){
            treeDepth++;
            getHtmlFromJson(node.children);
        }
    }
    treeDepth--;
    endFolder();
}
const isFolder = (node) => {
    return (node.type === 'fld');
}
const isBook = (node) => {
    return (node.type === 'bok');
}
const isLink = (node) => {
    return (node.type === 'lnk');
}
const startFolder = (node) => {
    let data = node.bgicon ? 'bgicon="'+node.bgicon+'" ':'';
    data = node.bgurl ? 'bgurl="'+node.bgurl+'" ':'';
    data += node.bgcolor ? 'bgcolor="'+node.bgcolor+'" ': '';
    data += node.color ? 'color="'+node.color+'" ':'';
    tempHtml += addIndent(treeDepth) + '<DT><H3 '+ data +'>' + node.title.trim() + '</H3>\n' + addIndent(treeDepth) + '<DL><p>\n';
}
const endFolder = () => {
    tempHtml += addIndent(treeDepth) + '</DL><p>\n';
}
const startLink = (node) => {
    tempHtml += addIndent(treeDepth) + '<DT><A HREF="' + node.href + '">' + node.title.trim() +'</A>\n' + addIndent(treeDepth) + '<DL><p>\n';
}
const addIndent = (treeDepth) => {
    let indent = '';
    for(let i = 0; i < treeDepth; i++ ){
        indent += '    ';
    }
    return indent;
}
