import React, {useContext} from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {LangContext} from '../../components/LangProvider';

function Home(){
  const {lang} = useContext(LangContext);
  const t = lang.Home

    return(
   <> 
   <Header/>
         
    {/* <!-- Header --> */}
    <header className="bg-primary py-5 mb-5">
        <div className="container h-100">
        <div className="row h-100 align-items-center">
            <div className="col-lg-12">
              <h1 className="display-4 text-white mt-5 mb-2">{t.title}</h1>
              <p className="lead mb-5 text-white-50">{t.subtitle}</p>
            </div>
        </div>
        </div>
    </header>    
  {/* <!-- Page Content --> */}
  <div className="container">

    <div className="row">
      <div className="col-md-8 mb-5">
        <h2>{t.purpose}</h2>
        <hr/>
        <p>{t.purpose1}</p>
        <p>{t.purpose2}</p>
        <p>{t.purpose3}</p>
        <p>{t.purpose4}</p>
        <p>{t.purpose5}</p>
        <p>{t.purpose6}</p>
        <p>{t.purpose7}</p>
        <a className="btn btn-primary btn-lg" href="http://neohompy.com/home">{t.signup}</a>
      </div>
      <div className="col-md-4 mb-5">
        <h2>{t.contact}</h2>
        <hr/>
        <address>
          <strong>{t.contactName}</strong>
        </address>
        <address>
          <a href="mailto:#">{t.contactEmail}</a>
        </address>
      </div>
    </div>
    {/* <!-- /.row --> */}

    <div className="row">
      <div className="col-md-4 mb-5">
        <div className="card h-100">
          <img className="card-img-top" src={t.card1.image} alt=""/>
          <div className="card-body">
            <h4 className="card-title">{t.card1.title}</h4>
            <p className="card-text">{t.card1.descr1}</p>
            <p className="card-text">{t.card1.descr2}</p>
            <p className="card-text">{t.card1.descr3}</p>
          </div>
          <div className="card-footer">
            <a href={t.moreUrl} className="btn btn-primary">{t.card1.more}</a>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-5">
        <div className="card h-100">
          <img className="card-img-top" src={t.card2.image} alt=""/>
          <div className="card-body">
            <h4 className="card-title">{t.card2.title}</h4>
            <p className="card-text">{t.card2.descr1}</p>
            <p className="card-text">{t.card2.descr2}</p>
            <p className="card-text">{t.card2.descr3}</p>
          </div>
          <div className="card-footer">
          <a href={t.moreUrl} className="btn btn-primary">{t.card2.more}</a>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-5">
        <div className="card h-100">
          <img className="card-img-top" src={t.card3.image} alt=""/>
          <div className="card-body">
            <h4 className="card-title">{t.card3.title}</h4>
            <p className="card-text">{t.card3.descr1}</p>
            <p className="card-text">{t.card3.descr2}</p>
            <p className="card-text">{t.card3.descr3}</p>
          </div>
          <div className="card-footer">
          <a href={t.moreUrl} className="btn btn-primary">{t.card3.more}</a>
          </div>
        </div>
      </div>
    </div>
    {/* <!-- /.row --> */}
    <div className="row">
      <div className="col-md-4 mb-5">
        <div className="card h-100">
          <img className="card-img-top" src={t.card4.image} alt=""/>
          <div className="card-body">
            <h4 className="card-title">{t.card4.title}</h4>
            <p className="card-text">{t.card4.descr1}</p>
            <p className="card-text">{t.card4.descr2}</p>
            <p className="card-text">{t.card4.descr3}</p>
          </div>
          <div className="card-footer">
            <a href={t.moreUrl} className="btn btn-primary">{t.card4.more}</a>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-5">
        <div className="card h-100">
          <img className="card-img-top" src={t.card5.image} alt=""/>
          <div className="card-body">
            <h4 className="card-title">{t.card5.title}</h4>
            <p className="card-text">{t.card5.descr1}</p>
            <p className="card-text">{t.card5.descr2}</p>
            <p className="card-text">{t.card5.descr3}</p>
          </div>
          <div className="card-footer">
          <a href={t.moreUrl} className="btn btn-primary">{t.card5.more}</a>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-5">
        <div className="card h-100">
          <img className="card-img-top" src={t.card6.image} alt=""/>
          <div className="card-body">
            <h4 className="card-title">{t.card6.title}</h4>
            <p className="card-text">{t.card6.descr1}</p>
            <p className="card-text">{t.card6.descr2}</p>
            <p className="card-text">{t.card6.descr3}</p>
          </div>
          <div className="card-footer">
          <a href={t.moreUrl} className="btn btn-primary">{t.card6.more}</a>
          </div>
        </div>
      </div>
    </div>
    {/* <!-- /.row --> */}

  </div>
  {/* <!-- /.container --> */}
  <Footer/>
  </>
    );
}

export default Home;