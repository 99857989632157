import config from '../data/config';
const savePathTitle = (userid, pathTitle) => {
  let pathTitles = JSON.parse(localStorage.getItem(config.pathTitles))
  if(!pathTitles){
    pathTitles = {}
  }
  if(!pathTitles[userid]){
    pathTitles[userid] = {}
  }
  pathTitles[userid] = pathTitle
  localStorage.setItem(config.pathTitles, JSON.stringify(pathTitles));
}
const loadPathTitle = (userid) => {
  const pathTitles = JSON.parse(localStorage.getItem(config.pathTitles))
  if(pathTitles && pathTitles[userid]){
    return pathTitles[userid]
  }else{
    return ''
  }
}
const removePathTitle = (userid) => {
  savePathTitle(userid, '')
}
const saveLocalToken = (tokenString) => {
  localStorage.setItem(config.localTokenName, tokenString);
}
const loadLocalToken = () => {
  return localStorage.getItem(config.localTokenName);
}
const removeLocalToken = () => {
  localStorage.removeItem(config.localTokenName);
}
const loginWithToken = (tokenString) => {
  return fetch('/.netlify/functions/users-loginWithToken', {
    body: JSON.stringify({token: tokenString}),
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => {
    return error.json()
  }) 
}
const loginWithUser = (user) => {
  return fetch('/.netlify/functions/users-loginWithUser', {
    body: JSON.stringify(user),
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => {
    return error.json()
  }) 
}
const logoutWithToken = (tokenString) => {
  return fetch('/.netlify/functions/users-logoutWithToken', {
    body: JSON.stringify({token: tokenString}),
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => {
    return error.json()
  }) 
}
const signupWithUser = (data) => {
  return fetch('/.netlify/functions/users-signupWithUser', {
    body: JSON.stringify(data),
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => {
    return error.json()
  }) 
}
const signoutWithUser = (user) => {
  return fetch('/.netlify/functions/users-signoutWithUser', {
    body: JSON.stringify(user),
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => {
    return error.json()
  }) 
}
const changePassWithUser = (user) => {
  return fetch('/.netlify/functions/users-changePassWithUser', {
    body: JSON.stringify(user),
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => {
    return error.json()
  }) 
}
const saveRemoteSource = (source, token, timestamp)=>{
  return fetch('/.netlify/functions/myhomes-saveRemoteSourceWithToken', {
    body: JSON.stringify({source, token, ts: timestamp}),
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => {
    return error.json()
  }) 
}
const loadRemotePage = (pathUserid, pathTitle, token, ts)=>{
  return fetch('/.netlify/functions/myhomes-loadRemotePageWithToken', {
    body: JSON.stringify({pathUserid, pathTitle, token, ts}),
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => {
    return error.json()
  }) 
}
const deleteRemoteSource = (source, token)=>{
  return fetch('/.netlify/functions/myhomes-deleteRemoteSourceWithToken', {
    body: JSON.stringify({token, source}),
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => {
    return error.json()
  }) 
}
const moveItemRemote = ({targetDelegate, sourceDelegate, token, ts})=>{
  return fetch('/.netlify/functions/myhomes-moveItemRemote', {
    body: JSON.stringify({targetDelegate, sourceDelegate, token, ts}),
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => {
    return error.json()
  })  
}
const moveItemChildRemote = ({targetDelegate, sourceDelegate, token, ts})=>{
  return fetch('/.netlify/functions/myhomes-moveItemChildRemote', {
    body: JSON.stringify({targetDelegate, sourceDelegate, token, ts}),
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => {
    return error.json()
  })  
}
const moveItemToPageCopy = ({targetDelegate, sourceDelegate, token, ts})=>{
  return fetch('/.netlify/functions/myhomes-moveItemToPageCopy', {
    body: JSON.stringify({targetDelegate, sourceDelegate, token, ts}),
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => {
    return error.json()
  })  
}
const moveItemToPageCut = ({targetDelegate, sourceDelegate, token, ts})=>{
  return fetch('/.netlify/functions/myhomes-moveItemToPageCut', {
    body: JSON.stringify({targetDelegate, sourceDelegate, token, ts}),
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => {
    return error.json()
  }) 
}
const moveItemToShare = ({targetDelegate, sourceDelegate, token, task})=>{
  return fetch('/.netlify/functions/shares-moveItemToShare', {
    body: JSON.stringify({targetDelegate, sourceDelegate, token, task}),
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => {
    return error.json()
  }) 
}
const requestCode = (email, langCode)=>{
  return fetch('/.netlify/functions/users-requestCode', {
    body: JSON.stringify({email, langCode}),
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => {
    return error.json()
  }) 
}
const verifyCode = (email, code)=>{
  return fetch('/.netlify/functions/users-verifyCode', {
    body: JSON.stringify({email, code}),
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => {
    return error.json()
  }) 
}
const loadShare = (after)=>{
  return fetch('/.netlify/functions/shares-loadShare', {
    body: JSON.stringify({after}),
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => {
    return error.json()
  })
}
const searchShare = (searchKeyword, afterSearch)=>{
  return fetch('/.netlify/functions/shares-searchShare', {
    body: JSON.stringify({searchKeyword, afterSearch}),
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => {
    return error.json()
  })
}
const searchShareEmbed = (searchKeyword, afterSearch)=>{
  return fetch('/.netlify/functions/shares-searchShareEmbed', {
    body: JSON.stringify({searchKeyword, afterSearch}),
    method: 'POST'
  }).then(response => {
    return response.json()
  }).catch(error => {
    return error.json()
  })
}

export default {
  savePathTitle,
  loadPathTitle,
  removePathTitle,
  saveLocalToken,
  loadLocalToken,
  removeLocalToken,
  loginWithToken,
  loginWithUser,
  logoutWithToken,
  signupWithUser,
  signoutWithUser,
  changePassWithUser,
  saveRemoteSource,
  loadRemotePage,
  deleteRemoteSource,
  moveItemRemote,
  moveItemChildRemote,
  moveItemToPageCopy,
  moveItemToPageCut,
  moveItemToShare,
  requestCode,
  verifyCode,
  loadShare,
  searchShare,
  searchShareEmbed,
}