const importJSON = (file, callAfterImport) => {
    const reader = new FileReader();
    reader.onloadend = (e) => {
        let content = reader.result;
        let importedPage = JSON.parse(content);
        if(Array.isArray(importedPage)){
          importedPage = importedPage[0]
        }
        callAfterImport(importedPage);
    };
    reader.readAsText(file, 'utf-8');
}

export default importJSON;