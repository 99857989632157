import React, {useContext, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {LoginContext} from '../../components/LoginProvider';
import {LangContext} from '../../components/LangProvider';
import api from '../../utils/api';
import ajaxLoader from '../../icons/ajax-loader.gif';
import config from '../../data/config';
import {validateUserid, validatePass, validateNewPass} from '../../utils/validation';

let user = {};
function LogoutModal(props){
    //** input validation */
    const {lang} = useContext(LangContext);
    const t = lang.logoutmodal;
    const {setUserid, token} = useContext(LoginContext);
    const {showLogoutModal, showSignoutModal, handleCloseLogout} = props;
    const [useridError, setUseridError] = useState(null);
    const [passError, setPassError] = useState(null);
    const [tab, setTab] = useState('signout')
    const [newpassError, setNewPassError] = useState(null);
    let disabledSignout = useridError || passError;
    let disabledChangePass = useridError || passError || newpassError;
    const handleUserid = (e) => {
      user.userid = e.target.value;
      setUseridError(validateUserid(user));
    }
    const handlePass = (e) => {
      user.pass = e.target.value;
      setPassError(validatePass(user));
    }
    const handleLogout = (e) => {
          submitLogout();
    }
    const handleSignout = (e) => {
      const userid_error = validateUserid(user);
      setUseridError(userid_error);
      const pass_error = validatePass(user);
      setPassError(pass_error);
      disabledSignout = userid_error || pass_error;
      if(!disabledSignout){
        submitSignout(user);
      }
    }
    //** input submit */
    const [serverErrors, setServerErrors] = useState(null);
    const [loading, setLoading] = useState(false);

    const submitLogout = () => {
      setServerErrors(null);
      setLoading(true);
      if(token && typeof token.current === 'string' && token.current.length > 50){
        api.logoutWithToken(token.current).then((response) => {
          if(response.errors && response.errors[0]){
            setServerErrors(response.errors[0]);
            setLoading(false);
          }else{
            api.removeLocalToken()
            api.removePathTitle(user.userid)
            setUserid(config.defaultUserid)
            setLoading(false);
            handleCloseLogout();
            window.location.href = `/${config.defaultUserid}`
          }
        }).catch((e) => {
          setLoading(false);
        })
      }else{
        setLoading(false);
      }
    }
    const submitSignout = (user) => {
      setServerErrors(null);
      setLoading(true);
      api.signoutWithUser(user).then((response) => {
        if(response.errors && response.errors[0]){
          setServerErrors(response.errors[0]);
          setLoading(false);
        }else{
          api.removeLocalToken()
          api.removePathTitle(user.userid)
          setUserid(config.defaultUserid)
          setLoading(false);
          handleCloseLogout();
          // window.location.href = `/${config.defaultUserid}`
          window.location.href = '/'
        }
      }).catch((e) => {
        setLoading(false);
      })
    }
    const handleTab = (e) => {
      setTab(e)
    }
    const handleNewPass = (e) => {
      user.newpass = e.target.value;
      setNewPassError(validateNewPass(user));
    } 
    const handleChangePass = (e) => {
      const userid_error = validateUserid(user);
      setUseridError(userid_error);
      const pass_error = validatePass(user);
      setPassError(pass_error);
      const newpass_error = validateNewPass(user);
      setNewPassError(newpass_error);
      disabledChangePass = userid_error || pass_error || newpass_error;
      if(!disabledChangePass){
        submitChangePass(user);
      }
    }
    const submitChangePass = (user) => {
      setServerErrors(null);
      setLoading(true);
      api.changePassWithUser(user).then((response) => {
        if(response.errors && response.errors[0]){
          setServerErrors(response.errors[0]);
          setLoading(false);
        }else{
          setLoading(false);
          handleCloseLogout();
        }
      }).catch((e) => {
        setLoading(false);
      })
    }   
    return(
      <Modal
      show={(showLogoutModal || showSignoutModal)}
      onHide={handleCloseLogout}
      backdrop="static"
      keyboard={false}
    >{showSignoutModal &&
      <Tabs defaultActiveKey="signout" id="tab" onSelect={handleTab}>
        <Tab eventKey="signout" title={t.signoutTab}>
        </Tab>
        <Tab eventKey="newpass" title={t.newpassTab}>
        </Tab>
      </Tabs>}
      <Modal.Header closeButton>
    <Modal.Title>{showLogoutModal ? t.logout : (tab==='signout'?t.signout: t.newpassTitle)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

      { loading && <img className='ajaxLoader' src={ajaxLoader} alt='ajaxLoader'/>}
      <div className={showLogoutModal? "logoutConfirm":"signoutConfirm"}>{showLogoutModal ? t.logoutConfirm : (tab==='signout'?t.signoutConfirm:t.newpassConfirm)}</div>
      {showSignoutModal && 
      <form className="form-inline">
        <div className="form-group">
          <label htmlFor="inputUserid">{t.userid}</label>
          <input type="text" id="inputUserid6" className="form-control mx-sm-3" aria-describedby="useridHelpInline" placeholder={t.useridholder} onChange={handleUserid}/>
          {!useridError &&             
          <small id="useridHelpInline" className="text-muted">
            {t.useridguide}
          </small>}
          {useridError &&             
          <span id="useridHelpInline" className="loginError">
            {t[useridError.errors[0]]}
          </span>}
        </div>
        <div className="form-group">
          <label htmlFor="inputPassword">{t.pass}</label>
          <input type="password" id="inputPassword6" className="form-control mx-sm-3" aria-describedby="passwordHelpInline" placeholder={t.passholder} onChange={handlePass}/>
          {!passError && <small id="passwordHelpInline" className="text-muted">
            {t.passguide}
          </small>}
          {passError && 
          <span id="passwordHelpInline" className="loginError">
            {t[passError.errors[0]]}
          </span>}
        </div>
        {tab==='newpass' && 
            <div className="form-group">
              <label htmlFor="inputNewPassword">{t.newpass}</label>
              <input type="password" id="inputNewPassword" className="form-control mx-sm-3" aria-describedby="newpasswordHelpInline" placeholder={t.newpassholder} onChange={handleNewPass}/>
              {!newpassError && <small id="newpasswordHelpInline" className="text-muted">
                {t.newpassguide}
              </small>}
              {newpassError && 
                <span id="passwordHelpInline2" className="loginError">
                {t[newpassError.errors[0]]}
                </span>}
        </div>}
      </form>
      }
      </Modal.Body>
      <Modal.Footer>
        {serverErrors &&  
          <span className="loginError">
          {t[serverErrors]}
          </span>}
        <Button variant="secondary" onClick={handleCloseLogout}>
          {t.closeBtn}
        </Button>
        {showLogoutModal && 
        <Button variant="success" onClick={handleLogout} >
          {t.logoutBtn}
        </Button>
        }
        {showSignoutModal && tab === 'signout' &&
        <Button variant="danger" onClick={handleSignout} disabled={disabledSignout}>
          {t.signoutBtn}
        </Button>
        }
        {showSignoutModal && tab === 'newpass' &&
        <Button variant="danger" onClick={handleChangePass} disabled={disabledSignout}>
          {t.newpassBtn}
        </Button>
        }
      </Modal.Footer>
    </Modal>
    );
}

export default LogoutModal;