import shortid from 'shortid';

let randomId = '';
let nodeCount = 0;
const getId = () => {
  if(!randomId){
    randomId = shortid();
  }
  return randomId + nodeCount++;
}

export default getId