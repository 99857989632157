import React from 'react';
import config from '../../data/config';
import { useCookies } from 'react-cookie';
import language from '../../data/language';

export const  LangContext = React.createContext(null);

function LangProvider({children}) {
    const [cookies, setCookies] = useCookies([config.cookiesName]);
    const langCode = (cookies.selectedLang || config.defaultLang)
    const lang = language[langCode];
    return (
        <LangContext.Provider value={{lang, setCookies, langCode}}>
            {children}
        </LangContext.Provider>
    )
}

export default LangProvider;