import React, {useContext, useState} from 'react';
import {
  Link,
} from 'react-router-dom';
import logo from '../../icons/neologo108x54.png';
import NavbarMyhome from '../../components/NavbarMyhome';
import {LangContext} from '../../components/LangProvider';
import {LoginContext} from '../../components/LoginProvider';
import Search from '../../components/Search';
import Button from 'react-bootstrap/Button';
import config from '../../data/config';

function HeaderMyhome(props){
  //** props */
  // const {toggleMemo, memoShown, setSearchString, resetSearchResult, toggleTidy, tidy, pathUserid} = props
  const {toggleMemo, memoShown, setSearchString, resetSearchResult, toggleTidy, tidy, pathUserid} = props
  //** context */
  const {lang} = useContext(LangContext);
  const t = lang.HeaderMyhome
  const {userid} = useContext(LoginContext);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const handleLogin = () => {
    setShowLoginModal(true);
  }
  const handleLogout = () => {
    setShowLogoutModal(true);
  }
  // const handleMyhome = () => {
  //   window.location.href = `${config.companyUrl}/${userid}`
  // }
  const isLoggedUser = (userid && userid!==config.defaultUserid)

  const baseUrl = window.location.origin;
  const bookmarklet = `<a href="javascript:var d=document,w=window,e=w.getSelection,k=d.getSelection,x=d.selection,s=(e?e():(k)?k():(x?x.createRange().text:'')),f='${baseUrl}/${pathUserid}',l=d.location,e=encodeURIComponent,u=f+'?u='+e(l.href)+'&t='+e(d.title)+'&s='+e(s);a=function(){if(!(z=w.open(u,'t'))){l.href=u;}else{z.focus();}};if(navigator.userAgent.indexOf('Firefox') != -1)setTimeout(a,0);else a();void(0)"><span className="label label-info" title="Drag this bookmarklet to your browser bookmarkbar" >${t.bookmarklet}</span></a>`;
  //** props */
  const SearchProps = {setSearchString, resetSearchResult}
  const NavbarMyhomeProps = {...props, showLoginModal, setShowLoginModal,  showLogoutModal, setShowLogoutModal}

  return(
    <>     
    {/* <!-- Navigation --> */}
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
      <div className="container">
        {/* <a className="navbar-brand" href={`${config.companyUrl}/${userid}${(new_lnk?.current?.u)?new_lnk.current.u:''}`}><img className="logo" src={logo} alt="logo"/></a> */}
        <Link className="navbar-brand" to="/home"><img src={logo} className="logo" alt="logo_icon" /></Link>
        {/* <Button variant="primary" onClick={handleMyhome}>{userid}</Button> */}
        <Link className="navbar-brand" to={`/${userid}`}><Button variant="primary">{userid}</Button></Link>
        {(isLoggedUser)?
        <button type="button" className="btn btn-success" onClick={handleLogout}>{t.logout}</button>
        :<button type="button" className="btn btn-success" onClick={handleLogin}>{t.login}</button>
        }
        <Button variant="secondary" onClick={toggleMemo}><i className={memoShown?"memoHidden":"memoShown"}></i></Button>
        <Button variant="secondary" onClick={toggleTidy}><i className={tidy===1?"tidy1":(tidy===2)?"tidy2":"tidy3"}></i></Button>
        <Search {...SearchProps}/>
        <button className="btn btn-link bookmarklet" dangerouslySetInnerHTML={{__html: bookmarklet}}></button>
        <NavbarMyhome {...NavbarMyhomeProps} />
      </div>
    </nav>
    </>
);
}

export default HeaderMyhome;