import React, {useState, useContext, useEffect} from 'react';
import api from '../../utils/api'
import ajaxLoader from '../../icons/ajax-loader.gif';
import {LoginContext} from '../../components/LoginProvider';
import BookShareEmbed from './BookShareEmbed';
import config from '../../data/config'
import {LangContext} from '../../components/LangProvider';
import SearchEmbed from './SearchEmbed'
import {
  useParams,
} from "react-router-dom";
import MyModalShare from './MyModalShare';

function Share(){
   let {userid} = useContext(LoginContext)
   const {lang} = useContext(LangContext);
   const t = lang.share;
   //** get page */
   const params = useParams()
   let {bookTitle} = params
   const pathUserid = config.shareUserid
   const path = ['share']

   const [, setShow] = useState(false)
   const [showShare, setShowShare] = useState(false)
   let [selected, setSelected] = useState(null)
   const defaultPageSearch = {type: 'pge', key: config.shareUserid+'share', title: config.shareUserid+'share', public: true, children: []}
   let [pageSearch, setPageSearch] = useState(defaultPageSearch);
   const [loading, setLoading] = useState(false);
  const [, setServerErrors] = useState(null);
  let [memoShown, setMemoShown] = useState(true);
  let [tidy, setTidy] = useState(1);
  let [afterSearch, setAfterSearch] = useState(null);
  let [searchKeyword, setSearchKeyword] = useState(null);

   const toggleClose = (node)=>() => {
      node.close = !node.close;
      setPageSearch({...pageSearch});    
    } 
   const handleShow = (node)=>()=>{
      if(!checkAuth(userid, pathUserid)){
         setShowShare(true)
         setSelected(node)
      }else{
         setShow(true)
         setSelected(node)
      }
   }
   const memoIcons = ['memoIcon0', 'memoIcon1', 'memoIcon2' ]
   const toggleMemoIcon = (node) => () => {
     node.memoI = node.memoI? (node.memoI + 1) % 3: 1
     setPageSearch({...pageSearch});  
   }
     //** auth check */
   const checkAuth = (userid, pathUserid) => {
      return userid === pathUserid
   }
   const toggleMemo = ()=>{
      memoShown = !memoShown
      setMemoShown(memoShown)
    }
    const toggleTidy = ()=>{
      tidy = tidy % 3 + 1
      setTidy(tidy)
      resetRecursiveClose(pageSearch, tidy)
    }
    
    function resetRecursiveClose(node, tidy){
      if(tidy === 1){
          node.close = false
          if(node.children && node.children.length > 0){
            for(let i = 0, n = node.children.length; i < n; i++){
              node.children[i].close = false
            }
          }
      }else if(tidy === 2){
        node.close = false;
        if(node.children && node.children.length > 0){
          for(let i = 0, n = node.children.length; i < n; i++){
            node.children[i].close = true
          }
        }
      }else if(tidy === 3){
        node.close = true;
      }
    }
    useEffect(()=>{
      const searchResultString = localStorage.getItem("searchResult")
      if(searchResultString){
        const searchResult = JSON.parse(searchResultString)
        searchKeyword = searchResult.searchKeyword 
        setSearchKeyword(searchKeyword)
        pageSearch = searchResult.pageSearch
        setPageSearch(pageSearch);
      }else{
        searchKeyword = bookTitle
        setSearchString(searchKeyword)
      }
    },[])
    const resetSetValues = () => {
      afterSearch = null
      setAfterSearch(afterSearch)
      pageSearch = defaultPageSearch
      setPageSearch(pageSearch);
      searchKeyword = null
      setSearchKeyword(searchKeyword)
    }
    const resetStorage = () => {
      localStorage.removeItem('searchResult')
    }
    const resetSearchResult = () => {
      resetSetValues()
      resetStorage()
    }
    const setSearchString = (searchKeyword) => {
      resetSetValues()
      if(!searchKeyword){
        resetStorage()
        return;
      }
      setSearchKeyword(searchKeyword)
      doSearchShare(searchKeyword)

    }//setSearchString
    const handleMoreSearch = () => {
      if(afterSearch){
        doSearchShare(searchKeyword)
      }
    }
    const doSearchShare = (searchKeyword) => {
      if(searchKeyword && afterSearch !== 'end'){
        setServerErrors(null)
        setLoading(true)
        api.searchShare(searchKeyword, afterSearch).then((response)=>{
          setServerErrors(null)
          setLoading(false)
          if(response.results?.length > 0){
            pageSearch.children = pageSearch.children.concat(response.results)
            localStorage.setItem("searchResult", JSON.stringify({searchKeyword ,  pageSearch}))
          }
          if(response.afterSearch){
            setAfterSearch(response.afterSearch[0]['@ref']['id'])
          }else{
            setAfterSearch('end')
          }
          setPageSearch({...pageSearch})
        }).catch((error)=>{
          setLoading(false)
          setServerErrors(error.errors[0])
        })
      }
    }
  const itemProps = {toggleClose, handleShow, parentPath: path, toggleMemoIcon, memoIcons}
  const SearchProps = {setSearchString, resetSearchResult, searchKeyword}
  const modalShareProps = {showShare, setShowShare, selected, userid, setServerErrors, setLoading, pathUserid, checkAuth, searchKeyword}
   return(
   <>
   {<link rel="stylesheet" type="text/css" href={"/vendor/ShareEmbed.css"} />}
   {memoShown && <link rel="stylesheet" type="text/css" href={"/vendor/memoShown.css"} />}
   {loading && <img className='ajaxLoader' src={ajaxLoader} alt='ajaxLoader'/>}
      <div className="container main">
      {pageSearch &&
        <> 
         <div className="page pageSearch">
            <i onClick={toggleMemo} className={memoShown?"memoHidden":"memoShown"}></i>
            <i onClick={toggleTidy} className={tidy===1?"tidy1":(tidy===2)?"tidy2":"tidy3"}></i>
            <div className="alert alert-dark header public">
              <SearchEmbed {...SearchProps}/>
              {pageSearch.memo && <i className={memoIcons[pageSearch.memoI || 0]} onClick={toggleMemoIcon(pageSearch)}></i>}
              <i className="settings" onClick={handleShow(pageSearch)}></i>
            </div>
            <div className={pageSearch.memoI?"memoI": "memo"}>{pageSearch.memo}</div>
            <div className="content">
            {tidy !== 3 && pageSearch.children && pageSearch.children.length > 0 && pageSearch.children.map(book => <BookShareEmbed key={book.key} book={book} {...itemProps}/>)}  
            </div>
            <div className={"searchShareMore"} >
              <div className={pageSearch.public?"headerTitle public":"headerTitle private"}><span onClick={handleMoreSearch} className="shareMore" >{(!afterSearch || afterSearch==='end')?t.end:t.moreSearchResult}</span></div>
            </div>
         </div>
        </>
      }
      </div>
      {showShare && <MyModalShare {...modalShareProps}/>}
   </>
   ) 
}

export default Share