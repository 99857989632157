import React, {useRef} from 'react';
import Link from '../Link'
import {useDrag, useDrop } from 'react-dnd';
import config from '../../../data/config';

function Book(props){
    const {book, toggleClose, handleShow, parentPath, moveItemChild, moveItem, toggleMemoIcon, memoIcons} = props
    const linkDepth = -1
    //** drag and drop */
    const path = [...parentPath, book.key]
    book.path = path
    const bookDelegate = {type: book.type, key: book.key, path: path}
    const [, drag, previewRef] = useDrag({
      item: bookDelegate,
      collect: (monitor)=>({
          opacity: monitor.isDragging()? 0.5: 1
      })
    })
    const [{isSiblingOver, canDrop}, drop] = useDrop({
      accept: ['lnk'],
      drop(item){
          if(item.key !== book.key){
              moveItem({targetDelegate: bookDelegate, sourceDelegate: item});
          }
      },
      collect: (monitor) => ({
        isSiblingOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      canDrop(item, monitor){
        // parent item cannot move under child book
        return (item.key !== book.key) && !book.path.includes(item.key)
      }
    })
    const [{isChildOver, canChildDrop}, dropChild] = useDrop({
        accept: ['fld', 'bok', 'lnk'],
        drop(item){
            if(item.key !== book.key){
              if(item.type === 'lnk'){ // link
                if(book.share){
                  alert('You cannot add a link to a Shared Book. Unshare this before editting.')
                  return
                }
                moveItemChild({targetDelegate: bookDelegate, sourceDelegate: item});
              }else{ // not link
                moveItem({targetDelegate: bookDelegate, sourceDelegate: item});
              }
            }
        },
        collect: (monitor) => ({
          isChildOver: monitor.isOver(),
          canChildDrop: monitor.canDrop(),
      }),
      canDrop(item, monitor){
        // parent item cannot move to child item && book is not shared
        return ((item.key !== book.key) && !book.path.includes(item.key)) && !(book.share && item.type === 'lnk')
      }
    })
    const ref = useRef(null)
    const ref2 = useRef(null)
    drop(ref)
    previewRef(drag(dropChild(ref2)))

    let siblingStyle
    if(isSiblingOver){
      siblingStyle = canDrop? config.canDropStyle: config.cannotDropStyle
    }
    let childStyle
    if(isChildOver){
      childStyle = canChildDrop? config.canDropStyle: config.cannotDropStyle
    }
    //** style */
    let style;
    if(book.bgurl){
      style = {background: `white url(${book.bgurl}) no-repeat`, backgroundPosition:'center right', height: '370px'}
    }
      //** props */
    const itemProps = {toggleClose, handleShow, parentPath: path, moveItemChild, moveItem, toggleMemoIcon, memoIcons, share: book.share}
    return(
      <>
      {book && 
        <div className={book.share?"book share": "book"}>
          <i style={siblingStyle} className={book.close ? (book.children?.length?"closeIcon": "nochildIcon"):"titleIcon"} ref={ref} onClick={toggleClose(book)}></i>
            {book.share && <h6><span className="badge badge-success share">share</span></h6>}
          <div className="alert header" ref={ref2} style={childStyle}>
            <a href={book.href} target="_blank" rel="noopener noreferrer">
              <span className={book.match ? "title match" : 'title'}>{book.title}</span>
            </a>
            {book.memo && <i className={memoIcons[book.memoI || 0]} onClick={toggleMemoIcon(book)}></i>}
            <i className="settings" onClick={handleShow(book)}></i>
          </div>
          <div className={book.memoI?"memoI": "memo"}>{book.memo}</div>
          {!book.close && 
          <div className="content"  style={style}>
            {book.children && book.children.map((item)=>{
                return <Link key={item.key} link={item} linkDepth={linkDepth} {...itemProps}/>
            })}
          </div>
          }
        </div>
      }
      </>
    );
}

export default Book;