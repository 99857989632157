import React, {useContext} from 'react';
import {LangContext} from '../../components/LangProvider';


function Footer(props){
  const {lang} = useContext(LangContext);
  const t = lang.Footer
    return(
        <>
        {/* <!-- Footer --> */}
        <footer className="py-5 bg-dark">
          <div className="container">
            <p className="m-0 text-center text-white">{t.copyright} &copy; {t.company}</p>
          </div>
          {/* <!-- /.container --> */}
        </footer>
        </>
    );
}

export default Footer;