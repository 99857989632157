import config from '../../data/config';

const ko = {
    Home:{
        title: '세상을 향한 개인 포탈 사이트',
        subtitle: '자신의 북마크를 모아 만드는, 세상을 향한 개인 포탈 사이트',
        purpose: '이 사이트는 뭔가?',
        purpose1: '북마클릿을 이용해서 자신의 북마크를 수집하여, 세상을 향한, 자신만의 포탈 사이트를 만듭니다.',
        purpose2: '북마크를 생성, 편집, 삭제합니다. 멋진 배경도 설정할 수 있습니다.',
        purpose3: '드래그-드롭으로 북마크를 재배치할 수있습니다.',
        purpose4: '여러 페이지를 생성하고 페이지별로 공개, 비공개 ( 기본 )를 설정할 수있습니다',
        purpose5: '좋은 북마크를 다른이들과 공유할 수있습니다',
        purpose6: '등록하지않으면 \'anonym\' 사용자 아이디를 사용할수있습니다. 등록하면(무료) 자신의 사용자 아이디를 사용할 수있습니다',
        purpose7: '모든 서비스는 무료입니다.',
        contact: '연락처',
        signup: '무료 등록',
        contactName: '네오홈피',
        contactEmail: 'neohompy@gmail.com',
        card1:{
            image: '/img/1-300x200.jpg',
            title: '사용자 아이디 \'anonym\'',
            descr1: '등록하지않으면 위 아이디를 사용할 수있습니다',
            descr2: '이 경우, 모든 북마크는 자신의 컴퓨터에 저장됩니다.',
            descr3: '다른 기기에서, 자신의 북마크를 공유하고싶으면 등록하면 됩니다. 무료입니다',
            more: '더 알아보기!',
            moreUrl: '',
        },
        card2:{
            image: '/img/2-300x200.jpg',
            title: '자신의 사용자 아이디',
            descr1: '등록하면 자신의 사용자 아이디를 가질 수있습니다',
            descr2: '이 경우, 자신의 북마크는 서버에 저장됩니다. 공개, 비공개(기본)를 설정할 수있습니다.',
            descr3: '자신의 다른 기기에서 북마크를 공유할 수있습니다. 모두 무료입니다.',
            more: '더 알아보기!',
            moreUrl: '',
        },
        card3:{
            image: '/img/3-300x200.jpg',
            title: '북마클릿',
            descr1: '북마클릿을 사용하여 북마크를 수집합니다.',
            descr2: '북마클릿은 홈페이지 헤더에 있습니다.',
            descr3: '북마클릿을 드래그드롭하여 자신의 웹브라우저의 북마크바에 옮겨놓으세요.',
            more: '더 알아보기!',
            moreUrl: '',
        },
        card4:{
            image: '/img/4-300x200.jpg',
            title: '기존 북마크 가져오기',
            descr1: '자신의 웹브라우저에서 사용하던 북마크를 가져올 수있습니다.',
            descr2: '먼저, 자신의 웹브라우저에 있는 북마크를 HTML 파일 형식으로 내보냅니다.',
            descr3: '다음, 그 북마크 파일을 새 페이지로 불러들입니다.',
            more: '더 알아보기!',
            moreUrl: '',
        },
        card5:{
            image: '/img/5-300x200.jpg',
            title: '드래그 앤 드롭',
            descr1: '페이지에 있는 카테고리, 북, 북마크(링크)의 제목을 드래그 앤 드롭 할수있습니다.',
            descr2: '아이템의 제목을 드래그하여 타겟의 제목에 드롭하면, 그 타겟의 자식 (또는 형제)이 됩니다.',
            descr3: '아이템의 제목을 드래그하여 타겟의 아이콘에 드롭하면, 그 타겟의 형제 (또는 자식)가 됩니다.',
            more: '더 알아보기!',
            moreUrl: '',
        },
        card6:{
            image: '/img/6-300x200.jpg',
            title: '공개, 비공개 페이지',
            descr1: '페이지의 공개 , 비공개 (기본) 여부를 설정할 수있습니다.',
            descr2: '비공개 설정시, 다른이들은 그 페이지에 접근할 수없습니다.',
            descr3: '북 별로 공개여부를 설정하여 다른이와 공유할 수있습니다.',
            more: '더 알아보기!',
            moreUrl: '',
        },
    },
    share:{
        mainTitle: '좋은 북마크 책들을 공유하세요.',
        humanSelected: '이들 북마크 책들은, 사람들이 선택하고 관리하는 것들입니다.',
        embedInto: '각 북마크 책들을, 당신의 페이지로 복사하거나 당신의 웹사이트에 삽입할 수있습니다.',
        url1: '책 주소는 https://neohompy.com/share/BookTitle',
        example1: '예: ',
        exampleUrl1: 'https://neohompy.com/share/izone',
        url2: '또는 책 주소는 https://neohompy.com/share/SearchWord',
        example2: '예: ',
        exampleUrl2: 'https://neohompy.com/share/k-pop',
        embedCode: '임베드 코드는 아래와 같습니다 :',
        recentBooks: '최신 책들',
        moreRecentBooks: '더보기',
        end: '끝',
        searchResult: '검색 결과',
        moreSearchResult: '더보기',
    },
    Privacy:{
      title: '개인정보',
      policy:`<h1>개인 정보 정책</h1>
      <p>마지막 고침: 2021 년 1 월 8 일</p>
      <p>이 개인 정보 보호 정책은 귀하가 서비스를 사용할 때 귀하의 정보를 수집, 사용 및 공개하는 것에 대한 당사의 정책 및 절차를 설명하고 귀하의 개인 정보 권리와 법률이 귀하를 보호하는 방법을 알려줍니다.</p>
      <p>당사는 서비스를 제공하고 개선하기 위해 귀하의 개인 데이터를 사용합니다. 서비스를 이용함으로써 귀하는 본 개인 정보 보호 정책에 따라 정보의 수집 및 사용에 동의하게됩니다. 이 개인 정보 보호 정책은 <a href="https://www.privacypolicies.com/privacy-policy-generator/" target="_blank"> 개인 정보 보호 정책 생성기</a>의 도움으로 작성되었습니다.</p>
      <h1>해석 및 정의</h1>
      <h2>해석</h2>
      <p>첫 글자가 대문자 인 단어는 다음 조건에서 정의 된 의미를 갖습니다. 다음 정의는 단수 또는 복수로 표시되는지 여부에 관계없이 동일한 의미를 갖습니다.</p>
      <h2>정의</h2>
      <p>이 개인 정보 보호 정책의 목적을 위해 :</p>
      <ul>
      <li>
      <p><strong>계정</strong> 귀하가 당사 서비스 또는 당사 서비스의 일부에 액세스하기 위해 생성 된 고유 계정을 의미합니다.</p>
      </li>
      <li>
      <p><strong>제휴</strong> 통제, 통제 또는 당사자와 공동 통제하에있는 엔티티를 의미합니다. 이사 또는 기타 관리 권한에 투표 할 수있는 주식, 지분 또는 기타 증권의 50 % 이상의 소유권을 의미합니다.</p>
      </li>
      <li>
      <p><strong>응용프로그램</strong> 귀하가 귀하가 neohompy라는 이름의 전자 장치에 다운로드하여 회사가 제공 한 소프트웨어 프로그램을 의미합니다.</p>
      </li>
      <li>
      <p><strong>회사</strong> (이 계약에서 "회사", "우리", "우리를" 또는 "우리의" 라고도합니다.) 네오홈피, neohompy@gmail.com을 의미합니다.</p>
      </li>
      <li>
      <p><strong>국가</strong>호주 (뉴 사우스 웨일즈)를 말합니다.</p>
      </li>
      <li>
      <p><strong>장치</strong> 컴퓨터, 휴대폰 또는 디지털 태블릿과 같이 서비스에 액세스 할 수있는 모든 장치를 의미합니다.</p>
      </li>
      <li>
      <p><strong>개인 정보</strong> 식별되거나 식별 가능한 개인과 관련된 모든 정보입니다.</p>
      </li>
      <li>
      <p><strong>서비스</strong> 응용 프로그램을 나타냅니다.</p>
      </li>
      <li>
      <p><strong>서비스 제공자</strong> 회사를 대신하여 데이터를 처리하는 자연인 또는 법인을 의미합니다. 서비스를 용이하게하거나, 회사를 대신하여 서비스를 제공하거나, 서비스와 관련된 서비스를 수행하거나, 서비스가 어떻게 사용되는지 분석하는 데 도움을주기 위해 회사가 고용 한 제 3 자 회사 또는 개인을 말합니다.</p>
      </li>
      <li>
      <p><strong>제 3 자 소셜 미디어 서비스</strong> 사용자가 서비스를 사용하기 위해 로그인하거나 계정을 생성 할 수있는 모든 웹 사이트 또는 소셜 네트워크 웹 사이트를 의미합니다.</p>
      </li>
      <li>
      <p><strong>사용 데이터</strong> 서비스 사용 또는 서비스 인프라 자체에서 생성 된 자동 수집 데이터 (예 : 페이지 방문 기간)를 의미합니다.</p>
      </li>
      <li>
      <p><strong>당신</strong> 서비스에 액세스하거나 사용하는 개인, 해당하는 경우 해당 개인이 서비스에 액세스하거나 사용하는 회사 또는 기타 법인을 의미합니다.</p>
      </li>
      </ul>
      <h1>개인 데이터 수집 및 사용</h1>
      <h2>수집되는 데이터 유형</h2>
      <h3>개인 정보</h3>
      <p>당사 서비스를 사용하는 동안 당사는 귀하에게 연락하거나 식별하는 데 사용할 수있는 특정 개인 식별 정보를 당사에 제공하도록 요청할 수 있습니다. 개인 식별 정보에는 다음이 포함될 수 있지만 이에 국한되지는 않습니다.</p>
      <ul>
      <li>
      <p>이메일 주소</p>
      </li>
      <li>
      <p>사용 데이터</p>
      </li>
      </ul>
      <h3>사용 데이터</h3>
      <p>이용 데이터는 서비스 이용시 자동으로 수집됩니다.</p>
      <p>사용 데이터에는 귀하의 장치의 인터넷 프로토콜 주소 (예 : IP 주소), 브라우저 유형, 브라우저 버전, 귀하가 방문한 당사 서비스 페이지, 귀하의 방문 시간 및 날짜, 해당 페이지에서 보낸 시간, 고유 장치와 같은 정보가 포함될 수 있습니다. 식별자 및 기타 진단 데이터.</p>
      <p>귀하가 모바일 장치를 통해 또는 모바일 장치를 통해 서비스에 액세스 할 때 당사는 귀하가 사용하는 모바일 장치 유형, 귀하의 모바일 장치 고유 ID, 귀하의 모바일 장치의 IP 주소, 귀하의 모바일을 포함하되 이에 국한되지 않는 특정 정보를 자동으로 수집 할 수 있습니다. 운영 체제, 사용하는 모바일 인터넷 브라우저의 유형, 고유 한 장치 식별자 및 기타 진단 데이터.</p>
      <p>또한 귀하가 당사 서비스를 방문 할 때마다 또는 모바일 장치를 통해 또는 모바일 장치를 통해 서비스에 액세스 할 때 귀하의 브라우저가 보내는 정보를 수집 할 수 있습니다.</p>
      <h2>귀하의 개인 데이터 사용</h2>
      <p>회사는 다음과 같은 목적으로 개인 정보를 사용할 수 있습니다.</p>
      <ul>
      <li>
      <p><strong>서비스 제공 및 유지</strong>, 서비스 사용 모니터링 포함.</p>
      </li>
      <li>
      <p><strong>귀하의 계정을 관리:</strong> 서비스 사용자로서 귀하의 등록을 관리하기 위해. 귀하가 제공하는 개인 데이터는 등록 된 사용자로서 귀하가 사용할 수있는 서비스의 다양한 기능에 대한 액세스 권한을 귀하에게 부여 할 수 있습니다.</p>
      </li>
      <li>
      <p><strong>계약 이행:</strong> 귀하가 구매 한 제품, 항목 또는 서비스 또는 서비스를 통해 당사와의 기타 계약에 대한 구매 계약의 개발, 준수 및 착수.</p>
      </li>
      <li>
      <p><strong>귀하에게 연락</strong> 이메일, 전화 통화, SMS 또는 필요하거나 합당한 경우 보안 업데이트를 포함하여 기능, 제품 또는 계약 서비스와 관련된 업데이트 또는 정보 통신에 관한 모바일 애플리케이션의 푸시 알림과 같은 기타 동등한 형태의 전자 통신을 통해 귀하에게 연락하기 위해 구현을 위해.</p>
      </li>
      <li>
      <p><strong>뉴스를 제공하기 위해:</strong>, 귀하가 그러한 정보를 수신하지 않기로 선택하지 않는 한 귀하가 이미 구매했거나 문의 한 것과 유사한 기타 상품, 서비스 및 이벤트에 대한 특별 제안 및 일반 정보.</p>
      </li>
      <li>
      <p><strong>귀하의 요청을 관리:</strong>당사에 대한 귀하의 요청에 참석하고 관리하기 위해.</p>
      </li>
      <li>
      <p><strong>비즈니스 양도의 경우 :</strong>당사는 귀하의 정보를 사용하여 합병, 매각, 구조 조정, 구조 조정, 해산 또는 기타 당사 자산의 일부 또는 전체를 매각 또는 이전하는 것을 평가하거나 수행 할 수 있습니다. 당사 서비스 사용자에 대해 당사가 보유한 개인 데이터는 이전 된 자산 중 하나입니다.</p>
      </li>
      <li>
      <p><strong>다른 목적으로</strong>: 당사는 귀하의 정보를 데이터 분석, 사용 경향 식별, 당사의 홍보 캠페인의 효과 결정 및 당사의 서비스, 제품, 서비스, 마케팅 및 귀하의 경험을 평가하고 개선하는 등의 다른 목적으로 사용할 수 있습니다.</p>
      </li>
      </ul>
      <p>당사는 다음 상황에서 귀하의 개인 정보를 공유 할 수 있습니다.</p>
      <ul>
      <li><strong>서비스 제공 업체와 : </strong> 당사는 귀하에게 연락하기 위해 서비스 사용을 모니터링 및 분석하기 위해 귀하의 개인 정보를 서비스 제공 업체와 공유 할 수 있습니다.</li>
      <li><strong> 비즈니스 양도의 경우 : </strong> 당사는 합병, 회사 자산의 매각, 자금 조달 또는 당사 비즈니스의 전부 또는 일부 인수와 관련하여 또는 협상 중에 귀하의 개인 정보를 다른 사람과 공유하거나 전송할 수 있습니다. 회사.</li>
      <li><strong>계열사와 : </strong> 당사는 귀하의 정보를 당사 계열사와 공유 할 수 있으며,이 경우 해당 계열사가이 개인 정보 보호 정책을 준수하도록 요구합니다. 계열사에는 당사의 모회사 및 기타 자회사, 합작 투자 파트너 또는 당사가 통제하거나 당사와 공동으로 통제하는 기타 회사가 포함됩니다.</li>
      <li><strong>비즈니스 파트너와 : </strong> 당사는 특정 제품, 서비스 또는 프로모션을 제공하기 위해 귀하의 정보를 비즈니스 파트너와 공유 할 수 있습니다.</li>
      <li><strong>다른 사용자와 : </strong> 귀하가 개인 정보를 공유하거나 다른 사용자와 공용 공간에서 상호 작용할 때 이러한 정보는 모든 사용자가 볼 수 있으며 외부에 공개적으로 배포 될 수 있습니다. 귀하가 다른 사용자와 상호 작용하거나 제 3 자 소셜 미디어 서비스를 통해 등록하는 경우 제 3 자 소셜 미디어 서비스의 연락처는 귀하의 이름, 프로필, 사진 및 귀하의 활동에 대한 설명을 볼 수 있습니다. 마찬가지로 다른 사용자는 귀하의 활동에 대한 설명을보고 귀하와 소통하며 귀하의 프로필을 볼 수 있습니다.</li>
      <li><strong>귀하의 동의하에 </strong> : 당사는 귀하의 동의하에 다른 목적으로 귀하의 개인 정보를 공개 할 수 있습니다.</li>
      </ul>
      <h2>귀하의 개인 데이터 보유</h2>
      <p>회사는 본 개인 정보 보호 정책에 명시된 목적에 필요한 기간 동안 만 귀하의 개인 정보를 보유합니다. 당사는 법적 의무를 준수하고 (예 : 관련 법률을 준수하기 위해 귀하의 데이터를 보유해야하는 경우) 분쟁을 해결하고 당사의 법적 계약 및 정책을 시행하는 데 필요한 범위까지 귀하의 개인 데이터를 보유 및 사용할 것입니다.</p>
      <p>회사는 또한 내부 분석 목적으로 사용 데이터를 보유합니다. 사용 데이터는 일반적으로이 데이터가 보안을 강화하거나 당사 서비스의 기능을 개선하는 데 사용되는 경우를 제외하고는 더 짧은 기간 동안 유지됩니다.</p>
      <h2>귀하의 개인 데이터 전송</h2>
      <p>개인 데이터를 포함한 귀하의 정보는 회사의 운영 사무소 및 처리와 관련된 당사자가 위치한 기타 장소에서 처리됩니다. 이는 데이터 보호법이 귀하의 관할권과 다를 수있는 귀하의 주, 지방, 국가 또는 기타 정부 관할권 밖에있는 컴퓨터로이 정보가 전송되고 유지 될 수 있음을 의미합니다.</p>
      <p>이 개인 정보 보호 정책에 대한 귀하의 동의와 귀하의 그러한 정보 제출은 해당 이전에 대한 귀하의 동의를 나타냅니다.</p>
      <p>회사는 귀하의 데이터가 본 개인 정보 보호 정책에 따라 안전하게 취급되도록 합리적으로 필요한 모든 조치를 취할 것이며, 귀하의 데이터 및 기타 개인 정보의 보안을 포함하여 적절한 통제가 없는 한 귀하의 개인 데이터는 조직이나 국가로 전송되지 않습니다.</p>
      <h2>귀하의 개인 데이터 공개</h2>
      <h3>비즈니스 거래</h3>
      <p>회사가 합병, 인수 또는 자산 매각에 관여하는 경우 귀하의 개인 데이터가 전송 될 수 있습니다. 당사는 귀하의 개인 데이터가 전송되기 전에 통지를 제공하고 다른 개인 정보 보호 정책의 적용을받습니다.</p>
      <h3>법 집행</h3>
      <p>특정 상황에서 회사는 법률에 의해 요구되거나 공공 기관 (예 : 법원 또는 정부 기관)의 유효한 요청에 응답하여 귀하의 개인 데이터를 공개해야 할 수 있습니다.</p>
      <h3>기타 법적 요건</h3>
      <p>회사는 다음과 같은 조치가 필요하다는 신념으로 귀하의 개인 정보를 공개 할 수 있습니다.</p>
      <ul>
      <li>법적 의무 준수</li>
      <li>회사의 권리 또는 재산을 보호하고 방어합니다.</li>
      <li>서비스와 관련하여 가능한 잘못된 행위를 방지하거나 조사합니다.</li>
      <li>서비스 사용자 또는 대중의 개인 안전을 보호하십시오.</li>
      <li>법적 책임으로부터 보호</li>
      </ul>
      <h2>개인 데이터의 보안</h2>
      <p>귀하의 개인 데이터 보안은 저희에게 중요하지만 인터넷을 통한 전송 방법이나 전자 저장 방법은 100 % 안전하지 않습니다. 당사는 귀하의 개인 데이터를 보호하기 위해 상업적으로 허용되는 수단을 사용하기 위해 노력하지만 절대적인 보안을 보장 할 수는 없습니다.</p>
      <h1>다른 웹 사이트로의 링크</h1>
      <p>당사 서비스에는 당사가 운영하지 않는 다른 웹 사이트에 대한 링크가 포함될 수 있습니다. 제 3 자 링크를 클릭하면 해당 제 3 자 사이트로 이동합니다. 방문하는 모든 사이트의 개인 정보 보호 정책을 검토 할 것을 강력히 권장합니다.</p>
      <p>당사는 제 3 자 사이트 또는 서비스의 콘텐츠, 개인 정보 보호 정책 또는 관행을 통제하지 않으며 책임을지지 않습니다.</p>
      <h1>이 개인 정보 보호 정책의 변경</h1>
      <p>당사는 수시로 개인 정보 보호 정책을 업데이트 할 수 있습니다. 이 페이지에 새로운 개인 정보 보호 정책을 게시하여 변경 사항을 알려드립니다.</p>
      <p>변경 사항이 적용되기 전에 이메일 및 / 또는 서비스에 대한 눈에 잘 띄는 공지를 통해 귀하에게 알리고, 이 개인 정보 보호 정책의 상단의 날짜 "마지막 고침"을 고칠 것입니다. </p>
      <p>변경 사항이 있는지 정기적으로이 개인 정보 보호 정책을 검토하는 것이 좋습니다. 이 개인 정보 보호 정책의 변경 사항은이 페이지에 게시될 때 유효합니다.</p>
      <h1>문의하기</h1>
      <p>이 개인 정보 보호 정책에 대해 질문이 있는 경우 다음 연락처로 문의할 수 있습니다.</p>
      <ul>
      <li>이메일: neohompy@gmail.com</li>
      </ul>`,
    },
    Footer:{
        copyright: '저작권',
        company: '네오홈피 2020',
    },
    header:{
        name: '한국어',
        company: '네오홈피',
        home: '홈',
        share: '공유',
        privacy: '개인정보',
        myhome: '마이홈',
        signup: '등록',
        signout: '탈퇴',
        login: '로그인',
        logout: '로그아웃',
        account: '계정',
        bookmarklet: '북마클릿',
    },
    PageList:{
        newpage: '+ 새 페이지',
       public: '공개',
    },
    page:{
        public: '공개',
        private: '미공개',
        pge: '페이지',
        auth_error: '편집권한이 없습니다.',
        moveItemError: '부모 아이템은 자식 아이템으로 이동못합니다.',
        moveItemChildError: '부모 아이템은 자식아이템 아래로 이동못합니다.',
    },
    HeaderMyhome:{
        memoShown: '보임',
        memoHidden: '숨김',
        tidy1: '원래',
        tidy2: '정렬',
        tidy3: '배경',
        bookmarklet: '북마클릿',
        login: '로그인',
        logout: '로그아웃'
    },
    Search: {
        searchHolder: '검색',
        search: '검색',
    },
    SearchResult: {
         SearchResults: '검색 결과',
        NoSearchResults: '검색 없음',
    },
    loginmodal:{
        login: '로그인',
        signup: '등록',
        email: '이메일',
        emailholder: '이메일 입력',
        emailguide: 'example@example.com',
        requestcode_label: '이메일 확인',
        requestcode: '확인코드 요청',
        requestcode_guide: '',
        verifycode_holder: '확인코드 입력',
        verifycode: '코드 전송',
        notverified_error: '이메일이 확인되지 않았습니다',
        userid: '아이디',
        useridholder: '아이디 입력',
        useridguide: config.userid_min + '-' + config.userid_max + ' 길이의 문자와 숫자.',
        userid_lower_error: '모두 소문자이어야합니다.',
        userid_min_error: config.userid_min + ' 보다 길어야합니다.',
        userid_max_error: config.userid_max + ' 보다 짧아야합니다.',
        userid_required_error: '아이디가 필요합니다',
        pass: '암호',
        passholder: '암호 입력',
        passguide: config.pass_min + '-' + config.pass_max + ' 길이의 문자와 숫자.',
        pass_min_error: config.pass_min + ' 보다 길어야합니다.',
        pass_max_error: config.pass_max + ' 보다 짧아야합니다.',
        pass2: '암호2',
        passholder2: '같은 암호 입력',
        passguide2: '같은 암호를 입력해주세요.',
        pass_match_error: '두 암호가 같지않습니다.',
        close: '닫기',
        submit: '보내기',
        title_min_error:'제목이 짧습니다.',
        title_max_error:'제목이 깁니다.',
        title_required_error: '제목이 필요합니다.',
        url_error:'Url 형식이 틀립니다.',
        url_max_error:'Url 이 깁니다.',
        bgurl_max_error:'Url 이 짧습니다.',
        email_error:'이메일 형식이 틀립니다.',
        email_max_error:'이메일이 깁니다.',
        email_required_error:'이메일이 필요합니다',
        pass_mismatch_error:'확인암호가 같지않습니다.',
        pass_required_error:'암호가 필요합니다.',
        token_error: '토큰이 틀립니다.',
        token_min_error:'토큰이 짧습니다.',
        token_max_error:'토큰이 깁니다.',
        pass_change_msg: '암호가 바뀌었습니다.',
        pass_change_error: '암호바꾸기에 문제가 있습니다.',
        pass_wrong_error: '아이디 또는 암호가 틀립니다.',
        server_error: '서버에 문제가 있습니다.',
        unique_error: '아이디가 존재합니다.',

    },
    logoutmodal:{
        logout: '로그아웃',
        logoutConfirm: '로그아웃하시겠습니까? 언제든 다시 오세요.',
        logoutBtn: '확인',
        closeBtn: '취소',
        signout: '탈퇴',
        signoutConfirm: '탈퇴하시겠습니까? 모든 데이타가 사라집니다.',
        signoutBtn: '확인',
        signoutTab: '탈퇴',
        newpassTab: '암호바꾸기',
        newpassTitle: '암호바꾸기',
        userid: '아이디',
        useridguide: '현재 아이디',
        pass: '암호',
        passguide: '현재 암호',
        newpass: '새 암호',
        newpassholder: '새 암호를 입력하세요',
        newpassConfirm: '암호를 바꾸기위해, 현재 암호와 새 암호를 입력하세요',
        newpassguide: config.pass_min + '-' + config.pass_max + ' 길이의 문자와 숫자.',
        newpassBtn: '확인',
    },
    mymodal:{
        type:{
            pge: '페이지',
            fld: '분류',
            bok: '책',
            lnk: '링크'
        },
        edt:{
            title: '편집',
            tab: '편집'
        },
        fld:{
            title: '새 분류',
            tab: '분류'
        },
        bok:{
            title: '새 책',
            tab: '책'
        },
        lnk:{
            title: '새 링크',
            tab: '링크'
        },
        pge: {
            title: '새 페이지',
            tab: '페이지'
        },
        form:{
            kind: '종류',
            child: '자식',
            sibling: '형제',
            sharing: '공유여부',
            share: '공유',
            notshare: '비공유',
            privacy: '공개여부',
            public: '공개',
            private: '비공개',
            defaulting: '기본여부',
            default: '기본',
            notdefault: '기본 아님',
            title: '제목',
            titleHolder: '제목 입력',
            href: 'URL',
            hrefHolder: 'URL 입력 (선택)',
            bgurl: '배경 URL',
            bgurlHolder: '배경 URL 입력 (선택)',
            memo: '메모',
            memoHolder: '메모 입력 (선택)',
            keywords: '키워드',
            keywordsHolder: '키워드 입력 (선택)',
            smallBg: '작은 그림',
            smallBgHolder: '작은 그림 URL 입력',
            largeBg: '큰 그림',
            largeBgHolder: '큰 그림 URL 입력',
            bgColor: '배경색',
            bgColorHolder: '배경색 입력',
            color: '글자색',
            colorHolder: '글자색 입력',
            icon: '아이콘 URL',
            iconHolder: '아이콘 URL 입력',
            title_min_error: '제목이 너무 짧음',
            title_max_error: '제목이 너무 긺',
            unique_error: '제목이 중복됨',
            url_error: 'url 틀림',
            url_max_error: 'url 너무 긺',
            memo_min_error: '메모가 너무 짧음',
            memo_max_error: '메모가 너무 긺',
            bgurl_max_error: 'bgurl 너무 긺',
        },
        share: '공유',
        unshare: '비공유',
        shared: '공유 아이템',
        unshared: '비공유 아이템',
        confirmshare: '이 아이템을 공유하시겠습니까?',
        confirmunshare: '이 아이템을 비공유하시겠습니까?',
        saveCloseState: '상태 보관',
        copy: '베낌',
        cut: '자름',
        paste: '붙임',
        close: '닫음',
        submit: '저장',
        delete: '삭제',
        quickdelete: '삭제',
        create_error: '링크에 카테고리나 북을 생성할 수없습니다',
        paste_error: '링크에 카테고리나 북을 붙일 수없습니다.',
        auth_error: '편집권한이 없습니다.',
        pageCopy_error: '페이지는 복사할 수없습니다',
        pageCut_error: '페이지는 자를 수없습니다',
        clearpaste: '붙이기 취소',
        clearnewlink: '링크 취소',
    },
    mymodalshare:{
        copyquestion:'복사하시겠습니까?',
        type:{
         pge: '페이지',
         fld:'분류',
         bok: '책',
         lnk: '링크'
        },
        copy: '복사',
        copyguide: '복사한 아이템을 붙이거나 취소하세요',
        cancelcopy: '복사 취소',
        close: '닫기',
        cannotcopy: '페이지는 복사할 수없습니다.',
        copywidget: '이 코드를 복사한 후, 당신의 HTML 페이지에 붙여넣으세요.',
       copyurl: '다른 검색어를 원한다면, "YourWord"를 당신의 검색어로 치환하거나, 새로운 검색을 하세요.',
       companyurl: 'https://neohompy.com/share',
       companyname: '네오홈피',

    },
    expo:{
        tabImport: '가져오기',
        tabExport: '내보내기',
        tabRecover: '복구',
        tabClear: '삭제',
        tabTheme: '테마',
        tabBgimg: '배경',
        tabTools: '도구',
        title:{
           tabImport: '북마크 가져오기 (HTML 또는 JSON)',
           tabExport: '북마크 내보내기',
           tabRecover: '기본 북마크 복구',
           tabClear: '로컬 저장 삭제',
           tabTheme: '테마',
           tabBgimg: '배경 그림',
           tabTools: '도구들',
        },
        bodyImport: {
            html: '가져올 북마크 html 파일 선택',
            json: '가져올 북마크 JSON 파일 선택 (추천)',
            default: '기본 페이지 가져오기',
            defaultBtn: '기본 페이지',
        },
        bodyExport: {
            body1: '북마크 파일을 html 이나 json 파일로 내려받기.',
            body2: '다른 웹브라우저로 가져가려면 HTML 파일을 선택하세요.',
            btn1: 'HTML 파일로 내려받기',
            btn2: 'JSON 파일로 내려받기 (추천)',
        },
        bodyRecover: {
            body1: '북마크를 서버에 저장하기 위해, 임시 아이디와 암호를 사용합니다.',
            userid: '아이디',
            useridHolder: '아이디를 입력...',
            password: '암호',
            passwordHolder: '암호를 입력...',
            btn1: '서버로 내보내기',
            btn2: '서버로부터 가져오기',
            foot1: '현재의 북마크는 서버의 북마크나 기본 북마크로 대체됩니다.',
        },
        bodyClear: {
            body1:'로컬 저장소를 지우기.',
            body2:' 모든 북마크가 로컬 저장소에서 지워집니다.',
            body3:'따라서 다음에 주의하세요, ',
            body4:' 현재의 북마크를 html 파일로 저장해두세요, ',
            body5:' 저장소를 지우기 전에.',
            body6:'저장소가 지워지면 기본 북마크로 돌아갑니다.',
            btn1:'로컬 저장소 지우기',
        },
        bodyTheme: {
            body1: '테마. 기본 테마는 \'cosmo\' 입니다.',
            dropdown1: '테마 선택',
        },
        bodyBgImage: {
            body1: '배경 그림.',
            dropdown1: '배경 그림 선택',
        },
        bodyTools: {
            body1: '북마크에 다음 처리가 실행됩니다.',
            body2: '하고싶은 처리를 선택하세요.',
            body3: '처리 결과는 ',
            body4: '저장 단추를 누르기 전까지',
            body5: ' 저장되지않습니다.',
            body6: '최초 갯수: ',
            body7: ' 최종 갯수: ',
            body8: ' 처리 갯수: ',
            btn1: '중복 북마크 없애기',
            btn2: '빈 폴더 없애기',
            btn3: '폴더 정렬',
            btn4: '폴더 정렬 (역순)',
            btn5: '정렬 복구',
            btn6: '카테고리 단순화',
        },
        footBtn1: '기본 북마크 가져오기',
        footBtn2: '닫기',
        footBtn3: '테마 저장',
        footBtn4: '배경 그림 저장',
        footBtn5: '처리 결과 저장',
        mockPageData: {
            type: "pge",
            key: "홈",
            title: "홈",
            close: false,
            default: false,
            children: [
              {
                children: [
                  {
                    type: "bok",
                    key: "5z7F5TIaH2",
                    title: "임시",
                  },
                ],
                type: "fld",
                key: "5z7F5TIaH3",
                title: "임시",
              },
              {
                children: [
                  {
                    type: "bok",
                    key: "d9RyT4vpy4",
                    title: "일과",
                  },
                  {
                    type: "bok",
                    key: "d9RyT4vpy1",
                    title: "이메일",
                  },
                  {
                    type: "bok",
                    key: "d9RyT4vpy3",
                    title: "검색",
                  },
                ],
                type: "fld",
                key: "d9RyT4vpy0",
                title: "일과",
              },
              {
                children: [
                  {
                    type: "bok",
                    key: "dyGdiLYg32",
                    title: "뉴스",
                  },
                  {
                    type: "bok",
                    key: "kgae9aqCc0",
                    title: "과학",
                  },
                ],
                type: "fld",
                key: "d9RyT4vpy6",
                title: "뉴스",
              },
              {
                children: [
                  {
                    type: "bok",
                    key: "Xju6PbN7o1",
                    title: "사회",
                  },
                ],
                type: "fld",
                key: "d9RyT4vpy8",
                title: "사회",
              },
              {
                children: [
                  {
                    type: "bok",
                    key: "pU12o0gLg5",
                    title: "장보기",
                  },
                ],
                type: "fld",
                key: "pU12o0gLg4",
                title: "장보기",
              },
              {
                children: [
                  {
                    type: "bok",
                    key: "5z7F5TIaH1",
                    title: "사업",
                  },
                  {
                    type: "bok",
                    key: "kgae9aqCc4",
                    title: "부동산",
                  },
                  {
                    type: "bok",
                    key: "kgae9aqCc3",
                    title: "재정",
                  },
                ],
                type: "fld",
                key: "kgae9aqCc2",
                title: "사업",
              },
              {
                children: [
                  {
                    type: "bok",
                    key: "dyGdiLYg35",
                    title: "게임",
                  },
                  {
                    type: "bok",
                    key: "dyGdiLYg34",
                    title: "비디오",
                  },
                  {
                    type: "bok",
                    key: "dyGdiLYg33",
                    title: "음악",
                  },
                ],
                type: "fld",
                key: "d9RyT4vpy7",
                title: "오락",
              },
              {
                children: [
                  {
                    type: "bok",
                    key: "iLSYvK3DA0",
                    title: "생활",
                  },
                  {
                    type: "bok",
                    key: "RR8SEXtEw0",
                    title: "음식",
                  },
                  {
                    type: "bok",
                    key: "Xju6PbN7o0",
                    title: "건강",
                  },
                ],
                type: "fld",
                key: "JGfJ_gc3L0",
                title: "생활",
              },
              {
                children: [
                  {
                    type: "bok",
                    key: "pU12o0gLg1",
                    title: "취미",
                  },
                  {
                    type: "bok",
                    key: "pU12o0gLg3",
                    title: "예술",
                  },
                  {
                    type: "bok",
                    key: "pU12o0gLg2",
                    title: "여행",
                  },
                ],
                type: "fld",
                key: "rkmMsW9V10",
                title: "취미",
              },
            ],
          },
        mockPageList: [
        {
            type: "pge",
            key: "홈",
            title: "홈",
        },
        ],
        shortPageData: {
            "type": "pge",
            "key": "홈",
            "title": "홈",
            "close": false,
            "default": false,
            "children": [
                {
                "children": [
                    {
                    "type": "bok",
                    "key": "d9RyT4vpy4",
                    "title": "책",
                    "children": [
                        {
                        "children": [
                            {
                            "type": "lnk",
                            "key": "I4FxYgS3056",
                            "title": "링크"
                            }
                        ],
                        "type": "lnk",
                        "key": "I4FxYgS3034",
                        "title": "링크"
                        }
                    ],
                    }
                ],
                "type": "fld",
                "key": "d9RyT4vpy0",
                "title": "분류"
                }
            ]
        }        
    },    
}

export default ko;