import React, {useContext} from 'react'
import PageBtn from '../PageBtn'
import Button from 'react-bootstrap/Button';
import getId from '../../../utils/getId'
import {LangContext} from '../../../components/LangProvider';

function PageList(props){
    const {lang} = useContext(LangContext);
    const t = lang.PageList;
    const {pageList, pathUserid, handleShowPage, pathTitle, currentPathTitle, setPathUserid, setPathTitle} = props
    const pageProps = {pathUserid, pathTitle, handleShowPage, t, currentPathTitle, setPathUserid, setPathTitle}
    const randomId = getId()
    const newPage = {kind: 'pge', type: 'pge', key: randomId, title: randomId, path: [randomId]}
    
    return(
    <div className="container pageList">
    {
        pageList.map(pageDelegate=><PageBtn key={pageDelegate.key} pageDelegate={pageDelegate} {...pageProps}/>)
    }
        <Button variant="success" onClick={handleShowPage(newPage)}>{t.newpage}</Button>
    </div>
    )
}

export default PageList