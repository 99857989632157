import React from 'react';
import LinkShare from '../LinkShare'

function BookShare(props){
    const {book, toggleClose, handleShow, parentPath, toggleMemoIcon, memoIcons} = props
    const linkDepth = -1
    //** drag and drop */
    const path = [...parentPath, book.key]
    book.path = path

    //** style */
    let style;
    if(book.bgurl){
      style = {background: `white url(${book.bgurl}) no-repeat`, backgroundPosition:'center right', height: '370px'}
    }
      //** props */
    const itemProps = {toggleClose, handleShow, parentPath: path, toggleMemoIcon, memoIcons, share: book.share}
    return(
      <>
      {book && 
        <div className={book.share?"book share": "book"}>
          <i className={book.close ? (book.children?.length?"closeIcon": "nochildIcon"):"titleIcon"} onClick={toggleClose(book)}></i>
      {book.share && <h6><span className="badge badge-success share">{book.author}{'-'}{new Date(book.date).toLocaleDateString()}</span></h6>}
          <div className="alert header">
            <a href={book.href} target="_blank" rel="noopener noreferrer">
              <span className={book.match ? "title match" : 'title'}>{book.title}</span>
            </a>
            {book.memo && <i className={memoIcons[book.memoI || 0]} onClick={toggleMemoIcon(book)}></i>}
            <i className="settings" onClick={handleShow(book)}></i>
          </div>
          <div className={book.memoI?"memoI": "memo"}>{book.memo}</div>
          {!book.close && 
          <div className="content"  style={style}>
            {book.children && book.children.map((item)=>{
                return <LinkShare key={item.key} link={item} linkDepth={linkDepth} {...itemProps}/>
            })}
          </div>
          }
        </div>
      }
      </>
    );
}

export default BookShare;