let allLinks;
let uniqueLinks;
let duplicateLinks;
let allFolders;
let notEmptyFolders;
let emptyFolders;

export const handleDuplicatesAI = (treeData) => {
    _initialize();
    _getAllLinks(treeData, []);
    const beforeLeng = allLinks.length;
    _sortAllLinks(allLinks);
    _getDuplicateLinks(allLinks);
    _deleteDuplicateLinks(treeData, duplicateLinks);
    const deletedLeng = duplicateLinks.length;
    _initialize();
    _getAllLinksAndRemovePath(treeData, []);
    const afterLeng = allLinks.length;

    return {treeData, beforeLeng, afterLeng, deletedLeng };
}
const _initialize = () => {
    allLinks = [];
    uniqueLinks = [];
    duplicateLinks = [];
    allFolders = [];
    notEmptyFolders = [];
    emptyFolders = [];
}

const _getAllLinks = (treeData, parentPath) => {
    for(let i = 0, n = treeData.length; i < n; i++){
        const node = treeData[i];
        node.path = [...parentPath, node.key];
        if(node.type === 'lnk'){
            allLinks.push(node);
        }
        if(node.children && node.children.length){
            _getAllLinks(node.children, node.path); 
        }
    }
}
const _getAllLinksAndRemovePath = (treeData) => {
    for(let i = 0, n = treeData.length; i < n; i++){
        const node = treeData[i];
        delete node.path;
        if(node.type === 'lnk'){
            allLinks.push(node);
        }
        if(node.children && node.children.length){
            _getAllLinksAndRemovePath(node.children); 
        }
    }
}

const _sortAllLinks = (allLinks) => {
    allLinks.sort(( nodeA, nodeB ) => {
        let result = nodeA.href.localeCompare(nodeB.href);
        if(result === 0){
            result = nodeA.path.length - nodeB.path.length;
        }
        return result;
    })
}

const _getDuplicateLinks = (allLinks) => {
    let prevNode = allLinks[0];
    uniqueLinks.push(prevNode);
    for(let i = 1, n = allLinks.length; i < n; i++){
        if(!allLinks[i].href.startsWith('http') || allLinks[i].href !== prevNode.href ){
            uniqueLinks.push(allLinks[i]);
            prevNode = allLinks[i];
        }else{
            duplicateLinks.push(allLinks[i]);
        }
    }
}

const _deleteDuplicateLinks = (treeData, duplicateLinks) => {
    for(let i = 0, n = duplicateLinks.length; i < n; i++){
        let nodeToDelete = duplicateLinks[i];
        let parentOfNodeToDelete = _getParentNode(treeData, nodeToDelete);
        parentOfNodeToDelete.children.splice(parentOfNodeToDelete.children.indexOf(nodeToDelete), 1);
    }
};

const _getParentNode = (treeData, nodeToDelete) => {
    let parentNode = treeData[0];
    for(let j = 1, m = nodeToDelete.path.length - 1; j < m; j++){
        parentNode = parentNode.children.find( item => item.key === nodeToDelete.path[j]);
    }
    return parentNode;
}

//========================================================

export const handleEmptyFoldersAI = (treeData) => {
    
    let beforeLeng = 0;
    let afterLeng = 0;
    
    for(let i = 0, n = 100; i < n; i++){
        _initialize();
        _getEmptyFolders(treeData, []);
        if(i === 0 ){
            beforeLeng = allFolders.length;
        }
        if(emptyFolders.length <= 0){
            afterLeng = allFolders.length;
            break;
        }else{
            _deleteEmptyFolders(treeData, emptyFolders);
        } 
    }
    let deletedLeng = beforeLeng - afterLeng;
    return {treeData, beforeLeng, afterLeng, deletedLeng };
}
const _getEmptyFolders = (treeData, parentPath) => {
    for(let i = 0, n = treeData.length; i < n; i++){
        const node = treeData[i];
        node.path = [...parentPath, node.key];
        if(node.type === 'fld' || node.type === 'bok'){
            allFolders.push(node);
        }
        if(node.children && node.children.length){
            notEmptyFolders.push(node);
            _getEmptyFolders(node.children, node.path); 
        }else{
            if(node.type === 'fld' || node.type === 'bok'){
                emptyFolders.push(node);
            }
        }
    }
}
const _deleteEmptyFolders = (treeData, emptyFolders) => {
    for(let i = 0, n = emptyFolders.length; i < n; i++){
        let nodeToDelete = emptyFolders[i];
        let parentOfNodeToDelete = _getParentNode(treeData, nodeToDelete);
        parentOfNodeToDelete.children.splice(parentOfNodeToDelete.children.indexOf(nodeToDelete), 1);
    }
};
//====================================================
let isBackupOrder = false;
export const handleSortAI = (node, option) => {
    if(node.children && node.children.length){
        if(!isBackupOrder){
           _backupOrder(node);
           isBackupOrder = true;
        }
        if(option === 'reverse'){
            node.children.sort((a, b) => b.title.localeCompare(a.title));
        }else{
            node.children.sort((a, b) => a.title.localeCompare(b.title));
        }
    }
}
const _backupOrder = (node) => {
    for(let i = 0, n = node.children.length; i < n; i++){
        node.children[i].i = i;
    }
}

export const handleRestoreSortAI = (node) => {
    if(node.children && node.children.length){
        node.children.sort((a, b) => a.i - b.i);
    }
}

