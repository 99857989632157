const config = {
    defaultLang: 'en',
    defaultUserid: 'anonym',
    companyUrl: 'https://neohompy.com',
    defaultPathTitle: 'home',
    cookiesName: 'selectedLang',
    localStorageName: 'neohompy2c',
    localTokenName: 'token',
    localBookmarkName: 'bookmark',
    pathTitles: 'pathTitles',
    title_min: 1,
    title_max: 100,
    url_max: 2000,
    memo_min: 0,
    memo_max: 2000,
    bgurl_max: 200,
    email_max: 50,
    userid_min: 3,
    userid_max: 20,
    pass_min: 3,
    pass_max: 20,
    token_min: 20,
    token_max: 200,
    code_min: 20,
    code_max: 200,
    defaultTheme: 'cerulean',
    max_links_in_book: 10,
    max_length_of_link_title: 100,
    max_length_of_userid: 28,
    tempBookName: 'temp',
    bgimgs: ['REMOVE', 'cloud-mountain.jpg', 'fog-beach.jpg', 'fog-field.jpg', 'purple-sunset.jpg', 'snow-road.jpg', 'summer-beach.jpg', 'sunrise-mountain.jpg', 'sunset-beach.jpg', 'yellow-field.jpg', 'fog-forest.jpg', 'ride-beach.jpg'],
    defaultHref: '#',
    canDropStyle: {backgroundColor: '#a4de02'},
    cannotDropStyle: {border: '1px solid red'},
    useEmailVerification: true,
    shareUserid: 'share',
    sharePathTitle: 'share',
    cutStorageName: 'cut',
    newLinkStorageName: 'newlink',
    defaultSearchWord: 'k-pop'
}

export default config;