import React, {useContext} from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import {LangContext} from '../../components/LangProvider/LangProvider';

function Privacy(){
  const {lang} = useContext(LangContext);
  const t = lang.Privacy

    return(
   <> 
   <Header/>
         
    {/* <!-- Header --> */}
    <header className="py-5 mb-5">
        <div className="container h-100">
        <div className="row h-100 align-items-center">
            <div className="col-lg-12" dangerouslySetInnerHTML={{__html: t.policy}}>
            </div>
        </div>
        </div>
    </header>    
  <Footer/>
  </>
    );
}

export default Privacy;