import React, {useContext, useState} from 'react';
import Folder from '../Folder'
import BookShare from '../BookShare'
import Book from '../Book'
import Link from '../Link'
import MyModal from '../MyModal'
import MyModalShare from '../MyModalShare'
import {useDrop } from 'react-dnd';
import {saveLocalUserData, saveNewLink} from '../../../utils/loadAndSaveData'
import cutByLen from '../../../utils/cutByLen';
import getParentAndTargetNodeFromPath from '../../../utils/getParentAndTargetNodeFromPath';
import config from '../../../data/config';
import api from '../../../utils/api';
import {LangContext} from '../../../components/LangProvider';

function Page(props){
  const {lang} = useContext(LangContext);
  const t = lang.page;
  //** treeData */
  const {show, setShow, showShare, setShowShare, selected, setSelected, page, setPage, userid, pathUserid, pathTitle, pageList, setPageList, useQuery, 
    setServerErrors, setLoading, token, checkAuth, currentPathTitle, prevPathTitle, pageCache} = props
  const treeDepth = -1
  const linkDepth = -1

  //** new link */
  // let new_lnk
  const [cleared, setCleared] = useState(false)
  const query = useQuery();
  if(!cleared){
    let ttl = query.get('t');
    if(ttl){ 
      ttl = cutByLen(ttl, config.max_length_of_link_title);
    }
    let slc = query.get('s');
    if(slc){ 
      slc = slc.substring(0, config.max_length_of_link_title);
    }
    const url = query.get('u');
    if(url){
      const new_lnk = {}
      new_lnk.current = {kind: 'sibling', type: 'lnk', title: ttl, href: url, slc: slc};
      saveNewLink(new_lnk)
      window.history.pushState('','',`/${pathUserid}/${currentPathTitle.current}`)
    }
  }
  const handleShow = (node)=>()=>{
    if(!checkAuth(userid, pathUserid)){
      setShowShare(true)
      setSelected(node)
    }else{
      setShow(true)
      setSelected(node)
    }
  }
  //** page functions */
  const toggleClose = (node)=>() => {
    node.close = !node.close;
    setPage({...page});    
  }

  //** drag and drop functions */
  const moveItem = ({targetDelegate, sourceDelegate}) => {
    if(!checkAuth(userid, pathUserid)){
      alert(t['auth_error'])
      return
    }
    // if source is parent of target, source should not move.
    if(targetDelegate.path.includes(sourceDelegate.path[sourceDelegate.path.length-1])){
      alert(t['moveItemError'])
      return
    }
    const {itemParent: sourceParent, item: source} = getParentAndTargetNodeFromPath(sourceDelegate.path, page);
    const indexToDelete = sourceParent.children.findIndex(item2 => item2.key === source.key);
    sourceParent.children.splice(indexToDelete, 1);
    const {itemParent: targetParent, item: target} = getParentAndTargetNodeFromPath(targetDelegate.path, page);
    const indexToInsert = targetParent.children.findIndex(item2 => item2.key === target.key);
    targetParent.children.splice(indexToInsert + 1, 0, source);

    const timestamp = Date.now()
    page.ts = timestamp
    setPage({...page});

    if(userid === pathUserid){
      saveLocalUserData(userid, page);
    }
    if(userid !== config.defaultUserid){
      setServerErrors(null);
      setLoading(true);
      api.moveItemRemote({targetDelegate, sourceDelegate, token:token.current, ts: timestamp}).then((response) => {
        if(response.errors && response.errors[0]){
          setServerErrors(response.errors[0]);
          setLoading(false);
        }else{
          setServerErrors(null);
          setLoading(false);
        }
      }).catch((e) => {
        if(e.errors && e.errors[0]){
          setServerErrors(e.errors[0]);
        }
        setLoading(false);
      })
    }
  }
  const moveItemChild = ({targetDelegate, sourceDelegate})=>{
    if(!checkAuth(userid, pathUserid)){
      alert(t['auth_error'])
      return
    }
    // if source is parent of target, source should not move.
    if(targetDelegate.path.includes(sourceDelegate.path[sourceDelegate.path.length-1])){
      alert(t['moveItemChildError'])
      return
    }
    const {item: target} = getParentAndTargetNodeFromPath(targetDelegate.path, page);
    const {itemParent: sourceParent, item: source} = getParentAndTargetNodeFromPath(sourceDelegate.path, page);
    const indexToDelete = sourceParent.children.findIndex(item2 => item2.key === source.key);
    sourceParent.children.splice(indexToDelete, 1);
    if(!target.children){
      target.children = []
    }
    target.children.unshift(source);

    const timestamp = Date.now()
    page.ts = timestamp
    setPage({...page});

    if(userid === pathUserid){
      saveLocalUserData(userid, page);
    }
    if(userid !== config.defaultUserid){
      setServerErrors(null);
      setLoading(true);
      api.moveItemChildRemote({targetDelegate, sourceDelegate, token: token.current, ts: timestamp}).then((response) => {
        if(response.errors && response.errors[0]){
          setServerErrors(response.errors[0]);
          setLoading(false);
        }else{
          setServerErrors(null);
          setLoading(false);
        }
      }).catch((e) => {
        setServerErrors(e.errors[0]);
        setLoading(false);
      })
    }
  }

  //** drag and drop */
  const path = [page.title] // !important: Lets use page.title as the first element of the path so as to identify the page with path[0]  
  page.path = path
  const pageDelegate = {type: page.type, key: page.key, path: path}
  const [{isChildOver, canChildDrop}, dropChild] = useDrop({
    accept: ['fld', 'bok', 'lnk'],
    drop(item){
        if(item.key !== page.key){
            moveItemChild({targetDelegate: pageDelegate, sourceDelegate: item});
        }
    },
    collect: (monitor) => ({
      isChildOver: monitor.isOver(),
      canChildDrop: monitor.canDrop(),
    }),
    canDrop(item, monitor){
      // parent item cannot move to child item
      return (item.key !== page.key) && !page.path.includes(item.key)
    }
  })
  const headerTitle = `${pathUserid} ${page.public?t.public: t.private} ${t[page.type]}`

  let childStyle
  if(isChildOver){
    childStyle = canChildDrop? config.canDropStyle: config.cannotDropStyle
  }
  const memoIcons = ['memoIcon0', 'memoIcon1', 'memoIcon2' ]
  const toggleMemoIcon = (node) => () => {
    node.memoI = node.memoI? (node.memoI + 1) % 3: 1
    setPage({...page})
  }
  //** props */
  const itemProps = {toggleClose, handleShow, parentPath: path, moveItemChild, moveItem, toggleMemoIcon, memoIcons}
    //** auth check */
  const modalProps = {show, setShow, selected, setSelected, userid, page, setPage, pageList, setPageList, setServerErrors, setLoading, pathUserid, pathTitle, 
    token, checkAuth, prevPathTitle, setCleared, pageCache}
  const modalShareProps = {showShare, setShowShare, selected, userid, page, setPage, pageList, setPageList, setServerErrors, setLoading, pathUserid, pathTitle, 
    token, checkAuth, prevPathTitle}
  //** renderPage */
  const renderPage = ()=>{
    return (
      <div className="container main">
        <div className="page">
          <i className={page.close ? (page.children?.length?"closeIcon": "nochildIcon"):"titleIcon"}  onClick={toggleClose(page)} ></i>
          <div className={page.public?"alert alert-dark header public": "alert alert-dark header private"} ref={dropChild} style={childStyle}>
            <a href={page.href} target="_blank" rel="noopener noreferrer">
              <span className={page.match ? "title match" : 'title'}>{page.title}</span>
            </a>
            <div className={page.public?"headerTitle public":"headerTitle private"}>{headerTitle}</div>
            {page.memo && <i className={memoIcons[page.memoI || 0]} onClick={toggleMemoIcon(page)}></i>}
            <i className="settings" onClick={handleShow(page)}></i>
          </div>
          <div className={page.memoI?"memoI": "memo"}>{page.memo}</div>
          <div className="content">
          {!page.close && page.children && page.children.map((item)=>{
            return (
              item.type === 'fld'? <Folder key={item.key} folder={item} treeDepth={treeDepth} {...itemProps}/>:
              (item.type === 'bok' && item.share)? <BookShare key={item.key} book={item} {...itemProps}/>:
              (item.type === 'bok' && !item.share)? <Book key={item.key} book={item} {...itemProps}/>:
              item.type === 'lnk'? <Link key={item.key} link={item} linkDepth={linkDepth} {...itemProps}/>:
              null
            )
          })}
          </div>
        </div>
      </div>
    )
  }

  return(
      <>
      {page && renderPage() 
      }
      {show && <MyModal {...modalProps}/>}
      {showShare && <MyModalShare {...modalShareProps}/>}
    </>
  );
}

export default Page;