import React, {useState, useContext, useEffect, useRef} from 'react';
import HeaderMyhome from '../../components/HeaderMyhome';
import './Myhome.css'
import PageList from './PageList'
import Page from './Page'
import {loadLocalUserData, saveLocalUserData2} from '../../utils/loadAndSaveData'
import api from '../../utils/api'
import config from '../../data/config'
import {
  useParams,
  useLocation,
  useHistory  
} from "react-router-dom";

import { DndProvider} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {LoginContext} from '../../components/LoginProvider';
import ajaxLoader from '../../icons/ajax-loader.gif';
import SearchResult from '../../components/SearchResult';

function Myhome(props){
  //** get page */
  const params = useParams()
  let [pathUserid, setPathUserid] = useState(params.pathUserid)
  let [pathTitle, setPathTitle] = useState(params.pathTitle)
  const currentPathTitle = useRef(pathTitle)
  //** userid */
  let {userid, token} = useContext(LoginContext)

  //** load page */
  const [show, setShow] = useState(false)
  const [showShare, setShowShare] = useState(false)
  let [selected, setSelected] = useState(null)
  const [, setServerErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(null);
  const [pageShare, setPageShare] = useState(null);
  const [pageList, setPageList] = useState([]);
  const [defaultPageTitle, setDefaultPageTitle] = useState(null);
  let [memoShown, setMemoShown] = useState(true);
  let [tidy, setTidy] = useState(1);
  const pageCache = useRef({})
  const prevPathTitle = useRef('')
  let history = useHistory();

  useEffect(()=>{
    //2----------------------------
      setServerErrors(null);
      if(!pathUserid){
        if(userid){
          pathUserid = userid
        }else{
          pathUserid = config.defaultUserid
        }
      }
      setPathUserid(pathUserid)
      if(!pathTitle){ 
        if(pathUserid === userid){// check the saved pathTitle only in case of owner
          prevPathTitle.current = api.loadPathTitle(userid)
          if(prevPathTitle.current){ // if there is no prefPathTitle, empty string is returned.
            pathTitle = prevPathTitle.current
            currentPathTitle.current = prevPathTitle.current
          }
        }
      }
      currentPathTitle.current = pathTitle
      setPathTitle(pathTitle)
      if(pageCache.current[pathUserid] && pageCache.current[pathUserid][pathTitle]){
        setPage(pageCache.current[pathUserid][pathTitle])
        setPageList(pageCache.current[pathUserid].pageList)
      }else{
        // in case of owner
        if(userid === pathUserid){
          setLoading(true);
          const userData = loadLocalUserData(pathUserid, pathTitle)
          if(userData?.pageData?.children){
            setServerErrors(null);
            setLoading(false);            
            loadPageCallback(userData)
            if(userid !== config.defaultUserid){ // check if the page is updated or not using ts(timestamp)
              doLoadRemotePage(userData.pageData.ts)
            }
          }else{
            if(userid === config.defaultUserid){
              setServerErrors('notfound_error');
              setLoading(false);            
            }else{ // try to load remote page
              doLoadRemotePage()
            }
          }
        }else{ // in case of non-owner
          doLoadRemotePage()
        }
      }
    function doLoadRemotePage(ts){
      // setLoading(true);
      api.loadRemotePage(pathUserid, pathTitle, token.current, ts).then((response) => {
        if(response.errors && response.errors[0]){
          setServerErrors(response.errors[0]);
          setLoading(false);
          handleClearError()
          history.push('/')
        }else{
          setServerErrors(null);
          setLoading(false);
          loadPageCallback(response)
          // update the local---------------------
          // response is userData
          if(response.message === 'newupdate_msg'){
            saveLocalUserData2(userid, response)
          }else{
            // noupdate_msg can be ignored. Do nothing
          }
          // update the local=====================
        }
      }).catch((e) => {
        setLoading(false);
        handleClearError()
        history.push('/')
      })
    }
    function loadPageCallback(userData){
      currentPathTitle.current = userData.pageData.title
      setDefaultPageTitle(userData.defaultPageTitle)
      setPage(userData.pageData)
      setPageList([...userData.pageList]) // important: pageList is refreshed
      if(userData.pageShare){
        setPageShare(userData.pageShare)
      } 
      // save to cache----------------------------------
      if(!pageCache.current[pathUserid]){
        pageCache.current[pathUserid] = {}
      }
      pageCache.current[pathUserid][pathTitle] = userData.pageData
      pageCache.current[pathUserid].pageList = userData.pageList
      if(!pageCache.current[pathUserid].closeStates){
        pageCache.current[pathUserid].closeStates = {}
      }
      pageCache.current[pathUserid].closeStates[pathTitle] = keepCloseStates(userData.pageData)
      // save to cache===================================
    }
    //2============================
  }, [pathUserid, pathTitle])
  //==================================
  //** functions */
  const toggleMemo = ()=>{
    memoShown = !memoShown
    page.memoShown = memoShown
    setMemoShown(page.memoShown)
  }
  const keepCloseStates = (page)=>{
    if(!page){
      return
    }
    const closeStates = []
    if(page.children){
      for(let i = 0, n = page.children.length; i < n ; i++){
        closeStates.push(page.children[i].close)
      }
    }
    return closeStates
  }
  const toggleTidy = ()=>{
    tidy = tidy % 3 + 1
    setTidy(tidy)
    resetRecursiveClose(page, tidy)
  }
  function resetRecursiveClose(node, tidy){
    if(tidy === 1){
      if(pageCache.current[pathUserid] && pageCache.current[pathUserid].closeStates && pageCache.current[pathUserid].closeStates[pathTitle]){
        const initialCloseStates = pageCache.current[pathUserid].closeStates[pathTitle]
        node.close = false
        if(node.children && node.children.length > 0){
          for(let i = 0, n = initialCloseStates.length; i < n; i++){
            node.children[i].close = initialCloseStates[i]
          }
        }
      }
    }else if(tidy === 2){
      node.close = false;
      if(node.children && node.children.length){
        for(let i = 0, n = node.children.length; i < n; i++){
          node.children[i].close = true
        }
      }
    }else if(tidy === 3){
      node.close = true;
    }
  }
  //** auth check */
  const checkAuth = (userid, pathUserid) => {
    return userid === pathUserid
  }
  const handleShowPage = (node)=>()=>{
    if(!checkAuth(userid, pathUserid)){
      alert('auth_error')
      return
    }
    setShow(true)
    if(node.kind !== 'pge' && node.type === 'pge'){ // page edit
      node = page
      selected = page
    }
    setSelected(node)
  }

  //** search */
  const initialSearchState = { 
    searchFocusIndex: 0,
    searchFoundCount: null,
  };
  const [searchState, setSearchState] = useState(
    initialSearchState
    );
  const{ 
    searchFocusIndex,
    // searchFoundCount,
  } = searchState;
  const [searchResult, setSearchResult] = useState([]);
  const scrollto = (node) => (e) => {
    recursiveOpenParentNode(node, true);
    setPage({...page});
  }

  const resetRecursive = (node) => {
    node.close = true;
    delete node.match;
    if(node.children && node.children.length){
      for(let i = 0, n = node.children.length; i < n; i++){
        node.children[i].path = [...node.path, node.children[i].key];// prepared to open all parentNode upto matched node.
        resetRecursive(node.children[i]);
      }
    }
  }
  const resetSearchResult = () => {
    page.path = [page.title];
    resetRecursive(page);
    page.close = false;// lets open page as default
    setSearchResult([]);
    setSearchState({
      searchFoundCount: 0,
      searchFocusIndex: 0,
    })
  }
  const setSearchString = (searchWord) => {
    resetSearchResult();
    if(!searchWord){
      return;
    }
    const searchWordLower = searchWord.toLowerCase();
    let matches = [];
    let matchesRef = [];
    let matchesIndex = 1;// important since node.match? shows true;
    const foundInTitle = (node) => {
      return node.title.toLowerCase().indexOf(searchWordLower) > -1;
    }
    const foundInHref = (node) => {
      return node.href && (node.href.toLowerCase().indexOf(searchWordLower) > -1);
    }
    const foundInMemo = (node) => {
      return node.memo && (node.memo.toLowerCase().indexOf(searchWordLower) > -1);
    }
    const foundInKeywords = (node) => {
      return node.keywords && (node.keywords.includes(searchWordLower));
    }
    const doSearch = (node) => {
      if(foundInTitle(node) || foundInHref(node) || foundInMemo(node) || foundInKeywords(node)){
        node.match = matchesIndex;
        matches[node.match] = node;
        matchesRef[node.match] = React.createRef();
        matchesIndex++;
      }
      if(node.children && node.children.length){
        for(let i = 0, n = node.children.length; i < n; i++ ){
          doSearch(node.children[i]);
        }
      }
    }
    doSearch(page);
    setSearchResult(matches);
    // setSearchResultRef(matchesRef);
    setSearchState({
      searchFoundCount: matches.length,
      searchFocusIndex:
        matches.length > 0 ? searchFocusIndex % matches.length : 0,
    })
    // doRecursiveOpenParentNode(matches, true);

  }
  const recursiveOpenParentNode = (node, open) => {
    if(node && node.path){
      let parentNode = page;
      parentNode.close = !open;
      for(let i = 1, n = node.path.length - 1; i < n; i++){
        if(parentNode.children && parentNode.children.length){
          parentNode = parentNode.children.find(node2 => node2.key === node.path[i]);
          parentNode.close = !open;
        }
      }
    }
  }
  const handleClearError = () => {
    setServerErrors(null);
    api.removePathTitle(pathUserid)
  }
  //********** */
  //** style */
  let style
  if(page && page.bgurl){
    style = {backgroundImage: `url(${page.bgurl})`, backgroundSize:'contain', backgroundRepeat: 'no-repeat', minHeight: '350vh'}
  }
  //** props */
  const HeaderMyhomeProps = {userid, pathUserid, page, pageList, setPageList, toggleMemo, memoShown, setSearchString, resetSearchResult, toggleTidy, tidy, pageCache, checkAuth, token }
  const shareProps = {show, setShow, showShare, setShowShare, selected, setSelected, page: pageShare, setPage: setPageShare, userid, pathUserid, pathTitle, pageList, setPageList, useQuery, setServerErrors, setLoading, 
    checkAuth, defaultPageTitle, currentPathTitle, prevPathTitle, token}
  const pageProps = {show, setShow, showShare, setShowShare, selected, setSelected, page, setPage, userid, pathUserid, pathTitle, pageList, setPageList, useQuery, setServerErrors, setLoading, 
    checkAuth, defaultPageTitle, currentPathTitle, prevPathTitle, token, pageCache}
  const pageListProps = { userid, pageList, pathUserid, pathTitle, handleShowPage, defaultPageTitle, currentPathTitle, setPathUserid, setPathTitle}
  const SearchResultProps = {searchResult, resetSearchResult, scrollto}

  return(
    <>
      {memoShown && <link rel="stylesheet" type="text/css" href={"/vendor/memoShown.css"} />}
      {loading && <img className='ajaxLoader' src={ajaxLoader} alt='ajaxLoader'/>}
      {page && pageList &&
      <div style={style}>
        <HeaderMyhome {...HeaderMyhomeProps}/>
        {tidy !== 3 &&
          <>
            <PageList {...pageListProps}/>
          <DndProvider backend={HTML5Backend}>
            {(pathUserid === config.shareUserid) && pageShare && <Page {...shareProps}/>} 
            <Page {...pageProps}/>
          </DndProvider>
          <div className="container SearchResult">
            <SearchResult {...SearchResultProps}/>
          </div>
          </>
        }     
      </div> 
    }
    </>
  );
}
function useQuery() {
  return new URLSearchParams(useLocation().search)
}
export default Myhome;