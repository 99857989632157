import config from '../data/config';
import {shortPageData as mockPageData, mockPageList} from '../data/8-new-default';
import LZString from 'lz-string';

mockPageData.default = true
const defaultUserData = { pageData: mockPageData, pageList: mockPageList, defaultPageTitle: mockPageData.title};

/**
 * pageData is saved in the following structure.
 * neohompy: {
 *  [userid]:{
 *      [page.title]: {
 *          //page content      
 *      }
 *  }
 * }
 */
const localStorageExisting = () => {
    return !!localStorage;
}
const loadDataFromLocalStorage = () => {
    if(!localStorage?.getItem(config.localStorageName)){
        return {}
    }
    return JSON.parse(LZString.decompressFromUTF16(localStorage.getItem(config.localStorageName)));
}
const saveDataToLocalStorage = (data) => {
    localStorage.setItem(config.localStorageName, LZString.compressToUTF16(JSON.stringify(data)));
}
const loadLocalUserData = (userid, pathTitle) => {
    let userData;
    let pages;
    let pageData;
    let pageList;
    if(localStorageExisting()){
        pages = loadDataFromLocalStorage()[userid];
        if(pages){
            let defaultPageTitle
            pageList = Object.keys(pages).map(title=>{
                const page = pages[title]
                if(!defaultPageTitle && page.default){
                    defaultPageTitle = page.title
                }
                   return {path: [page.title], type: page.type, key: page.title, title: page.title, default: page.default, public: page.public}

            })
            pageList.sort((a, b)=>{
                return a.title.localeCompare(b.title)
            })
            // set defaultPageTitle and default page
            if(!defaultPageTitle){
                defaultPageTitle = pageList[0].title
            }
            if(!pathTitle){
                pathTitle = defaultPageTitle
            }
            pageData = pages[pathTitle]
            userData = {pageData, pageList, defaultPageTitle}
        }else{
            if(userid === config.defaultUserid){
                const data = {[userid]:{[defaultUserData.pageData.title]: defaultUserData.pageData}}
                saveDataToLocalStorage(data)
                userData = defaultUserData;
            }else{
                userData = null
            }
        }

    }
    return userData;
}
const  _addDefaultItemsToPage = (pageData) => {
    pageData.children = defaultUserData.pageData.children
}
const saveLocalUserData = (userid, pageData, oldPageTitle) => {
    const usersData = loadDataFromLocalStorage();
    if(!usersData[userid]){
        usersData[userid]={}
    }
    if(pageData.key !== pageData.path[0]){ // case that original title is changed to new one, delete the old page. 
        delete usersData[userid][pageData.path[0]] 
    }
    if(pageData.originalTitle){
        delete pageData.originalTitle
    }
    // In case of page creation, populate it with default page
    if(pageData.kind === 'pge'){
        _addDefaultItemsToPage(pageData)
    }
    delete pageData.kind
    delete pageData.path
    usersData[userid][pageData.title] = pageData
    saveDataToLocalStorage(usersData);

}
const saveLocalUserData2 = (userid, userData) => {
    const pageData = userData.pageData
    const pageList = userData.pageList
    const usersData = loadDataFromLocalStorage();
    if(!usersData[userid]){
        usersData[userid]={}
    }
    const pagesDataForUserid = {}
    pageList.forEach(pageDelegate => {
        if(usersData[userid][pageDelegate.title]){ // save the existing pageData
            pagesDataForUserid[pageDelegate.title] = usersData[userid][pageDelegate.title]
        }else{ // create a page title and set it to null
            // children is undefined for pageDelegate intentionally
            pagesDataForUserid[pageDelegate.title] =  { key: pageDelegate.key, title: pageDelegate.title, default: pageDelegate.default, type: pageDelegate.type, public: pageDelegate.public}
        }
    });
    pagesDataForUserid[pageData.title] = pageData
    usersData[userid] = pagesDataForUserid
    saveDataToLocalStorage(usersData);
}
const removeLocalUserData = (userid, pagetitle) => {
    if(localStorageExisting() && userid && pagetitle){
        const usersData = loadDataFromLocalStorage();
        delete usersData[userid][pagetitle];
        saveDataToLocalStorage(usersData);
    }
}
const removeLocalUserDataAll = (userid) => {
    if(localStorageExisting() && userid){
        const usersData = loadDataFromLocalStorage();
        delete usersData[userid];
        saveDataToLocalStorage(usersData);
    }
}
const saveLocalBookmark = (newLink) => {
    localStorage.setItem(config.localBookmarkName, JSON.stringify(newLink));
}
const loadLocalBookmark = () => {
    return JSON.parse(localStorage.getItem(config.localBookmarkName));
}
const removeLocalBookmark = () => {
    localStorage.removeItem(config.localBookmarkName);
}
const saveCut = (data) => {
    localStorage.setItem(config.cutStorageName, (JSON.stringify(data)));
}
const loadCut = () => {
    return JSON.parse((localStorage.getItem(config.cutStorageName)));
}
const clearCut = () => {
    localStorage.removeItem(config.cutStorageName);
}
const saveNewLink = (data) => {
    localStorage.setItem(config.newLinkStorageName, (JSON.stringify(data)));
}
const loadNewLink = () => {
    return JSON.parse((localStorage.getItem(config.newLinkStorageName)));
}
const clearNewLink = () => {
    localStorage.removeItem(config.newLinkStorageName);
}
export {
    loadLocalUserData,
    saveLocalUserData,
    saveLocalUserData2,
    removeLocalUserData,
    removeLocalUserDataAll,
    saveLocalBookmark,
    loadLocalBookmark,
    removeLocalBookmark,
    saveCut,
    loadCut,
    clearCut,
    saveNewLink,
    loadNewLink,
    clearNewLink,

}
//=======================================================