import React, {useState, useEffect, useRef} from 'react';
import config from '../../data/config';
import api from '../../utils/api';
import ajaxLoader from '../../icons/ajax-loader.gif';

export const  LoginContext = React.createContext(null);

function LoginProvider({children}){
    const [, setServerErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    let [userid, setUserid] = useState(null);
    const token = useRef('')

    useEffect(()=>{
      // TODO: move this code(1) inside of setTimer if timing issue still occurs.
      //1------------------
      token.current = api.loadLocalToken();
      //1==================
         //** When token is undefined, if statement does not work correctly. So typeof is applied. */
         if(token && token.current && typeof token.current === 'string' && token.current.length > 50){
          const submitLogin = (token) => {
            setServerErrors(null);
            setLoading(true);
            api.loginWithToken(token.current).then((response) => {
              if(response.errors && response.errors[0]){
                setServerErrors(response.errors[0]);
                setLoading(false);
                setUserid(config.defaultUserid)
              }else{
                setServerErrors(null);
                setLoading(false);
                setUserid(response.userid)
              }
            }).catch((e) => {
              if(e.errors && e.errors[0]){
                setServerErrors(e.errors[0])
              }
              setLoading(false);
              setUserid(config.defaultUserid)
            })
          }
          submitLogin(token);
        }else{
          userid = config.defaultUserid
          setUserid(config.defaultUserid)
        }
    },[])
    return (
        <>
        { loading && <img className='ajaxLoader' src={ajaxLoader} alt='ajaxLoader'/>}
        {userid &&
          <LoginContext.Provider value={{userid, setUserid, token}}>
            {children}
          </LoginContext.Provider>
        }
        </>
    )

}

export default LoginProvider;
