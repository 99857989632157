import React, {useContext} from 'react';
import {LangContext} from '../../../components/LangProvider/LangProvider';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import copyObj from '../../../utils/copyObj'
import config from '../../../data/config';
import doRecursiveChangeKeys from '../../../utils/doRecursiveChangeKeys'
import {saveCut, loadCut, clearCut} from '../../../utils/loadAndSaveData';


function MyModalShare(props){
  const {showShare, setShowShare, selected, userid} = props
  const {lang} = useContext(LangContext);
  const t = lang.mymodalshare;
  const cut = loadCut() || {}
  //** functions */
  const handleClose = ()=>{
    setShowShare(false)
  } 
  const handleCopyCancel = ()=>{
    clearCut()
    setShowShare(false)
  } 
  const handleCopy = () => {
    if(selected.type === 'pge'){
      alert(t['pageCopy_error'])
      return 
    }
    const copied = copyObj(selected)
    doRecursiveChangeKeys(copied)
    cut.source = copied
    if(userid === config.defaultUserid){
      // do nothing
    }else{
      // to inform 'copy' to server
      cut.source.kind = 'copy'
    }
    saveCut(cut)
    handleClose()
  }
  //** props */
  return(
    <Modal show={showShare} onHide={handleClose} className="MyModal">
      <Modal.Footer>
          {selected.type === 'pge' &&
            <span className="loginError">{t.cannotcopy}</span>
          }
          {!cut.source && selected.type !== 'pge' &&
          <>
          <span>{t.copyquestion}</span><span>{t.type[selected.type]}{': '}{selected.title}</span>
          <Button variant="info" onClick={handleCopy}>
            {t.copy}
          </Button>
          </>
          }
          {cut.source && selected.type !== 'pge' &&
          <>
            <span>{t.copyguide}</span>
            <Button variant="success" onClick={handleCopyCancel}>
              {t.cancelcopy}
            </Button>
          </>
          }
        <Button variant="secondary" onClick={handleClose}>
          {t.close}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MyModalShare;