import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import importFile from '../../utils/importFile';
import importJSON from '../../utils/importJSON';
import { saveLocalUserData, removeLocalUserData } from '../../utils/loadAndSaveData';
import { saveAsFile, saveAsJSON } from '../../utils/exportFile';
import ajaxLoader from '../../icons/ajax-loader.gif';
import config from '../../data/config';
import { handleDuplicatesAI, handleEmptyFoldersAI, handleSortAI, handleRestoreSortAI } from '../../utils/bookmarkAI';
import apiBackup from '../../utils/api-backup';
import api from '../../utils/api';
import getId from '../../utils/getId';
import {LangContext} from '../LangProvider/LangProvider';

function Expo(props) {
  //** context */
  const {lang} = useContext(LangContext);
  const t = lang.expo
  const mockPageData = t.mockPageData
  //** props */
  const { userid, pathTitle, page, pageList, setPageList, showExpo, setShowExpo, myTheme, setMyTheme, saveTheme, activeMyhome, myBgimg, setMyBgimg, saveBgimg, treeData, setTreeData, pageCache, token } = props;
  //** set */
  const defaultTab = activeMyhome ? "tabImport" : 'tabClear';
  const [ tab, setTab ] = useState(defaultTab);
  const [ loading, setLoading] = useState(false);
  const [ beforeLeng, setBeforeLeng] = useState(0);
  const [ afterLeng, setAfterLeng] = useState(0);
  const [ deletedLeng, setDeletedLeng] = useState(0);
  const handleClose = () => {
    setShowExpo(false);
  };
  const handleSelect = (tab)=>{ 
    setTab(tab);
  };

  const handleFileChosen = (e) => {
    const file = e.target.files[0];
    // setLoading(true);
    importFile(file, callAfterImport);
  }
  const handleJSONChosen = (e) => {
    const file = e.target.files[0];
    // setLoading(true);
    importJSON(file, callAfterImport);
  }
  const handleDefaultChosen = (e) => {
    callAfterImport(mockPageData)
  }
  const callAfterImport = (importedPage) => {
    // pretreat
    const uniqueTitle = getId()
    importedPage.close = false; // close the rootNode at first for performance
    importedPage.kind = 'impo' // import page
    importedPage.type = 'pge'
    importedPage.key = uniqueTitle
    importedPage.path = [uniqueTitle]
    importedPage.title = uniqueTitle
    importedPage.default = false
    // add this new page to pageList
    const pageDelegate = {type: importedPage.type, key: importedPage.key, title: importedPage.title, default: importedPage.default}
    pageList.push(pageDelegate)
    if(pageCache.current[userid][pathTitle]){
      pageCache.current[userid].pageList = pageList
    }
    setPageList(pageList)

    //save
    if(userid === config.defaultUserid){
      saveLocalUserData(userid, importedPage);
      handleClose();
      window.location.reload(false)
    }else{
      // save to server
      setLoading(true);
      setErrors(null);
      setMessage(null);
      api.saveRemoteSource(importedPage, token.current).then(response => {
        if(response.errors){
          setErrors(response.errors);
          setLoading(false);
        }else{
          setMessage(response.message);
          setErrors(null);
          setLoading(false);
          handleClose();
          window.location.reload(false)
        }
      }).catch((e) => {
        console.log('An API error occurred', e)
        setLoading(false);
      });
    }
  }
  const handleSaveAsFile = () => {
    if(pathTitle){
      saveAsFile(userid, pathTitle, page);
    }else{
      saveAsFile(userid, pageList[0].title, page);
    }
  }
  const handleSaveAsJSON = () => {
    if(pathTitle){
      saveAsJSON(userid, pathTitle, page);
    }else{
      saveAsJSON(userid, pageList[0].title, page);
    }
  }
  const handleDefault = () => {
    if(window.confirm("this will remove the current bookmarks and load a default bookmarks")){
      removeLocalUserData({userid});
      handleClose();
      window.location.reload(false);
    }
  }
  const handleClearStorage = () => {
    if(window.confirm("This will clear the local storage and all bookmarks in it.")){
      localStorage.clear();
      window.location.reload(false);
    }
  }
  const themes = ['cerulean', 'cosmo', 'cyborg', 'darkly', 'flatly', 'journal', 'litera', 'lumen', 'lux', 'materia', 'minty', 'pulse', 'sandstone', 'simplex', 'sketchy', 'slate', 'solar', 'spacelab', 'superhero', 'united', 'yeti'];
  // const bgimgs = ['REMOVE', 'cloud-mountain.jpg', 'fog-beach.jpg', 'fog-field.jpg', 'purple-sunset.jpg', 'snow-road.jpg', 'summer-beach.jpg', 'sunrise-mountain.jpg', 'sunset-beach.jpg', 'yellow-field.jpg', 'fog-forest.jpg'];
  const bgimgs = config.bgimgs;

  const handleTheme = (eventKey) => {
    setMyTheme(eventKey);
  }
  const handleSaveTheme = () => {
    saveTheme();
    handleClose();
  }
  const handleBgimg = (eventKey) => {
    if(eventKey === 'REMOVE'){
      eventKey = null;
    }
    setMyBgimg(eventKey);
  }
  const handleSaveBgimg = () => {
    saveBgimg();
    handleClose();
  }
  const handleDuplicates = () => {
    setLoading(true);
    const result = handleDuplicatesAI(treeData);
    setLoading(false);
    setTreeData(result.treeData);
    setBeforeLeng(result.beforeLeng);
    setAfterLeng(result.afterLeng);
    setDeletedLeng(result.deletedLeng);
    // handleClose();
  }
  const handleEmptyFolders = () => {
    setLoading(true);
    const result = handleEmptyFoldersAI(treeData);
    setLoading(false);
    setTreeData(result.treeData);
    setBeforeLeng(result.beforeLeng);
    setAfterLeng(result.afterLeng);
    setDeletedLeng(result.deletedLeng);
    // handleClose();
  }
  const handleFlatten = () => {
    // saveBgimg();
    handleClose();
  }
  const handleSaveAI = () => {
    const userData = {treeData};
    saveLocalUserData({ userid, userData });
    handleClose();
  }
  const handleSort = () => {
    setLoading(true);
    handleSortAI(treeData[0]);
    setLoading(false);
    setTreeData([...treeData]);
  }
  const handleSortReverse = () => {
    setLoading(true);
    handleSortAI(treeData[0], 'reverse');
    setLoading(false);
    setTreeData([...treeData]);
  }
  const handleRestoreSort = () => {
    setLoading(true);
    handleRestoreSortAI(treeData[0]);
    setLoading(false);
    setTreeData([...treeData]);
  }
  const [errors, setErrors] = useState(null);
  const [message, setMessage] = useState(null);
  let tempUserid;
  let tempPass;
  const handleBackup = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors(null);
    setMessage(null);
    apiBackup.submitBackupWithPass({userid: tempUserid, pass: tempPass, treeData}).then(response => {
      if(response.errors){
        setErrors(response.errors);
      }else{
        setMessage(response.message);
      }
      setLoading(false);
    }).catch((e) => {
      console.log('An API error occurred', e)
      setLoading(false);
      handleClose();
    });
  }
  const handleRecover = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors(null);
    setMessage(null);
    apiBackup.submitRecoverWithPass({userid: tempUserid, pass: tempPass}).then(response => {
      if(response.errors){
        setErrors(response.errors);
      }else{
        const userData = {treeData:response.data};
        saveLocalUserData({ userid, userData });
        setMessage(response.message);
        setTreeData(response.data);
      }
      setLoading(false);
    }).catch((e) => {
      console.log('An API error occurred', e)
      setLoading(false);
      handleClose();
    });
  }
  const handleUserid = (e) => {
    tempUserid = e.target.value;
  }
  const handlePass = (e) => {
    tempPass = e.target.value;
  }
  //==============================================
  return (
      <Modal show={showExpo} onHide={handleClose} animation={true}>
          <Tabs defaultActiveKey={tab} id="uncontrolled-tab-example" onSelect={handleSelect}>
            {activeMyhome && 
              <Tab eventKey="tabImport" title={t.tabImport} >
              </Tab>
            }
            {activeMyhome && 
              <Tab eventKey="tabExport" title={t.tabExport} >
              </Tab>
            }
            <Tab eventKey="tabClear" title={t.tabClear} >
            </Tab>
          </Tabs>
        <Modal.Header closeButton>
          <Modal.Title>{t.title[tab]}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
              { tab === 'tabImport' &&
              <Form>
                  <Form.Group controlId="formBasicTextArea">
                  <Form.Label>{t.bodyImport.html}</Form.Label>
                  <input type="file" id="myHTML" onChange={handleFileChosen}/>
                  <hr/>
                  <Form.Label>{t.bodyImport.json}</Form.Label>
                  <input type="file" id="myJSON" onChange={handleJSONChosen}/>
                  <hr/>
                  <Form.Label>{t.bodyImport.default}</Form.Label><br/>
                  <button className="btn btn-success" onClick={handleDefaultChosen}>{t.bodyImport.defaultBtn}</button>
                  </Form.Group>
              </Form>              
              }
              { loading && <img className='ajaxLoader' src={ajaxLoader} alt='ajaxLoader'/>}
              { tab === 'tabExport' &&
                <div>
                    <div>{t.bodyExport.body1}</div>
                    <div>{t.bodyExport.body2}</div>
                    <p></p>
                    <div>
                      <button className="btn btn-success" onClick={handleSaveAsFile}>{t.bodyExport.btn1}</button>
                      <button className="btn btn-info" onClick={handleSaveAsJSON}>{t.bodyExport.btn2}</button>
                    </div>
                </div> 
              }
              { tab === 'tabRecover' &&
              <Form>
                  <Form.Label>{t.bodyRecover.body1}</Form.Label>
                  <Form.Group  as={Row} controlId="formTitle">
                    <Form.Label column sm={2}>{t.bodyRecover.userid}</Form.Label>
                    <Col sm={10}>
                      <Form.Control type="text" placeholder={t.bodyRecover.useridHolder} value={tempUserid} onChange={handleUserid} />
                    </Col>
                  </Form.Group>
                  <Form.Group  as={Row} controlId="formTitle">
                    <Form.Label column sm={2}>{t.bodyRecover.password}</Form.Label>
                    <Col sm={10}>
                      <Form.Control type="text" placeholder={t.bodyRecover.passwordHolder} value={tempPass} onChange={handlePass} />
                    </Col>
                  </Form.Group>
                  <Form.Group controlId="formBasicTextArea">
                    {errors ? <div><strong style={{color:'red'}}>{errors}</strong></div>:null}
                    {message ? <div><strong style={{color:'green'}}>{message}</strong></div>:null}
                    <button className="btn btn-primary" onClick={handleBackup}>{t.bodyRecover.btn1}</button>
                    <button className="btn btn-info" onClick={handleRecover}>{t.bodyRecover.btn2}</button>
                    <Form.Label>{t.bodyRecover.foot1}</Form.Label>
                  </Form.Group>
              </Form>              
              }
              { tab === 'tabClear' &&
                <div>
                    <div><strong style={{color: 'red'}}>{t.bodyClear.body1}</strong></div>
                    <div>{t.bodyClear.body2}</div>
                    <div>{t.bodyClear.body3}<strong style={{color: 'green'}}>{t.bodyClear.body4}</strong>{t.bodyClear.body5}</div>
                    <div>{t.bodyClear.body6}</div>
                    <div>
                      <button className="btn btn-danger" onClick={handleClearStorage}>{t.bodyClear.btn1}</button>
                    </div>
                </div> 
              }
              { tab === 'tabTheme' &&
                <Form.Group controlId="formBasicTheme">
                    <Form.Label>{t.bodyTheme.body1}</Form.Label>
                    <Dropdown onSelect={handleTheme}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {t.bodyTheme.dropdown1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {themes.map(themeName => <Dropdown.Item key={themeName} eventKey={themeName} active={themeName===myTheme}>{themeName}</Dropdown.Item> )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>
              }
              { tab === 'tabBgimg' &&
                <Form.Group controlId="formBasicTheme">
                    <Form.Label>{t.bodyBgImage.body1}</Form.Label>
                    <Dropdown onSelect={handleBgimg}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {t.bodyBgImage.dropdown1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {bgimgs.map(bgimg => <Dropdown.Item key={bgimg} eventKey={bgimg} active={bgimg===myBgimg}>{bgimg}</Dropdown.Item> )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>
              }
              { tab === 'tabTools' &&
                <div>
                    <div>{t.bodyTools.body1}</div>
                    <div>{t.bodyTools.body2}</div>
                    <div>{t.bodyTools.body3}<strong>{t.bodyTools.body4}</strong>{t.bodyTools.body5}</div>
                    <p></p>
                    <div>{t.bodyTools.body6}<strong>{beforeLeng}</strong>{t.bodyTools.body7}<strong>{afterLeng}</strong>{t.bodyTools.body8}<strong>{deletedLeng}</strong></div>
                    <p></p>
                    <div>
                      <button className="btn btn-success" onClick={handleDuplicates}>{t.bodyTools.btn1}</button>
                      <button className="btn btn-success" onClick={handleEmptyFolders}>{t.bodyTools.btn2}</button>
                      <button className="btn btn-success" onClick={handleSort}>{t.bodyTools.btn3}</button>
                      <button className="btn btn-success" onClick={handleSortReverse}>{t.bodyTools.btn4}</button>
                      <button className="btn btn-success" onClick={handleRestoreSort}>{t.bodyTools.btn5}</button>
                      <button className="btn btn-info" onClick={handleFlatten}>{t.bodyTools.btn6}</button>
                    </div>
                </div> 
              }              
        </Modal.Body>
        <Modal.Footer>
          { tab === 'tabRecover'  &&
            <>
            <Button variant="danger" onClick={handleDefault}>
              {t.footBtn1}
            </Button>
            </>
          }
          <Button variant="secondary" onClick={handleClose}>
            {t.footBtn2}
          </Button>
          { tab === 'tabTheme'  &&
            <>
            <Button variant="primary" onClick={handleSaveTheme}>
              {t.footBtn3}
            </Button>
            </>
          }
          { tab === 'tabBgimg'  &&
            <>
            <Button variant="primary" onClick={handleSaveBgimg}>
              {t.footBtn4} 
            </Button>
            </>
          }
          { tab === 'tabTools'  &&
            <>
            <Button variant="primary" onClick={handleSaveAI}>
              {t.footBtn5}  
            </Button>
            </>
          }
        </Modal.Footer>
      </Modal>
  );
}

export default Expo;

