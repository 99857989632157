import getId from './getId'
export const _recursiveChangeKeys = (items) => {
    for(let i = 0, n = items.length; i < n; i++){
        items[i].key = getId()
        delete items[i].share
        if(items[i].children && items[i].children.length > 0){
            _recursiveChangeKeys(items[i].children)
        }
    }
  }
const doRecursiveChangeKeys = (item, needNewKeyForFirstItem = true) => {
    if(needNewKeyForFirstItem){
        item.key = getId()
        delete item.share
    }
    if(item.type === 'pge'){
        item.title = item.key
    }
    if(item.children && item.children.length > 0){
       _recursiveChangeKeys(item.children)
    }
}

export default doRecursiveChangeKeys