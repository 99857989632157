import React from 'react';

function LinkShare(props){
    const {link, toggleClose, handleShow, parentPath, toggleMemoIcon, memoIcons, share} = props
    const linkDepth = props.linkDepth + 1
    
    //** drag and drop */
    const path = [...parentPath, link.key]
    link.path = path
    link.share = share

    //** functions */

    //** props */
    const itemProps = {toggleClose, handleShow, parentPath: path, toggleMemoIcon, memoIcons, share}
    return(
      <>
      {link && 
        <div className="link">
          <i className={link.close ? (link.children?.length?"closeIcon": "nochildIcon"):"titleIcon"} onClick={toggleClose(link)}></i>
          <div className="alert header" >
            <a href={link.href} target="_blank" rel="noopener noreferrer">
              <span className={link.match ? "title match" : 'title'}>{link.title}</span>
            </a>
            {link.memo && <i className={memoIcons[link.memoI || 0]} onClick={toggleMemoIcon(link)}></i>}
            <i className="settings" onClick={handleShow(link)}></i>
          </div>
          <div className={link.memoI?"memoI": "memo"}>{link.memo}</div>
          <div className="content">
            {!link.close && link.children && link.children.map((item)=>{
                  return <LinkShare key={item.key} link={item} linkDepth={linkDepth} {...itemProps}/>
            })}
          </div>
        </div>
      }
      </>
    );
}

export default LinkShare;