import React, {useContext, useState} from 'react';
import {
  Link,
  useLocation
} from 'react-router-dom';
import {LangContext} from '../LangProvider/LangProvider';
import LoginModal from '../LoginModal/LoginModal';
import LogoutModal from '../LogoutModal/LogoutModal';
import config from '../../data/config';
import Expo from '../../components/Expo';

function NavbarMyhome(props){
    //** props */
    // const {userid, pathUserid, checkAuth} = props
    const {userid, pathUserid, checkAuth, showLoginModal, setShowLoginModal, showLogoutModal, setShowLogoutModal} = props

    //** context */
    const {lang} = useContext(LangContext);
    const t = lang.header

    //** set */
    const [showSignupModal, setShowSignupModal] = useState(false);
    const [showSignoutModal, setShowSignoutModal] = useState(false);
    const [showExpo, setShowExpo] = useState(false);

    //** functions */
    const handleClose = () => {
      setShowLoginModal(false);
      setShowSignupModal(false);
    }
    const handleCloseLogout = () => {
      setShowLogoutModal(false);
      setShowSignoutModal(false);
    }
    const handleSignup = () => {
        setShowSignupModal(true);
    }
    const handleSignout = () => {
        setShowSignoutModal(true);
    }
    
    //** expo */
    const handleExpo = ()=>{
        //** auth check */
        if(!checkAuth(userid, pathUserid)){
            alert('auth_error')
            return
        }
        setShowExpo(true)
    }

    //** props */
    const isLoggedUser = (userid && userid!==config.defaultUserid)
    const loginModalProps = {showLoginModal, showSignupModal, handleClose}
    const logoutModalProps = {showLogoutModal, showSignoutModal, handleCloseLogout}
    const pathname = useLocation().pathname;
    let activeHome='', activeMyhome='', activeShare='', activePrivacy='';
    if(pathname === '/home'){
        activeHome = ' active'; 
    }else if(pathname === '/share'){
        activeShare = ' active'
    }else if(pathname === '/privacy'){
        activePrivacy = ' active'
    }else {
        activeMyhome = ' active';
    }
    //** props */
    const expoProps = {...props, showExpo, setShowExpo, activeMyhome };
    return(
        <>
        {(showLoginModal || showSignupModal) && <LoginModal {...loginModalProps}/>}
        {(showLogoutModal || showSignoutModal) && <LogoutModal {...logoutModalProps}/>}
        { showExpo && <Expo {...expoProps}/>}
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                  <li className={"nav-item" + activeMyhome}>
                      <Link className="nav-link" to={`/${userid}`}>{t.myhome}</Link>
                  </li>
                  <li className={"nav-item" + activeHome}>
                      <Link className="nav-link" to="/home">{t.home}</Link>
                  </li>
                  <li className={"nav-item" + activeShare}>
                      <Link className="nav-link" to="/share">{t.share}</Link>
                  </li>
                  <li className={"nav-item" + activePrivacy}>
                      <Link className="nav-link" to="/privacy">{t.privacy}</Link>
                  </li>
                {(isLoggedUser)?
                    <li className={"nav-item"}>
                        <button type="button" className="btn btn-secondary" onClick={handleSignout}>{t.account}</button>
                    </li>
                    :  
                    <li className={"nav-item"}>
                        <button type="button" className="btn btn-secondary" onClick={handleSignup}>{t.signup}</button>
                    </li>
                }
                    <li className="nav-item">
                        <i className="Navbar_settings" onClick={handleExpo}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i>
                    </li>
              </ul>
          </div>
        </>
    );
}
export default NavbarMyhome;