import React, {useContext} from 'react';
import {LangContext} from '../LangProvider/LangProvider';

function SearchResult(props){
  //** props */
  const {searchResult, resetSearchResult, scrollto} = props
  const {lang} = useContext(LangContext);
  const t = lang.SearchResult

  return(
    <>
      <div className="col-12 col-md-2 myhomeSearch">
        <div className="searchResultTitle">{t.SearchResults}<i className="searchReset" onClick={resetSearchResult}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i></div>
        {!searchResult && <div className="searchResult">{t.NoSearchResults}</div>}
        {searchResult &&
        <div className="searchResult">
          {searchResult.map(node => 
          <div key={node.key} className="linkResult">
            <i className="settingsResult" onClick={scrollto(node)}>&nbsp;&nbsp;&nbsp;&nbsp;</i>
            <div className="titleResult">
              <i className={node.type}>&nbsp;&nbsp;&nbsp;&nbsp;</i>
              <a href={node.href} target="_blank" rel="noopener noreferrer"><div>{node.title}</div></a>
            </div>
            <div className="hrefResult">{node.href}</div>
            <hr/>
          </div>)}
        </div>
        }
      </div>
    </>
  );
}

export default SearchResult;