import shortid from 'shortid';
import cutByLen from '../utils/cutByLen';
import config from '../data/config';
import moveLinksIntoChildren from '../utils/moveLinksIntoChildren';
import getBaseHref from '../utils/getBaseHref';

const isFolderStart = (line) => {
    return ((line.indexOf('<H3 ') > -1) || (line.indexOf('<H1>') > -1));
  }
  const isFolderEnd = (line) => {
    return line.indexOf('</DL>') > -1;
  }
  const isLink = (line) => {
    return line.indexOf('<A ') > -1;
  }
  const regexBgicon = /<DT><H3\s*bgicon="([\w:/.\-?=&]*)"/i;
  const getBgicon = (line) => {
    let m;
    if ((m = regexBgicon.exec(line)) !== null) {
      return m[1];
    }else{
      return null;
    }
  }
  const regexBgurl = /<DT><H3\s*bgurl="([\w:/.\-?=&]*)"/i;
  const getBgurl = (line) => {
    let m;
    if ((m = regexBgurl.exec(line)) !== null) {
      return m[1];
    }else{
      return null;
    }
  }
  const regexBgcolor = /<DT><H3\s+[\w=":/.\-?&"\s#]*\s*bgcolor="([#\w]*)"/i;
  const getBgcolor = (line) => {
    let m;
    if ((m = regexBgcolor.exec(line)) !== null) {
      return m[1];
    }else{
      return null;
    }
  }
  const regexColor = /<DT><H3\s+[\w=":/.\-?&"\s#]*\s*color="([#\w]*)"/i;
  const getColor = (line) => {
    let m;
    if ((m = regexColor.exec(line)) !== null) {
      return m[1];
    }else{
      return null;
    }
  }
  const regexTitle =  /<DT><H3\s+[\w=":/.\-?&"\s#]*\s*>/i;
  const regexTitle2 =  /<H1>/;
  const regexTitle3 =  /<\/H1>/;
  const getTitle = (line) => {
    line = line.replace(regexTitle, "");
    line = line.replace(/<\/H3>/, "");
    line = line.replace(regexTitle2, "");
    line = line.replace(regexTitle3, "");
    return line.trim();
  }
  let lineCount = 0;
  const getLink = (line) => {
    ++lineCount;
    line = line.replace(/^\s*<DT><A\s*HREF="/, '{ "href": "' );
    // line = line.replace(/\s*ICON="/, ', "icon": "' );
    line = line.replace(/"\s*>/, '", "title": "' );
    line = line.replace(/<\/A>/, '"}');
    try{
      line = JSON.parse(line);
      if(line.title){
        line.title = cutByLen(line.title, config.max_length_of_link_title);
      }
      if(line.href && line.href.startsWith('http')){
        line.base = getBaseHref(line.href);
      }
      return line;
    }catch(e){
      console.log('lineCount=', lineCount, ', line=', line);
      return {"href": "#", "title": "###"};
    }
  }

const getPreprocessedContent = (content) => {
    content = content.replace(/ITEM_ID="[{\w:/.\-?=&}]*"/gm, "");
    // content = content.replace(/ICON="[\w:/;,+=\-.?%\&@#$]*"/gm, "");
    content = content.replace(/ICON="[\w:/;,+=\-.?%&@#$]*"/gm, "");
    content = content.replace(/ICON_URI="[\w:/;,+=\-.?%&@#$]*"/gm, "");
    content = content.replace(/ADD_DATE="[0-9]*"/gm, "");
    content = content.replace(/LAST_MODIFIED="[0-9]*"/gm, "");
    content = content.replace(/PERSONAL_TOOLBAR_FOLDER="[\w]*"/gm, "");
    content = content.replace(/HREF="javascript:[\w:\s=,.(?)'/+&;{!}\-%[0-9\]]*"/gm, 'HREF="#"');
    content = content.replace(/<HR>/gm, "");
    return content;
  }

  let randomId = '';
  let nodeCount = 0;
  const getId = () => {
    if(!randomId){
      randomId = shortid();
    }
    return randomId + nodeCount++;
  }
  const getTreeData = (content) => {
    let folderStack = [];
    let rootFolders=[];
    let currentFolder;
    let line;
    const lines = content.split('\n');
    for(let i = 0; i < lines.length; i++){
      line = lines[i];
      if(!line.length){ //empty line
        continue;
      }
      if(isFolderStart(line)){
        const bgicon = getBgicon(line);
        const bgurl = getBgurl(line);
        const bgcolor = getBgcolor(line);
        const color = getColor(line);
        const newFolder = { type: 'bok', title: getTitle(line), key: getId(), children:[], links:[], close: true};
        if(bgicon){
          newFolder.bgicon = bgicon;
        };
        if(bgurl){
          newFolder.bgurl = bgurl;
        };
        if(bgcolor){
          newFolder.bgcolor = bgcolor;
        };
        if(color){
          newFolder.color = color;
        };
        if(currentFolder) {
          currentFolder.children.push(newFolder);
          currentFolder.type = 'fld';
        } else {
          newFolder.type = 'fld';
          newFolder.close = false; // this is rootFolder
          rootFolders.push(newFolder)
        }
        folderStack.push(newFolder);
        currentFolder = newFolder;
      } else if(isLink(line)){
        const newLink = getLink(line);
        newLink.key = getId();
        newLink.type = 'lnk';
        if(currentFolder){
          currentFolder.links.push(newLink);
          // currentFolder.children.push(newLink);
        } else {
          rootFolders.push(newLink)
        }
      } else if(isFolderEnd(line)){
        if(currentFolder){
          if((currentFolder.children && currentFolder.children.length > 0) || (currentFolder.links && currentFolder.links.length > config.max_links_in_book)){
            currentFolder.type = 'fld'; // !important. bok should become fld if new bok is added to bok as child.
            const bookArr = moveLinksIntoChildren({ folder: currentFolder, step: config.max_links_in_book});
            currentFolder.children = [...bookArr, ...currentFolder.children];
            delete currentFolder.links;
          }else{ // pure bok with less max_links_in_book
            currentFolder.children = currentFolder.links;
            delete currentFolder.links;
          }
        }else{
          moveLinksIntoChildren({ folder: rootFolders, step: config.max_links_in_book});
        }
        folderStack.pop();
        currentFolder = folderStack[folderStack.length - 1];
      }
    }
    rootFolders = secureOnlyOneRootNode(rootFolders);    
    return rootFolders;
  }
const secureOnlyOneRootNode = (folders) => {
    if(folders.length > 1){
      const newfolders = [{type: 'fld', key: getId(), title: 'Bookmarks', children: folders}];
      return newfolders;
    }else if(folders.length === 1 && folders[0].children && folders[0].children.length === 1){
      return folders[0].children;
    }else{
      return folders;
    }
  }
const importFile = (file, callAfterImport) => {
    const reader = new FileReader();
    reader.onloadend = (e) => {
        let content = reader.result;
        content = getPreprocessedContent(content);
        const treeData = getTreeData(content);
        const importedPage = treeData[0]
        callAfterImport(importedPage);
    };
    reader.readAsText(file, 'utf-8');
}

export default importFile;