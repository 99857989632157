import React, {useContext, useState} from 'react';
import {LangContext} from '../../../components/LangProvider';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import getId from '../../../utils/getId'
import api from '../../../utils/api'
import copyObj from '../../../utils/copyObj'
import getParentAndTargetNodeFromPath from '../../../utils/getParentAndTargetNodeFromPath'
import config from '../../../data/config';
import {saveLocalUserData, removeLocalUserData, saveCut, loadCut, clearCut, loadNewLink, clearNewLink} from '../../../utils/loadAndSaveData';
import {validateTtl, validateUrl, validateBgurl, validateMemo, validateKeywords} from '../../../utils/validation';
import doRecursiveChangeKeys from '../../../utils/doRecursiveChangeKeys'

let tab_source = {}

function MyModal(props){
  const {show, setShow, selected, setSelected, userid, page, setPage, pageList, setPageList, setLoading, pathUserid, 
    token, checkAuth, prevPathTitle, setCleared, pageCache} = props
  const cut = loadCut() || {}
  const new_lnk = loadNewLink() || {}
  const {lang} = useContext(LangContext);
  const t = lang.mymodal;
  // const history = useHistory()
  const [serverErrors, setServerErrors] = useState(null)
  //** keywords treatment */
  if(Array.isArray(selected.keywords)){ // join all words with space
    selected.keywords = selected.keywords.join(' ')
  }
  //** initial edt tab */
  let defaultTab = 'edt'
  tab_source['edt'] = {
    kind: 'edt',
    type: selected.type,
    key: selected.key,
    title: selected.title,
    href: selected.href,
    memo: selected.memo,
    memoI: selected.memoI,
    keywords: selected.keywords,
    close: selected.close,
    path: selected.path?[...selected.path]:[selected.key] // path should be kept to find the selected node
  }
  if(selected.type === 'pge'){
    tab_source['edt'].bgurl = selected.bgurl
    tab_source['edt'].public = selected.public
    tab_source['edt'].default = selected.default
    tab_source['edt'].originalTitle = selected.title
  }
  if(selected.type === 'bok'){
    tab_source['edt'].bgurl = selected.bgurl
    tab_source['edt'].share = selected.share
  }
  if(selected.type === 'lnk'){
    tab_source['edt'].share = selected.share
  }
  if(new_lnk.current && !tab_source['lnk']){
    defaultTab = 'lnk'
    tab_source['lnk'] = {
      kind: (selected.type ==='lnk') ? 'sibling' : 'child',
      type: 'lnk',
      key: getId(),
      title: new_lnk.current.title,
      href: new_lnk.current.href,
      memo: new_lnk.current.slc,
      path: [...selected.path]
    }
  }
  if(selected.kind === 'pge' && !tab_source['pge']){ // create page
    defaultTab = 'pge'
    tab_source['pge'] = {
      kind: 'pge',
      type: 'pge',
      key: selected.title,
      title: selected.title,
      path: [selected.title],
    }
  }
  let [tab, setTab] = useState(defaultTab)

  let [source, setSource] = useState(tab_source[tab]) // "let" should be used. !important
  const [titleError, setTitleError] = useState(null);
  const [keywordsError, setKeywordsError] = useState(null);
  const [urlError, setUrlError] = useState(null);
  const [memoError, setMemoError] = useState(null);
  const [bgurlError, setBgurlError] = useState(null);
  let disabledSave = titleError || urlError || memoError || bgurlError;

  //** change source */
  const handleSelect = (newtab)=>{
    if(newtab === 'fld' && !tab_source['fld']){
      tab_source['fld'] = {
        kind: (selected.type ==='fld') ? 'sibling' : 'child',
        type: 'fld',
        key: getId(),
        title: '',
        path: [...selected.path]
      }
    }else if(newtab === 'bok' && !tab_source['bok']){
      tab_source['bok'] = {
        kind: (selected.type === 'bok') ? 'sibling':'child',
        type: 'bok',
        key: getId(),
        title: '',
        path: [...selected.path]
      }
    }else if(newtab === 'lnk' && !tab_source['lnk']){
      tab_source['lnk'] = {
        kind: (selected.type ==='lnk') ? 'sibling' : 'child',
        type: 'lnk',
        key: getId(),
        title: '',
        path: [...selected.path]
      }
    }else if(newtab === 'pge' && !tab_source['pge']){
      tab_source['pge'] = {
        kind: 'pge',
        type: 'pge',
        key: '',
        title: '',
        path: [...selected.path]
      }
    }
    setTab(newtab)
    source = tab_source[newtab]
    setSource(source)
  }

  //** functions */

  const handlePublic = (e)=>{
    if(e.target.value === 'true'){
      source.public = true
    }else{
      source.public = false
    }
    tab_source[tab] = source
    setSource({...source})
  }
  const handleDefault = (e)=>{
    if(e.target.value === 'true'){
      source.default = true
    }else{
      source.default = false
    }
    tab_source[tab] = source
    setSource({...source})
  }
  const handleTitle = (e)=>{
    source.title = e.target.value;
    if(source.type === 'pge'){ // replace a space with _ if it is page title
      source.title = source.title.replace(' ', '_')
      source.key = source.title // important: page key is same as page title 
      // source.path = [source.key] // important: path should not be changed until original page is found
      source.originalTitle = selected.title // signal for page title is changed.
    }
    setTitleError(validateTtl(source, pageList));
    tab_source[tab] = source
    setSource({...source})
  }
  const handleUrl = (e)=>{
    source.href = e.target.value;
    setUrlError(validateUrl(source));
    tab_source[tab] = source
    setSource({...source})
  }
  const handleBgurl = (e)=>{
    source.bgurl = e.target.value;
    setBgurlError(validateBgurl(source));
    tab_source[tab] = source
    setSource({...source})
  }
  const handleMemo = (e)=>{
    source.memo = e.target.value;
    setMemoError(validateMemo(source));
    tab_source[tab] = source
    setSource({...source})
  }
  const handleKeywords = (e)=>{
    source.keywords = e.target.value;
    setKeywordsError(validateKeywords(source, pageList));
    tab_source[tab] = source
    setSource({...source})
  }
  const handleSaveCloseState = () => {
    if(!checkAuth(userid, pathUserid)){
      alert(t['auth_error'])
      return
    }
    if(selected.children){
      source.children=[]
      for(let i = 0, n = selected.children.length; i < n; i++){
        source.children.push(selected.children[i].close) // important: push should be used to avoid the undefined error
      }
    }
    handleSave()
  }
  const handleSave = () => {
    if(!checkAuth(userid, pathUserid)){
      alert(t['auth_error'])
      return
    }
    if(selected.share){
      alert('You cannot edit a Shared Book. Unshare this before saving.')
      return
    }
    const title_error = validateTtl(source, pageList);
    setTitleError(title_error);
    const url_error = validateUrl(source);
    setUrlError(url_error);
    const memo_error = validateMemo(source);
    setMemoError(memo_error);
    const keywords_error = validateKeywords(source);
    setKeywordsError(keywords_error);
    const bgurl_error = validateBgurl(source);
    setBgurlError(bgurl_error);
    disabledSave = title_error || url_error || memo_error || bgurl_error || keywords_error;
    if(!disabledSave){
      const timestamp = Date.now()
      saveSourceCallback()
      //in case of owner, save it to localStorage
      if(userid === pathUserid){
        // if(source.kind=== 'pge'){ // create page through pageList
        //   source.ts = timestamp
        //   saveLocalUserData(userid, source) 
        //   window.location.href = `/${userid}/${source.title}`
        // }else{
        //   page.ts = timestamp
        //   saveLocalUserData(userid, page) 
        // }
        if(userid !== config.defaultUserid){ // update the remote 
          setServerErrors(null);
          setLoading(true);
          api.saveRemoteSource(source, token.current, timestamp).then((response) => {
            if(response.errors && response.errors[0]){
              setServerErrors(response.errors[0]);
              setLoading(false);
            }else{
              setServerErrors(null);
              setLoading(false);
              handleClose();
            }
          }).catch((e) => {
            console.log('An API error occurred', e)
            setServerErrors(e.errors[0]);
            setLoading(false);
          })
        }else{
          handleClose();
        }
        if(source.kind=== 'pge'){ // create page through pageList
          source.ts = timestamp
          saveLocalUserData(userid, source) 
          window.location.href = `/${userid}/${source.title}`
        }else{
          page.ts = timestamp
          saveLocalUserData(userid, page) 
        }
      }
    }
  }
  const saveSourceCallback = ()=>{
    if(source.kind=== 'pge'){ // create page through pageList
      pageList.push(source)
      pageList.sort((a, b)=>{
        return a.title.localeCompare(b.title)
      })
      setPageList([...pageList])
      api.savePathTitle(userid, source.title)
      // window.location.href = '/index.html'
    }else{
      
      if(source.kind === 'edt'){ //edit
        selected.key = source.key
        selected.title = source.title
        selected.href = source.href
        selected.memo = source.memo
        selected.memoI = source.memoI
        //** Make the keywords string into array */
        if(source.keywords){
          if(!Array.isArray(source.keywords)){
            source.keywords = source.keywords.toLowerCase().split(' ')
            selected.keywords = source.keywords
          }else{
            selected.keywords = source.keywords
          }
        }
        selected.close = source.close

        let editedPage
        if(source.type === 'pge'){
          editedPage = pageList.find(item=>{
            return item.title === source.path[0]
          })
          selected.public = source.public
          editedPage.public = source.public
          selected.default = source.default
          selected.bgurl = source.bgurl
        }
        if(source.originalTitle){// the page title is editted
          selected.originalTitle = source.originalTitle
          editedPage.title = source.title
          editedPage.key = source.title
          prevPathTitle.current = source.title
          api.savePathTitle(userid, source.title)
        }else{
          delete selected.originalTitle
        }
        if(source.type === 'bok'){
          selected.bgurl = source.bgurl
          selected.share = source.share
        }
        // save the close state of direct children of item
        if(source.children){
          for(let i = 0, n = source.children.length; i < n; i++){
            selected.children[i].close = source.children[i] // important: source.children contains only the close information
          }
        }
      }else if(source.kind==='del'){ //delete
        const {itemParent, item} = getParentAndTargetNodeFromPath(source.path, page)
        itemParent.children.splice(itemParent.children.indexOf(item), 1)
        if(source.type === 'pge'){// the page deletion
          removeLocalUserData(userid, page.title)
          // remove page from pageList
          const index = pageList.findIndex(item => item.key === source.key)
          pageList.splice(index, 1)
          api.removePathTitle(userid)
          //update pageCache
          delete pageCache.current[userid][page.title]
          pageCache.current[pathUserid].pageList = pageList
        }
      }else{ //create
        const newNode = {children:[]}
        newNode.type = source.type
        newNode.key = source.key
        newNode.title = source.title
        newNode.href = source.href
        newNode.memo = source.memo
        newNode.close = source.close
        //** Make the keywords string into array */
        if(source.keywords){
          if(!Array.isArray(source.keywords)){
            source.keywords = source.keywords.toLowerCase().split(' ')
            newNode.keywords = source.keywords
          }else{
            newNode.keywords = source.keywords
          }
        }
        if(source.type === 'pge'){
          newNode.public = source.public
          newNode.default = source.default
          newNode.bgurl = source.bgurl
        }
        if(source.type === 'bok'){
          newNode.bgurl = source.bgurl
          newNode.share = source.share
        }
        const {itemParent, item} = getParentAndTargetNodeFromPath(source.path, page)
        if((item.type === 'lnk' && (source.type === 'fld' || source.type === 'bok'))){
          alert(t['create_error'])
          return
        }
        if((item.type === source.type) || (item.type === 'bok' && source.type === 'fld')){ // add sibling
          itemParent.children.splice(itemParent.children.indexOf(item) + 1, 0, newNode)
        }else{
          if(!item.children){
            item.children = []
          }
          item.children.unshift(newNode)
          item.close = false // important: to see easily the created item after item is added.
        }

        if(newNode.type==='lnk' && new_lnk.current){
          clearNewLink()
        }
      }
      // keep the current page title as pathTitle for later access to the same page.
      if(prevPathTitle.current !== page.title){
        prevPathTitle.current = page.title
        api.savePathTitle(userid, page.title)
      }
      // update pageCache
      if(source.kind==='del' && source.type === 'pge'){
        // already done, so do nothing
      }else{
        pageCache.current[userid][page.title] = page
        pageCache.current[userid].pageList = pageList
      }
      
      // update the page
      setPage({...page})
      setPageList([...pageList])
      if(source.type === 'pge'){ // page refresh
        window.history.pushState('', '', `/${userid}/${source.title}`)
      }
    }
  }
  const handleClose = ()=>{
    tab_source = {}
    setShow(false)
  } 
  const handleDelete = ()=>{
    if(!checkAuth(userid, pathUserid)){
      alert(t['auth_error'])
      return
    }
    if(source.share){
      alert('You cannot delete a Shared Book. Unshare this before deleting.')
      return
    }
    source = tab_source['edt']// temporarily assign the source to tab_source['edt']
    if(window.confirm("Delete '" + t.type[source.type] + ": " + source.title + "' ?")){ 
      deleteRemoteSourceCallback()
      if(userid === pathUserid){
        // deleteRemoteSourceCallback()
        if(source.kind === 'edt' && source.type === 'pge'){// page deletion
          removeLocalUserData(userid, source.title)
          // remove page from pageList
          const index = pageList.findIndex(item => item.key === source.key)
          pageList.splice(index, 1)
          api.removePathTitle(userid)
          //update pageCache
          delete pageCache.current[userid][page.title]
          pageCache.current[pathUserid].pageList = pageList
        }else{ //item is already deleted from page. So just save the current page
          saveLocalUserData(userid, page)
        }
      }
      if(userid !== config.defaultUserid){
        setServerErrors(null);
        setLoading(true);
        api.deleteRemoteSource(source, token.current).then((response) => {
          if(response.errors && response.errors[0]){
            setServerErrors(response.errors[0]);
            setLoading(false);
          }else{
            setServerErrors(null);
            setLoading(false);
            // deleteRemoteSourceCallback()
            if(source.kind === 'edt' && source.type === 'pge'){// page deletion
              api.removePathTitle(userid)
              // window.location.href = `/${userid}`
            }
          }
        }).catch((e) => {
          console.log('An API error occurred', e)
          setServerErrors(e.errors[0]);
          setLoading(false);
        })
      }
      setPage({...page});
      handleClose()
      window.location.href = `/${userid}`
    }else{ 
      source = tab_source[tab]// restore the source to original
    }
    handleClose()
  }
  const handleCopy = () => {
    if(selected.type === 'pge'){
      alert(t['pageCopy_error'])
      return 
    }
    // const copied = JSON.parse(JSON.stringify(selected))
    const copied = copyObj(selected)
    doRecursiveChangeKeys(copied)
    cut.source = copied
    if(userid === config.defaultUserid){
      // do nothing
    }else{
      // to inform 'copy' to server
      cut.source.kind = 'copy'
    }
    saveCut(cut)
    handleClose()
  }
  const handleCut = () => {
    if(selected.type === 'pge'){
      alert(t['pageCut_error'])
      return 
    }
    if(selected.share && selected.type === 'lnk' ){
      alert('You cannot edit a Shared Book. Unshare this before saving.')
      return
    }
    cut.source = selected
    const {itemParent: sourceParent, item: source} = getParentAndTargetNodeFromPath(cut.source.path, page);
    const indexToDelete = sourceParent.children.indexOf(source);
    sourceParent.children.splice(indexToDelete, 1);
    if(userid === config.defaultUserid){
      // immediately save this editted page
      saveLocalUserData(userid, page)
    }else{
      // to inform 'copy' to server
      cut.source.kind = 'cut'
    }
    // setCut(cut)
    saveCut(cut)
    handleClose()
  }
  const handlePaste = () => {
    cut.target = selected
    if((cut.target.type === 'lnk' && (cut.source.type === 'fld' || cut.source.type === 'bok')) || (cut.target.share && cut.source.type === 'lnk')){
      alert(t['paste_error'])
      return
    }
    const {itemParent: targetParent, item: target} = getParentAndTargetNodeFromPath(cut.target.path, page);
    if(cut.target.type === cut.source.type || (cut.target.type === 'bok' && cut.source.type === 'fld')){ // sibling
      const indexToInsert = targetParent.children.indexOf(target);
      targetParent.children.splice(indexToInsert + 1, 0, cut.source);
    }else{ // child
      if(!target.children){
        target.children = []
      }
      target.children.unshift(cut.source)
      target.close = false // important: to see easily the created item after item is added.
    }
    const timestamp = Date.now()
    page.ts = timestamp
    if(userid === pathUserid){
      saveLocalUserData(userid, page)
      clearCut()
    }
    if(userid !== config.defaultUserid){
      saveRemoteUserData(cut, timestamp)
    }
    handleClose()
  }
  const handleClearPaste = ()=>{
    clearCut()
    handleClose()
  }
  function saveRemoteUserData(cut, ts){
      //TODO: send delegate to server
      setServerErrors(null);
      setLoading(true);
      const targetDelegate = {type: cut.target.type, path:[...cut.target.path]}
      let sourceDelegate
      if(cut.source.kind === 'cut'){
        sourceDelegate = {kind: cut.source.kind, type: cut.source.type, path:[...cut.source.path]}
        api.moveItemToPageCut({targetDelegate, sourceDelegate, token: token.current, ts}).then((response) => {
          if(response.errors && response.errors[0]){
            setServerErrors(response.errors[0]);
            setLoading(false);
          }else{
            setServerErrors(null);
            setLoading(false);
            clearCut()
          }
        }).catch((e) => {
          if(e.errors && e.errors[0]){
            setServerErrors(e.errors[0]);
          }
          setLoading(false);
        })
      }else{ // copy
        sourceDelegate = {kind: cut.source.kind, type: cut.source.type, path:[...cut.source.path], source: cut.source}
        api.moveItemToPageCopy({targetDelegate, sourceDelegate, token: token.current, ts}).then((response) => {
          if(response.errors && response.errors[0]){
            setServerErrors(response.errors[0]);
            setLoading(false);
          }else{
            setServerErrors(null);
            setLoading(false);
            clearCut()
          }
        }).catch((e) => {
          if(e.errors && e.errors[0]){
            setServerErrors(e.errors[0]);
          }
          setLoading(false);
        })
      }
  }
  function deleteRemoteSourceCallback(){
    if(source.path.length === 1){ // page deletion
      // const newPageList = pageList.filter(item=> item.title !== source.title)
      // setPageList(newPageList);
      // delete pageCache.current[userid][source.title]
      // pageCache.current[userid].pageList = newPageList
    }else{
      // const path = source.path;
      const {itemParent} = getParentAndTargetNodeFromPath(source.path, page);
      if(itemParent && itemParent.children){
        const indexToDelete = itemParent.children.findIndex(item => item.key === source.key);
        itemParent.children.splice(indexToDelete, 1);
        // setPage({...page});
        // handleClose()
      }
    }
  }
  const saveShare = (e)=>{
    if(!token.current){
      alert('login is needed')
      return 
    }
    if(!(tab === 'edt' && source.type === 'bok')){
      alert('only book can be shared')
      return 
    }
    if(window.confirm(t.confirmshare)){
      setServerErrors(null);
      setLoading(true); 
      const targetDelegate = {}
      const sourceDelegate = {key: source.key, type: source.type, title: source.title, path:[...source.path]}
      if(source.date){
        sourceDelegate.date = source.date
      }else{
        source.date = new Date().getTime()
        sourceDelegate.date = source.date
      }
      if(source.url){
        sourceDelegate.url = source.url
      }
      if(source.bgurl){
        sourceDelegate.bgurl = source.bgurl
      }
      if(source.memo){
        sourceDelegate.memo = source.memo
      }
      if(source.keywords){
        sourceDelegate.keywords = source.keywords
      }
      const timestamp = Date.now()
      page.ts = timestamp
      saveLocalUserData(userid, page)
      api.moveItemToShare({targetDelegate, sourceDelegate, token: token.current, task: 'share', ts: timestamp}).then((response) => {
        if(response.errors && response.errors[0]){
          setServerErrors(response.errors[0]);
          setLoading(false);
        }else{
          setServerErrors(null);
          setLoading(false);
          source.share = true
          tab_source[tab] = source
          setSource({...source})
          selected.share = true
          selected.title = selected.title.trim().toLowerCase().replace(' ', '_')
          if(source.url){
            selected.url = source.url
          }
          if(source.bgurl){
            selected.bgurl = source.bgurl
          }
          if(source.memo){
            selected.memo = source.memo
          }
          selected.author = userid
          selected.date = source.date
          if(source.keywords){
            selected.keywords = source.keywords
            if(typeof selected.keywords === 'string'){
              selected.keywords = selected.keywords.trim().toLowerCase().split(' ')
            }
          }
          if(Array.isArray(selected.keywords)){
            // add author as keyword
            if(!selected.keywords.includes(source.author)){
              selected.keywords.push(source.author)
            }
            //add book title as keyword
            if(!selected.keywords.includes(source.title)){
              selected.keywords.push(source.title)
            }
          }
          setSelected(selected)
          setPage({...page})
          handleClose()
        }
      }).catch((e) => {
        if(e.errors && e.errors[0]){
          setServerErrors(e.errors[0]);
        }
        setLoading(false);
      })   
    }
  }
  const saveUnShare = (e)=>{
    if(!token.current){
      alert('login is needed')
      return 
    }
    if(!(tab === 'edt' && source.type === 'bok')){
      alert('only book can be unshared')
      return 
    }
    if(window.confirm(t.confirmunshare)){
      setServerErrors(null);
      setLoading(true);  
      const targetDelegate = {}
      const sourceDelegate = {key: source.key, type: source.type, path:[...source.path]}
      const timestamp = Date.now()
      page.ts = timestamp
      saveLocalUserData(userid, page)     
      api.moveItemToShare({targetDelegate, sourceDelegate, token: token.current, task: 'unshare', ts: timestamp}).then((response) => {
        if(response.errors && response.errors[0]){
          setServerErrors(response.errors[0]);
          setLoading(false);
        }else{
          setServerErrors(null);
          setLoading(false);
          source.share = false
          tab_source[tab] = source
          setSource({...source})
          setPage({...page})
          selected.share = false
          setSelected(selected)
          handleClose()
        }
      }).catch((e) => {
        if(e.errors && e.errors[0]){
          setServerErrors(e.errors[0]);
        }
        setLoading(false);
      })   
    }
  }
  const handleClearNewLink = () => {
    clearNewLink()
    setCleared(true)
    setSource({...source})
  }
  //** props */
  return(
    <Modal show={show} onHide={handleClose} className="MyModal">
      <Modal.Header closeButton>
        <Modal.Title>{t[tab].title}{' '}{tab==='edt'?t.type[selected.type]:''}</Modal.Title>
        <Button className="quickdelete" variant="outline-danger" onClick={handleDelete}>
          {t.type[selected.type]}{' '}{t.quickdelete}
        </Button>
      </Modal.Header>

      <Modal.Body>
        <Tabs defaultActiveKey={defaultTab} id="uncontrolled-tab-example" onSelect={handleSelect}>
            <Tab eventKey="edt" title={t.edt.tab}>
            </Tab>
            { (selected.type === 'pge') &&
            <Tab eventKey="pge" title={t.pge.tab}>
            </Tab>
            }
            { (selected.type === 'pge' || selected.type === 'fld' || selected.type === 'bok') &&
            <Tab eventKey="fld" title={t.fld.tab}>
            </Tab>
            }
            { (selected.type === 'pge' || selected.type === 'fld' || selected.type === 'bok') &&
            <Tab eventKey="bok" title={<span><i className="book-icon" />{t.bok.tab}</span>}>
            </Tab>
            }
            { (selected.type === 'pge' || selected.type === 'fld' || selected.type === 'bok' ||  selected.type === 'lnk') &&
            <Tab eventKey="lnk" title={<span><i className="link-icon" />{t.lnk.tab}</span>}>
            </Tab>
            }
        </Tabs>
        <Form>
          <Form.Group as={Row} controlId="formTitle">
              <Form.Label column sm={3}>{t.form.title}</Form.Label>
              <Col sm={9}>
                  <Form.Control type="text" placeholder={t.form.titleHolder} value={source.title || ''} onChange={handleTitle} />
                  {titleError &&   
                    <span id="titleHelpInline" className="error titleError">
                      {t.form[titleError.errors[0]]}
                    </span>
                  }      
              </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formHref">
              <Form.Label column sm={3}>{t.form.href}</Form.Label>
              <Col sm={9}>
                  <Form.Control type="text" placeholder={t.form.hrefHolder} value={source.href || ''} onChange={handleUrl} />
                  {urlError && 
                    <span id="urlHelpInline" className="error urlError">
                      {t.form[urlError.errors[0]]}
                    </span>
                  } 
              </Col>
          </Form.Group>
          { ((tab === 'edt' && (source.type === 'pge' || source.type === 'bok')) || (tab === 'pge' || tab === 'bok')) &&
          <Form.Group as={Row} controlId="formBgurl">
            <Form.Label column sm={3}>{t.form.bgurl}</Form.Label>
            <Col sm={9}>
                <Form.Control type="text" placeholder={t.form.bgurlHolder} value={source.bgurl || ''} onChange={handleBgurl} />
                {bgurlError && 
                  <span id="bgurlHelpInline" className="error bgurlError">
                    {t.form[bgurlError.errors[0]]}
                  </span>
                } 
            </Col>
          </Form.Group>
          }
          <Form.Group as={Row} controlId="formMemo">
              <Form.Label column sm={3}>{t.form.memo}</Form.Label>
              <Col sm={9}>
                  <Form.Control as="textarea" rows="3" placeholder={t.form.memoHolder} value={source.memo || ''} onChange={handleMemo} />
                  {memoError && 
                    <span id="memoHelpInline2" className="error memoError">
                      {t.form[memoError.errors[0]]}
                    </span>
                  } 
              </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formKeywords">
              <Form.Label column sm={3}>{t.form.keywords}</Form.Label>
              <Col sm={9}>
                  <Form.Control type="text" placeholder={t.form.keywordsHolder} value={source.keywords || ''} onChange={handleKeywords} />
                  {keywordsError &&   
                    <span id="keywordsHelpInline" className="error keywordsError">
                      {t.form[keywordsError.errors[0]]}
                    </span>
                  }      
              </Col>
          </Form.Group>
          { ((tab === 'edt' && source.type === 'pge') || tab === 'pge') &&
          <>
          <Form.Group as={Row} controlId="formPrivacy">
              <Form.Label column sm={3}>{t.form.privacy}</Form.Label>
                <Col sm={4} className="MyModal_public">
                  <input type="radio" id="public" name="public" value={true} checked={source.public || false} onChange={handlePublic}/>
                  <label htmlFor="public">{t.form.public}</label><br/>
                </Col>
                <Col sm={4} className="MyModal_public">
                  <input type="radio" id="private" name="public" value={false}  checked={!source.public || false} onChange={handlePublic}/>
                  <label htmlFor="private">{t.form.private}</label><br/>        
                </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formDefault">
              <Form.Label column sm={3}>{t.form.defaulting}</Form.Label>
                <Col sm={4} className="MyModal_public">
                  <input type="radio" id="default" name="default" value={true} checked={source.default || false} onChange={handleDefault}/>
                  <label htmlFor="default">{t.form.default}</label><br/>
                </Col>
                <Col sm={4} className="MyModal_public">
                  <input type="radio" id="notdefault" name="default" value={false}  checked={!source.default || false} onChange={handleDefault}/>
                  <label htmlFor="notdefault">{t.form.notdefault}</label><br/>        
                </Col>
          </Form.Group>
          </>
          }
        </Form>
        {serverErrors && <span className="error">{serverErrors}</span>}
      </Modal.Body>
      <Modal.Footer>
        {(tab === 'edt') && 
        <>
        {source.type === 'bok' && (source.share ? <span className="shared">{t.shared}</span> : <span className="unshared">{t.unshared}</span>)}
        {source.type === 'bok' && !source.share &&
        <Button variant="warning" onClick={saveShare}>
          {t.share}
        </Button>
        }
        {source.type === 'bok' && source.share &&
        <Button variant="success" onClick={saveUnShare}>
          {t.unshare}
        </Button>
        }
        <Button variant="secondary" onClick={handleSaveCloseState}>
          {t.saveCloseState}
        </Button>
          {!cut.source && source.type !== 'pge' &&
          <> 
          <Button variant="info" onClick={handleCopy}>
            {t.copy}
          </Button>
          <Button variant="warning" onClick={handleCut}>
            {t.cut}
          </Button>
          </>
          }
          {cut.source &&
          <>
          <Button variant="info" onClick={handleClearPaste}>
            {t.clearpaste}
          </Button>
          <Button variant="warning" onClick={handlePaste}>
            {t.paste}
          </Button>
          </>
          }
        <Button variant="danger" onClick={handleDelete}>
          {t.delete}
        </Button>
        </>
        }
        {tab === 'lnk' && new_lnk.current && 
        <Button variant="secondary" onClick={handleClearNewLink}>
          {t.clearnewlink}
        </Button>
        }
        <Button variant="secondary" onClick={handleClose}>
          {t.close}
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={disabledSave}>
          {t.submit}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MyModal;