import React, {useRef} from 'react';
import {useDrag, useDrop } from 'react-dnd';
import config from '../../../data/config';

function Link(props){
    const {link, toggleClose, handleShow, parentPath, moveItemChild, moveItem, toggleMemoIcon, memoIcons} = props
    const linkDepth = props.linkDepth + 1
    
    //** drag and drop */
    const path = [...parentPath, link.key]
    link.path = path
    const linkDelegate = {type: link.type, key: link.key, path: path}
    const [, drag, previewRef] = useDrag({
      item: linkDelegate,
      collect: (monitor)=>({
          opacity: monitor.isDragging()? 0.5: 1
      }),
    })
    const [{isSiblingOver, canDrop}, drop] = useDrop({
      accept: ['lnk'],
      drop(item){
          if(item.key !== link.key){
              moveItemChild({targetDelegate: linkDelegate, sourceDelegate: item});
          }
      },
      collect: (monitor) => ({
        isSiblingOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      canDrop(item, monitor){
        // parent item cannot move under child item
        return (item.key !== link.key) && !link.path.includes(item.key)
      }
    })
    const [{isChildOver, canChildDrop}, dropChild] = useDrop({
        accept: ['lnk'],
        drop(item){
            if(item.key !== link.key){
                moveItem({targetDelegate: linkDelegate, sourceDelegate: item});
            }
        },
        collect: (monitor) => ({
            isChildOver: monitor.isOver(),
            canChildDrop: monitor.canDrop(),
        }),
        canDrop(item, monitor){
          // parent item cannot move to child item
          return (item.key !== link.key) && !link.path.includes(item.key)
        }
    })
    const ref = useRef(null)
    const ref2 = useRef(null)
    drop(ref)
    previewRef(drag(dropChild(ref2)))

    let siblingStyle
    if(isSiblingOver){
      siblingStyle = canDrop? config.canDropStyle: config.cannotDropStyle
    }
    let childStyle
    if(isChildOver){
      childStyle = canChildDrop? config.canDropStyle: config.cannotDropStyle
    }

    //** functions */


    //** props */
    const itemProps = {toggleClose, handleShow, parentPath: path, moveItemChild, moveItem, toggleMemoIcon, memoIcons}
    return(
      <>
      {link && 
        <div className="link">
          <i style={siblingStyle} className={link.close ? (link.children?.length?"closeIcon": "nochildIcon"):"titleIcon"} ref={ref} onClick={toggleClose(link)}></i>
          <div className="alert header" ref={ref2} style={childStyle}>
            <a href={link.href} target="_blank" rel="noopener noreferrer">
              <span className={link.match ? "title match" : 'title'}>{link.title}</span>
            </a>
            {link.memo && <i className={memoIcons[link.memoI || 0]} onClick={toggleMemoIcon(link)}></i>}
            <i className="settings" onClick={handleShow(link)}></i>
          </div>
          <div className={link.memoI?"memoI": "memo"}>{link.memo}</div>
          <div className="content">
            {!link.close && link.children && link.children.map((item)=>{
                  return <Link key={item.key} link={item} linkDepth={linkDepth} {...itemProps}/>
            })}
          </div>
        </div>
      }
      </>
    );
}

export default Link;